/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Input, Tooltip } from "antd";
import { isNaN } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import { useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

function DecimalInput({
  templateKey,
  metadata,
  formLookupKey,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);
  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && {
      rules: {
        ...formRules,
        pattern: {
          value: /^-?\\d+(?:\.\\d{0,2})?/,
          message: "must be a decimal",
        },
      },
    }),
  });

  const [inputValue, setInputValue] = useState(
    (value as number | string) ?? ""
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    if (!isNaN(Number(event.target.value))) {
      onChange(Number(event.target.value));
    }
  }

  useEffect(() => {
    if (value !== inputValue) setInputValue(value as string | string);
  }, [value]);

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div
      className="field-input-children-with-changelog"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FieldChangeLog templateKey={templateKey} shouldDisplayBadge={focus} />
      <div className="decimal-field">
        <Input
          value={inputValue}
          className="main-input-number"
          placeholder="0.00"
          {...inputProps}
          onChange={handleChange}
          style={{ width: "70px", borderRadius: "3px" }}
          status={error && "error"}
          prefix={
            error ? (
              <Tooltip title={error.message}>
                <ExclamationCircleOutlined />
              </Tooltip>
            ) : (
              <span />
            )
          }
        />
      </div>
    </div>
  );
}

export default DecimalInput;
