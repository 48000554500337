import { DocumentNode } from "graphql";
import { FieldInput, TemplateTypes } from "../types";
import { firmTemplateConfig } from "./firmTemplateConfig";
import { investmentTemplateConfig } from "./investmentTemplateConfig";
import * as types from "_graphql-types-frontend/graphql";
import { comapanyTemplateConfig } from "./companyTemplateConfig";

type TemplateDeferredQuery = {
  query: DocumentNode;
  variables: ({
    state,
  }: {
    state: types.FieldState | null;
  }) => Record<string, any>;
};

export type TemplateConfig = {
  updateTemplateQuery: (
    updateUserEmail: string,
    templateName: string,
    templateVersion: string,
    data: any
  ) => TemplateDeferredQuery | null;
  templateQuery: DocumentNode;
  getTemplateData: (data: any) =>
    | {
        __typename?: "FieldData" | undefined;
        assetClassName?: string | null | undefined;
        fieldMeta: any;
        fieldSet: any;
        fieldSetType: types.FieldSetType;
        strategyName?: string | null | undefined;
        queryFragments: any;
        version: string;
      }
    | null
    | undefined;
  getFieldsData: (data: any) => Record<string, Record<string, any>>;
  getTemplateDataQuery: (name: string, data: any) => TemplateDeferredQuery;
  getEntityOwnerIds: (
    data: any
  ) => Record<string, { id: number | string; fieldKey: string }> | null;
  saveFieldsQuery: (
    fields: Record<string, { [key: string]: FieldInput }>,
    data: any
  ) => TemplateDeferredQuery | null;
  deleteFieldsQuery: (
    fields: Record<string, { [key: string]: FieldInput }>,
    data: any
  ) => TemplateDeferredQuery | null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTemplateConfigForOwner(
  ownerType: TemplateTypes
): TemplateConfig {
  switch (ownerType) {
    case "investment":
      return investmentTemplateConfig;
    case "firm":
      return firmTemplateConfig;
    case "company":
      return comapanyTemplateConfig;
    default:
      throw new Error("unimplemented");
  }
}
