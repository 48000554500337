/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Tooltip } from "antd";
import classNames from "classnames";
import { ReactNode, useCallback, useContext, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useCurrentUser } from "../../../../utils/hooks";
import { RCGReactQuillInput } from "../../../RCGReactQuillInput";
import { TemplateContext } from "../../Context";
import { FieldStamp } from "../FieldAuditStamp";
import FieldChangeLog from "../FieldChangeLog";
import { useTemplateController } from "../FieldContext";
import FileInput from "../FieldFileInput";
import FieldStateSandwich from "../FieldStateSandwich.component";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

const EMPTY_BOX = "<p><br></p>";

const TextAreaWrapper = ({
  children,
  templateKey,
  hideMultiStateView,
  className,
}: {
  children: ReactNode;
  templateKey: string;
  hideMultiStateView: boolean;
  className: string;
}) => {
  const currentUser = useCurrentUser();
  if (hideMultiStateView || !currentUser.flags.rcgDataEntry) {
    return <div className={classNames(className)}>{children}</div>;
  }
  return (
    <FieldStateSandwich
      templateKey={templateKey}
      childClassName={classNames(className)}
    >
      {children}
    </FieldStateSandwich>
  );
};

export default function TextAreaFieldInput({
  metadata,
  templateOverride,
  templateKey,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const formRules = getFormRulesFromMetadata(metadata.value);
  const { hideMultiStateView, hideTimeStamp } = metadata.value;

  const {
    field: { onChange, value, onBlur, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  const { readDraftFieldFromCache, readFieldFromCache, hideImageUpload } =
    useContext(TemplateContext);

  const { field } = readFieldFromCache(templateKey);
  const { field: draftField } = readDraftFieldFromCache(templateKey);
  let mostRecentField = field;
  if (
    (!field && draftField) ||
    (field && draftField && draftField.modifyDate > field?.modifyDate)
  ) {
    mostRecentField = draftField;
  }

  const handleChange = useCallback(
    (content: any, delta: any, source: any) => {
      if (source === "user") {
        onChange(content);
      }
    },
    [onChange]
  );

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  const errorStyle = useMemo(() => {
    return error
      ? {
          borderColor: "red",
          borderWidth: "2px",
          borderStyle: "solid",
        }
      : {};
  }, [error]);

  const memoized_children = useMemo(() => {
    return (
      <>
        <div
          data-cy="template__textarea"
          data-test-id="template__textarea"
          className={classNames("main-input__info")}
        >
          <div className="main-input__sublabel">{questionText}</div>
        </div>

        <TextAreaWrapper
          templateKey={templateKey}
          hideMultiStateView={!!hideMultiStateView}
          className="field-input-children-with-changelog"
        >
          {error && (
            <Tooltip title={error.message}>
              <ExclamationCircleOutlined style={{ color: "red" }} />
            </Tooltip>
          )}
          <NotApplicableWrapper templateKey={templateKey}>
            <>
              <div
                data-testid="quill_editor"
                data-cy={templateKey}
                className={"flex-column full-width"}
              >
                <FieldChangeLog
                  templateKey={templateKey}
                  shouldDisplayBadge={focus}
                />
                <RCGReactQuillInput
                  toolbarId={templateKey.split(".")[1]}
                  placeholder={displayName ?? ""}
                  textValue={value || EMPTY_BOX}
                  onChange={handleChange}
                  style={errorStyle}
                />

                {!hideTimeStamp && <FieldStamp field={mostRecentField} />}
                {!hideImageUpload && (
                  <FileInput
                    metadata={metadata}
                    templateKey={templateKey}
                    formLookupKey={`${templateKey}.documentIds`}
                  />
                )}
              </div>
            </>
          </NotApplicableWrapper>
        </TextAreaWrapper>
      </>
    );
  }, [
    hideTimeStamp,
    hideImageUpload,
    metadata,
    mostRecentField,
    templateKey,
    hideMultiStateView,
    errorStyle,
    questionText,
    displayName,
    value,
    handleChange,
    error,
    NotApplicableWrapper,
  ]);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {memoized_children}
    </div>
  );
}
