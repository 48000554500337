import * as types from "_graphql-types-frontend/graphql";
// import config from "../../../../config";
import AumTableStatic from "../../Static/AumTableStatic";
import { useTemplateController } from "../FieldContext";
import { InputFieldProps } from "./InputFieldBase";

export default function AumEdit({ metadata, formLookupKey }: InputFieldProps) {
  const {
    field: { value },
  } = useTemplateController({
    name: formLookupKey,
  });
  const aumValue = value as types.AumRecord[];

  return (
    <>
      <AumTableStatic value={aumValue} metadata={metadata} />
      {/* {ownerType === types.FieldOwnerType.Investment && metadata.editRoute && (
        <div style={{ display: "inline", float: "left", width: "100%" }}>
          <Button
            style={{ display: "inline", marginRight: "4px" }}
            onClick={() =>
              window.open(
                `${config.RCG_DATA_COLLECTION_URI()}${metadata.editRoute?.replace(
                  "${investmentId}",
                  `${ownerId}`
                )}`
              )
            }
          >
            Edit in Data Collection
          </Button>
        </div>
      )} */}
    </>
  );
}
