import {
  defaultDataIdFromObject,
  InMemoryCache,
  InMemoryCacheConfig,
  TypePolicies,
} from "@apollo/client";
import { KeyFieldsFunction } from "@apollo/client/cache/inmemory/policies";

export interface FieldIdentification {
  ownerId: string;
  ownerType: string;
  fieldKey: string;
  state: string | null;
}

export function dataIdForField({
  state,
  ownerId,
  ownerType,
  fieldKey,
}: FieldIdentification): string {
  return `${ownerId}::${ownerType}::${fieldKey}::${state}`;
}

export const typePolicies: TypePolicies = {
  User: {
    keyFields: ["userName"],
  },
  PositionList: {
    keyFields: false,
  },
  InvestmentFields: {
    keyFields: ["investmentId", "state"],
  },
  CompanyFields: {
    keyFields: ["companyId", "state"],
  },
  TagAttributes: {
    keyFields: ["investmentId"],
  },
  FirmFields: {
    keyFields: ["firmId", "state"],
  },
  InvestmentSetItem: {
    keyFields: false,
  },
  InvestmentInvestors_Value: {
    keyFields: false,
  },
  InvestmentInvestors_Investor: {
    keyFields: false,
  },
  InvestmentSubscription: {
    keyFields: false,
  },
  OrganizationSubscription: {
    keyFields: false,
  },
  GroupSubscription: {
    keyFields: false,
  },
  Field: {
    keyFields: false,
  },
  CurrentUser: {
    keyFields: false,
  },
  Assets: {
    keyFields: ["investmentId", "asOfDate"],
  },
  Position: {
    keyFields: ["ticker", "investmentId", "reportDate"],
  },
  VInvestmentTag: {
    keyFields: ["investmentId", "tagId"],
  },
  PeersStat: {
    keyFields: ["id", "asOfDate"],
  },
  Investment: {
    fields: {
      access: {
        merge: true,
      },
    },
  },
};

export const dataIdFromObject: KeyFieldsFunction = (
  responseObject: any
): string | undefined => {
  if (
    Object.prototype.hasOwnProperty.call(responseObject, "ownerId") &&
    Object.prototype.hasOwnProperty.call(responseObject, "ownerType") &&
    Object.prototype.hasOwnProperty.call(responseObject, "fieldKey") &&
    Object.prototype.hasOwnProperty.call(responseObject, "state")
  ) {
    return dataIdForField(responseObject);
  }

  return defaultDataIdFromObject(responseObject);
};

export const config: InMemoryCacheConfig = {
  dataIdFromObject,
  typePolicies,
};

const cache = new InMemoryCache(config);

export default cache;
