/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable react/jsx-props-no-spreading */
import { Col, Row, Tooltip } from "antd";
import { chunk } from "lodash";
import { ImageProps, tagSample } from "./helper";
import { getSDGNumberFromTag } from "./impact";

import getImage from "./images/getImage";

export function renderIconGrid({
  impactTags,
  chunkSize,
}: {
  impactTags: tagSample[];
  chunkSize?: number;
}): JSX.Element[] | null {
  if (impactTags.length < 1) return null;
  const chunks = chunk(impactTags, chunkSize);
  return chunks.map((row, i) => (
    <Row gutter={1} className="icon_grid" key={i}>
      {row.map(tag => (
        <Col key={`${tag.id}::${tag.name}::icon`} span={2}>
          <SDGIcon key={tag.id} tag={tag} className="sdg-icon" bgFilled />
        </Col>
      ))}
    </Row>
  ));
}

export function impactField(
  impactEnums: { name: string; id: number }[],
  displayName: string
) {
  return {
    displayName,
    content: impactEnums.map(tag => (
      <Tooltip placement="top" title={tag.name}>
        <SDGIcon style={{ maxWidth: "50px" }} bgFilled key={tag.id} tag={tag} />
      </Tooltip>
    )),
    className: "flex-2",
  };
}

export function SDGIcon({
  tag,
  className,
  bgFilled = false,
  imageProps,
  style,
}: {
  tag: tagSample;
  className?: string;
  bgFilled?: boolean;
  imageProps?: ImageProps;
  style?: React.CSSProperties;
}): JSX.Element | null {
  const { name } = tag;
  const key = getSDGNumberFromTag(tag);
  if (!key) return null;

  return (
    <Tooltip placement="top" title={name}>
      <img
        {...imageProps}
        style={{ ...style } as any}
        src={getImage(bgFilled, key)}
        alt={name}
        className={className}
      />
    </Tooltip>
  );
}
