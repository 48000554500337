export default function uuid() {
  // https://stackoverflow.com/a/69118660
  if (crypto?.randomUUID) {
    return crypto.randomUUID();
  }
  if (globalThis?.crypto?.randomUUID) {
    return globalThis.crypto.randomUUID();
  }
  if (require?.("crypto")?.randomUUID) {
    return require("crypto").randomUUID();
  }
  throw Error("No crypto context found.");
}
