/* eslint-disable max-lines-per-function */
import {
  FieldOwnerType as FieldOwnerTypeEnum,
  FieldSetType,
} from "_graphql-types-frontend/graphql";
import { Popconfirm, Tree } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../Modal";
import { useTemplate } from "../Context";
import FieldTypeahead from "../Field/FieldTypeahead";
import { FieldOwnerType, TemplateContextInterface } from "../types";
import {
  draftImportedFieldsOnToInvestment,
  fieldsDataIsEmpty,
  renderImportTree,
} from "./helpers";

export function AvailableFieldsToImport({
  onChange,
  sourceTemplate,
}: {
  onChange: (fieldKeys: string[]) => void;
  sourceTemplate: TemplateContextInterface;
}): JSX.Element | null {
  const {
    publishedData: fieldsData,
    content,
    loading,
    getMetadata,
  } = sourceTemplate;

  if (fieldsData == null) return null;
  if (fieldsDataIsEmpty(fieldsData)) return null;

  if (loading) {
    return <i className="fa fa-spinner fa-spin" />;
  }

  const treeData = renderImportTree({
    content,
    path: "",
    importingFieldsData: fieldsData,
    getMetadata,
  });

  return (
    <div className="import-fields-modal__content">
      <h4>Select Fields to Import</h4>
      <Tree
        checkable
        onCheck={checked => {
          onChange(checked as string[]);
        }}
        treeData={treeData}
      />
    </div>
  );
}

function ImportFieldsModal({
  visible,
  onCancel,
  templateName,
  ownerType,
}: {
  visible: boolean;
  onCancel: () => void;
  templateName: FieldSetType;
  ownerType: FieldOwnerType;
}): JSX.Element {
  const [keysToImport, setKeysToImport] = useState<string[]>([]);
  const [sourceEntity, setSourceEntity] = useState<{
    id: string;
    name: string;
  }>({ id: "", name: "" });

  const { investmentId } = useParams<{ investmentId: string }>();

  const targetTemplate = useTemplate({
    ownerId: Number(investmentId),
    name: templateName,
    ownerType,
    editMode: false,
  });

  const sourceTemplate = useTemplate({
    ownerId: Number(sourceEntity.id),
    name: templateName,
    ownerType,
    editMode: false,
  });

  const CONFIMATION_MESSAGE =
    sourceEntity &&
    `Are you sure you want to replace the selected fields with data from ${sourceEntity?.name}`;

  return (
    <Modal
      title={"Import Fields"}
      visible={visible}
      onCancel={onCancel}
      zIndex={999}
      footer={
        <div className="main-modal__footer">
          <Popconfirm
            title={CONFIMATION_MESSAGE}
            onConfirm={_e =>
              draftImportedFieldsOnToInvestment({
                keysToImport,
                targetEntityId: Number(investmentId),
                publishedSourceData: sourceTemplate.publishedData,
                saveTargetFields: targetTemplate.saveFields,
                closeModal: onCancel,
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <button
              type="button"
              className="rounded-btn rounded-btn--blue mr-16"
            >
              Import as Drafts
            </button>
          </Popconfirm>
        </div>
      }
    >
      <label htmlFor="fieldtypeahead" className="main-input__label">
        {ownerType === FieldOwnerTypeEnum.Firm
          ? "Select Firm to Import From:"
          : "Select Investment to Import From:"}
      </label>

      <FieldTypeahead
        placeholder={"Name"}
        dropdownStyle={{ maxWidth: "100%" }}
        dropdownMatchSelectWidth={false}
        onSelect={({ id, name }) => {
          setSourceEntity({ id, name });
        }}
        ownerType={ownerType}
        dropdownClassName="tm-empty-card__add-member-menu"
      />
      <AvailableFieldsToImport
        onChange={(fieldKeys: string[]) => setKeysToImport(fieldKeys)}
        sourceTemplate={sourceTemplate}
      />
    </Modal>
  );
}

export default ImportFieldsModal;
