import { get } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";

export function CopyField({ field }: { field: { value?: any } }): JSX.Element {
  return (
    <CopyToClipboard text={get(field, "value")}>
      <button data-cy="copy-field" type="button" className="in-blue-500">
        <i className="icon icon--16 icon-copy font-13 mr-8" />
        <span> Copy </span>
      </button>
    </CopyToClipboard>
  );
}
