import RatingIcon from "../../RatingIcon";

export default function RatingStatic({
  value,
}: {
  value?: 0 | 1 | 2 | 3 | 4 | 5;
}): JSX.Element {
  return (
    <div
      data-cy="ratingStatic avoid-page-break-inside"
      data-testid={`rating__${value}`}
      style={{ width: "75px" }}
    >
      <RatingIcon ratingValue={value} size="big" />
    </div>
  );
}
