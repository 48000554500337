import HighCharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offline from "highcharts/modules/offline-exporting";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import HighChartsMore from "highcharts/highcharts-more";

exporting(HighCharts);
offline(HighCharts);
NoDataToDisplay(HighCharts);
HighChartsMore(HighCharts);
