import classnames from "classnames";
import RcTooltip from "rc-tooltip";

const convictionLanguage = {
  s_null: "Conviction",
  s_undefined: "Conviction",
  s_1: "Low Conviction",
  s_2: "Medium Conviction",
  s_3: "High Conviction",
};

export default function ConvictionScoreStatic({
  value,
}: {
  value?: 1 | 2 | 3 | null;
}): JSX.Element {
  const indicatorClasses = classnames({
    "cms-conviction__indicator": true,
    "cms-conviction__indicator--low": value === 1,
    "cms-conviction__indicator--medium": value === 2,
    "cms-conviction__indicator--high": value === 3,
    "avoid-page-break-inside": true,
  });

  return (
    <div>
      <div
        data-testid={`conviction-score_${value}`}
        className={indicatorClasses}
      >
        <span />
        <span />
        <span />
      </div>
      <RcTooltip
        placement="right"
        mouseLeaveDelay={0.25}
        align={{
          useCssBottom: true,
        }}
        overlayStyle={{
          position: "fixed",
        }}
        overlay={convictionLanguage[`s_${value}`] || convictionLanguage.s_null}
        destroyTooltipOnHide={false}
      >
        <span className="cms-conviction__txt">Conviction</span>
      </RcTooltip>
    </div>
  );
}
