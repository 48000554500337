import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import * as types from "_graphql-types-frontend/graphql";
import { createClient } from "graphql-ws";
import { useMemo } from "react";
import cache from "../../utils/configureApolloCache";

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  split,
  useQuery,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { captureException } from "@sentry/react";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import omitDeep from "omit-deep-lodash";
import { CURRENT_USER_QUERY } from "../../utils/graphql";
import { KEYS_TO_OMMIT_ON_INPUT } from "../Template/ImportFields/helpers";

type Props = {
  url: string;
  getToken: () => Promise<string | undefined> | string | undefined;
  children: JSX.Element | JSX.Element[];
  FallbackComponent: React.ComponentType<{ error: ApolloError }>;
  webSocketUrl?: string;
};

const omitTypenameLink = new ApolloLink((operation, forward) => {
  const { variables } = operation;
  if (!variables?.file) {
    operation.variables = omitDeep(variables, KEYS_TO_OMMIT_ON_INPUT);
  }
  return forward(operation);
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors?.forEach(({ message, locations, path }) => {
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );
  });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

function UserLoader({
  children,
  FallbackComponent,
}: {
  children: JSX.Element | JSX.Element[] | null;
  FallbackComponent: React.ComponentType<{ error: ApolloError }>;
}) {
  const { data, loading, error } = useQuery<{
    currentUser: types.CurrentUserQuery["currentUser"];
  }>(CURRENT_USER_QUERY);

  const currentUserError = useMemo(() => {
    const currentUserError = error;
    if (currentUserError) {
      captureException(currentUserError, {
        tags: { context: "Error fetching current user" },
      });
    }
    return currentUserError;
  }, [error]);

  if (currentUserError) return <FallbackComponent error={currentUserError} />;
  if (!data) return null;
  return <>{children}</>;
}

export function RCGApolloProvider({
  getToken,
  url,
  children,
  FallbackComponent,
  webSocketUrl,
}: Props) {
  const client = useMemo(() => {
    const uploadLink = createUploadLink({
      uri: url,
    });

    const retryLink = new RetryLink();

    const authLink = setContext(async (_, { headers }) => {
      const token = await getToken();
      if (!token) throw new Error("No Access Token available.");
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          "apollo-require-preflight": "true",
        },
      };
    });

    const getSplitLink = () => {
      if (webSocketUrl) {
        const wsClient = createClient({
          url: `${webSocketUrl}`,
          connectionParams: async () => {
            const token = await getToken();
            return {
              authorization: `Bearer ${token}`,
            };
          },
        });
        const wsLink = new GraphQLWsLink(wsClient);
        // use wsLink for subscriptions and uploadLink for everything else
        const splitLink = split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          uploadLink as any
        );
        return splitLink;
      }
      return uploadLink as any;
    };

    const splitLink: ApolloLink = getSplitLink();

    return new ApolloClient({
      link: ApolloLink.from([
        omitTypenameLink,
        retryLink,
        onErrorLink,
        authLink,
        splitLink,
      ]),
      cache,
      resolvers: {},
    });
  }, [getToken, url, webSocketUrl]);

  return (
    <ApolloProvider client={client}>
      <UserLoader FallbackComponent={FallbackComponent}>{children}</UserLoader>
    </ApolloProvider>
  );
}
