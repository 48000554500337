import * as HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { useMemo } from "react";

import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsExporting from "highcharts/modules/exporting";

if (typeof HighCharts === "object") {
  HighchartsExporting(HighCharts);
  HighchartsExportData(HighCharts);
}

const labelLetters = {
  T: 1_000_000_000_000,
  B: 1_000_000_000,
  M: 1_000_000,
  K: 1_000,
};

function AUMChart({
  chartData,
  yAxisTitle = {},
}: {
  chartData: Array<Highcharts.SeriesOptionsType>;
  yAxisTitle?: Partial<HighCharts.YAxisTitleOptions>;
}): JSX.Element {
  const options = useMemo<Highcharts.Options>(
    () => ({
      credits: {
        enabled: false,
      },
      title: {
        text: " ",
      },
      chart: {
        type: "column",
        zoomType: "x",
      },
      xAxis: {
        type: "datetime",
        crosshair: true,
      },
      yAxis: {
        title: yAxisTitle,
        labels: {
          formatter: function () {
            const thisValue = Number(this.value);
            for (const [key, value] of Object.entries(labelLetters)) {
              if (thisValue > value) return `${thisValue / value}${key}`;
            }
            return `${this.value}`;
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: chartData,
    }),
    [chartData]
  );

  return (
    <HighChartsReact
      constructorType="chart"
      options={options}
      highcharts={HighCharts}
    />
  );
}

export default AUMChart;
