/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Input, Tooltip } from "antd";
import { useCallback, useContext, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import { ParentContext, useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

export default function TextInput({
  metadata,
  templateOverride,
  formLookupKey,
  templateKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);

  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const {
    field: { onChange, value, onBlur, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.persist();
    onChange(event.target.value);
  }

  const parent = useContext(ParentContext);

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <>
      <div className="main-input__sublabel">
        {!["checklist", "conditional_description"].includes(parent) &&
          questionText}
      </div>
      <div
        className="field-input-children-with-changelog"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <FieldChangeLog templateKey={templateKey} shouldDisplayBadge={focus} />
        <NotApplicableWrapper {...{ templateKey, metadata }}>
          <Input
            placeholder={displayName ?? ""}
            value={value}
            size="small"
            onChange={handleChange}
            {...inputProps}
            status={error && "error"}
            style={{ marginRight: "20px", maxWidth: "60%" }}
            prefix={
              error ? (
                <Tooltip title={error.message}>
                  <ExclamationCircleOutlined />
                </Tooltip>
              ) : (
                <span />
              )
            }
          />
        </NotApplicableWrapper>
      </div>
    </>
  );
}
