import { useMemo } from "react";
import { ContentItemDecorator, Metadata, TemplateOverride } from "../../types";
import AumEdit from "./AumEdit";
import BooleanDropdownFieldInput from "./BooleanDropdownFieldInput";
import BooleanDropdownNaFieldInput from "./BooleanDropdownNaFieldInput";
import BooleanFieldInput from "./BooleanFieldInput";
import ConvictionScoreInput from "./ConvictionScoreInput";
import CurrencyInput from "./CurrencyInput";
import DateInput from "./DateInput";
import DecimalInput from "./DecimalInput";
import IntegerFieldInput from "./IntegerFieldInput";
import ListTableInput from "./ListTableInput";
import NotApplicableControl from "./NotApplicableWrapper";
import PercentFieldInput from "./PercentFieldInput";
import RatingInput from "./RatingInput";
import SelectInput from "./SelectInput";
import TableInput from "./TableInput";
import TextAreaFieldInput from "./TextAreaFieldInput";
import TextInput from "./TextInput";
import TimeSeriesTableInput from "./TimeSeriesTable";

const PlainWrapper = ({ children }: { children: JSX.Element }) => {
  return <>{children}</>;
};

export interface InputFieldProps {
  templateKey: string;
  metadata: Metadata;
  templateOverride?: TemplateOverride;
  formLookupKey: string;
  NotApplicableWrapper: (args: {
    children: JSX.Element;
    templateKey: string;
    metadata?: Metadata;
  }) => JSX.Element;
}

export function InputComponentFor(
  displayType: string
): null | React.ComponentType<InputFieldProps> {
  switch (displayType) {
    case "conviction_score":
      return ConvictionScoreInput;
    case "boolean":
      return BooleanFieldInput;
    case "boolean_dropdown":
      return BooleanDropdownFieldInput;
    case "boolean_dropdown_na":
      return BooleanDropdownNaFieldInput;
    case "textarea":
      return TextAreaFieldInput;
    case "text":
      return TextInput;
    case "rating":
      return RatingInput;
    case "percent":
      return PercentFieldInput;
    case "integer":
    case "year":
      return IntegerFieldInput;
    case "decimal":
      return DecimalInput;
    case "currency":
      return CurrencyInput;
    case "list-table":
      return ListTableInput;
    case "table":
      return TableInput;
    case "time-series-table":
      return TimeSeriesTableInput;
    case "select":
      return SelectInput;
    case "aum_table":
      return AumEdit;
    case "date-monthly":
    case "date-daily":
    case "date-yearly":
      return DateInput;
    case "page_heading":
      break;
    case "section_heading":
      break;
    default:
      console.warn("UNSUPPORTED Input Type ", displayType);
      break;
  }
  return null;
}

interface InputFieldBaseProps {
  templateKey: string;
  metadata: Metadata;
  templateOverride?: TemplateOverride;
  Component?: ContentItemDecorator;
  formLookupKey?: string;
}

export default function ({
  templateKey,
  metadata,
  templateOverride,
  Component: DecoratorComponent,
  formLookupKey = `${templateKey}.value`,
}: InputFieldBaseProps): JSX.Element | null {
  const InputComponent = useMemo(() => {
    return InputComponentFor(metadata.displayType ?? "");
  }, [metadata]);

  if (!InputComponent) {
    return null;
  }

  let inputComponent = (
    <InputComponent
      formLookupKey={formLookupKey}
      templateKey={templateKey}
      metadata={metadata}
      templateOverride={templateOverride}
      NotApplicableWrapper={
        metadata.allowNotApplicable ? NotApplicableControl : PlainWrapper
      }
    />
  );

  if (DecoratorComponent) {
    return (
      <DecoratorComponent
        displayName={
          templateOverride?.displayName ?? metadata.displayName ?? ""
        }
        contentItemProps={{
          type: "field_input",
          metadata,
        }}
      >
        {inputComponent}
      </DecoratorComponent>
    );
  }

  return inputComponent;
}
