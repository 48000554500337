/* eslint-disable no-shadow */
import { GenericProp } from "_graphql-types-frontend/graphql";
import classnames from "classnames";
import { camelCase, get, kebabCase, lowerCase, startCase } from "lodash";
import { sortColsByDisplayOrder } from "../Field/Inputs/TableInput";
import { Metadata } from "../types";
import StaticFieldBase from "./StaticBase.component";

function generateTableHeaders(rowProps: GenericProp) {
  return (
    <tr className="cms-striped-table__header field-table-default__row">
      {sortColsByDisplayOrder(rowProps.props).map(prop => (
        <td key={`header__${prop.propertyKey}`} className={prop.propertyKey}>
          {get(prop, "displayName", startCase(prop.propertyKey))}
        </td>
      ))}
    </tr>
  );
}

function TableRow<T extends object>({
  value,
  genericProps,
  iRow,
  key,
}: {
  value: T;
  genericProps: GenericProp[];
  iRow: number;
  key: string;
}) {
  const tableCols = sortColsByDisplayOrder(genericProps);

  return (
    <tr key={key}>
      {tableCols.map(gProp => {
        return (
          <td
            key={`table-row__${gProp.propertyKey}::${iRow}`}
            className={classnames(
              "field-table__cell-content",
              "field-table-default__cell-content",
              gProp.propertyKey,
              `field-cell-${lowerCase(gProp?.displayType ?? "")}`,
              `cell-${lowerCase(gProp?.displayType ?? "")}`
            )}
          >
            {gProp && (
              <StaticFieldBase
                displayType={gProp?.displayType ?? ""}
                value={value[gProp.propertyKey as keyof T]}
                metadata={{
                  displayName: "",
                  displayType: gProp?.displayType ?? "",
                  value: { ...gProp },
                }}
              />
            )}
          </td>
        );
      })}
    </tr>
  );
}

function TableStatic({
  value: formValue,
  metadata,
  notApplicable,
}: {
  value: unknown;
  metadata: Metadata;
  notApplicable?: boolean | null;
}): JSX.Element {
  const rowMetadata = metadata.value.props[0];
  const rowPropertyKey = rowMetadata.propertyKey;
  const value = get(
    formValue as Record<typeof rowPropertyKey, Record<"id", string>[]>,
    rowMetadata.propertyKey,
    []
  );

  return (
    <div className="section-card__content-table-wrap">
      <table
        data-cy={`tableStatic__${camelCase(metadata.displayName ?? "")}`}
        className={classnames(
          "cms-striped-table",
          "template_static",
          "field-table-default",
          "avoid-page-break-inside",
          kebabCase(metadata.displayName ?? "")
        )}
      >
        <tbody>
          {generateTableHeaders(rowMetadata)}
          {notApplicable && <b>N/A</b>}
          {value &&
            !notApplicable &&
            value.length > 0 &&
            value.map((rowValue, iRow: number) => (
              <TableRow
                key={rowValue.id}
                value={rowValue}
                genericProps={rowMetadata.props}
                iRow={iRow}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableStatic;
