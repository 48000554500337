import { isDefined } from "../../utils/helpers";
interface IAxisData {
  x: number;
  y: number;
  color?: string;
}
interface IValueData {
  name: string;
  value: number;
}

interface IPieData {
  name: string;
  y: number;
}
export interface AxisChartSeries {
  name: string;
  data: IAxisData[];
}

export interface BubbleChartSeries {
  name: string;
  data: IValueData[];
}

export interface PieChartSeries {
  type: string;
  name?: string;
  colorByPoint: boolean;
  data: IPieData[];
}

export interface ICategory {
  id: number | string;
  name: string;
}

/**
 * Generates a data series for charts with x/y axes (eg. bar, column, line)
 * @param data list of records
 * @param recordValueKey key of point value on record (ie. value, percentValue)
 * @param seriesCategories list used to split data into series - will be used for legend
 * @param recordSeriesKey key to access seriesCategory from record
 * @param axisCategories list used for category axis
 * @param recordAxisKey key to access axisCategory from record
 * @param categoryAxis (optional) which axis has categories - defaults to x
 */
export function getAxisChartSeries<
  R extends Partial<Record<keyof R, any>>,
  C extends Record<keyof C, string>
>(
  data: R[],
  recordValueKey: keyof R,
  seriesCategories: ICategory[],
  recordSeriesKey: keyof R,
  axisCategories: ICategory[],
  recordAxisKey: keyof R,
  categoryAxis: "x" | "y" = "x"
): AxisChartSeries[] {
  return seriesCategories.map(({ name, id }) => {
    const records = data.filter(r => r[recordSeriesKey] == Number(id));
    if (categoryAxis === "y") {
      return {
        name: name,
        data: records.map(r => ({
          x: r[recordValueKey],
          y: axisCategories?.findIndex(c => r[recordAxisKey] === Number(c.id)),
        })),
      };
    }
    return {
      name: name,
      data: records.map(r => ({
        y: r[recordValueKey],
        x: axisCategories?.findIndex(c => r[recordAxisKey] === Number(c.id)),
      })),
    };
  });
}

/**
 * function to generate data series for a packed bubble chart (no axes)
 * @param data list of records
 * @param recordValueKey key of point value on record (ie. value, percentValue)
 * @param seriesCategories list used to split data into series - will be used for legend
 * @param recordSeriesKey key to access seriesCategory from record
 * @param bubbleCategories individual bubbles
 * @param recordBubbleKey key to access bubbleCategory from record
 */
export function getPackedBubbleSeries<
  R extends Partial<Record<keyof R, any>>,
  C extends Record<keyof C, string>
>(
  data: R[],
  recordValueKey: keyof R,
  seriesCategories: ICategory[],
  recordSeriesKey: keyof R,
  bubbleCategories: ICategory[],
  recordBubbleKey: keyof R
): BubbleChartSeries[] {
  return seriesCategories.map(({ name, id }) => {
    const seriesRecords = data.filter(
      record => record[recordSeriesKey] == Number(id)
    );

    const bubbledata = bubbleCategories.map(
      ({ name: bubbleName, id: bubbleId }) => {
        const bubbleValue = seriesRecords
          .filter(r => !!r[recordValueKey])
          .find(r => r[recordBubbleKey] === Number(bubbleId));
        return (
          bubbleValue && {
            name: bubbleName,
            value: bubbleValue[recordValueKey],
          }
        );
      }
    );

    return {
      name,
      data: bubbledata.filter(isDefined),
    };
  });
}

/**
 * function to generate data series for a pie chart
 * @param data list of records
 * @param recordValueKey key of point value on record (ie. value, percentValue)
 * @param seriesCategories list used to split data into series - will be used for legend + pie wedges
 * @param recordSeriesKey key to access seriesCategory from record
 */
export function getPieChartSeries<
  R extends Partial<Record<keyof R, any>>,
  C extends Record<keyof C, string>
>(
  data: R[],
  recordValueKey: keyof R,
  seriesCategories: ICategory[],
  recordSeriesKey: keyof R
): PieChartSeries[] {
  return [
    {
      type: "pie",
      colorByPoint: true,
      data: seriesCategories.map(({ name, id }) => {
        const value = data
          .filter(r => r[recordSeriesKey] == id)
          .reduce((acc, curr: R) => {
            acc += curr[recordValueKey];
            return acc;
          }, 0);

        return {
          name,
          y: Math.round(value * 100) / 100,
        };
      }),
    },
  ];
}
