import * as types from "_graphql-types-frontend/graphql";
import { gql } from "_graphql-types-frontend";
import { capitalize } from "lodash";
import { dynamicgql } from "../../../utils/graphql";
import { NO_QUERY } from ".";
import { TemplateConfig } from "./config";
import { generateTemplateFragmentKey } from "../Template.helpers";
import { getDynamicQueryFragment } from "./configHelpers";
import { FieldInput } from "../types";
import uuid from "../../../utils/uuid";

export const FETCH_FIRM_TEMPLATE = gql(`
  query FETCH_FIRM_TEMPLATE(
    $id: Int!
    $name: FieldSetType!
    $version: String
    $state: String
  ) {
    firm(id: $id) {
      id
      templateOptions(fieldSetType: $name)
      fieldData(fieldSetType: $name, version: $version, state: $state) {
        assetClassName
        fieldMeta
        fieldSet
        fieldSetType
        strategyName
        queryFragments
        version
      }
    }
  }
`);

function getFirmFieldsQuery(
  operation: "deleteFirmFields" | "updateFirmFields"
) {
  return (
    fields: Record<"firm", { [key: string]: FieldInput }>,
    data: types.Fetch_Firm_TemplateQuery
  ) => {
    if (!data || !data.firm || !data.firm.fieldData) return null;

    const queryId = generateTemplateFragmentKey(operation);

    const firmQueryFragments = getDynamicQueryFragment({
      fields: fields.firm,
      queryId,
      queryFragments: data.firm.fieldData.queryFragments.firm,
    });

    // queryId needs to be dynamic to regenerate results appropriately.
    const firmId = data.firm.id;
    return {
      query: dynamicgql`
    mutation ${queryId} (
      $firmFields: FirmFieldsInput!
      $firmId: Int!
      $state: String
    ) {
        ${operation}(
          fields: $firmFields, firmId: $firmId, state: $state
        ) {
          firmId
          state
          ${firmQueryFragments}
        }
    }`,
      variables: ({ state }: { state: string | null }) => ({
        firmId,
        firmFields: fields.firm,
        state,
      }),
    };
  };
}

const saveFieldsQuery = getFirmFieldsQuery("updateFirmFields");
const deleteFieldsQuery = getFirmFieldsQuery("deleteFirmFields");

export const firmTemplateConfig: TemplateConfig = {
  templateQuery: FETCH_FIRM_TEMPLATE,
  getTemplateData(data: types.Fetch_Firm_TemplateQuery) {
    return data?.firm?.fieldData;
  },
  getFieldsData(data: {
    firm: {
      fields: types.FirmFields;
    };
  }) {
    return {
      firm: data.firm.fields,
    };
  },
  getTemplateDataQuery(name: string, data: types.Fetch_Firm_TemplateQuery) {
    if (!data || !data.firm || !data.firm.fieldData)
      return {
        query: NO_QUERY,
        variables: () => ({}),
      };

    const firmId = data.firm.id;

    return {
      query: dynamicgql`
        query TemplateFields${name}(
          $firmId: Int!, $state: String
        ) {
          firm(id: $firmId) {
            id
            fields(state: $state) {
              firmId
              state
              ${data.firm.fieldData.queryFragments.firm}
            }
          }
        }`,
      variables: ({ state }: { state: string | null }) => ({
        firmId,
        state,
      }),
    };
  },
  getEntityOwnerIds(data: types.Fetch_Firm_TemplateQuery) {
    if (!data || !data?.firm?.id) return null;
    return {
      firm: { id: data.firm.id, fieldKey: "firmId" },
    };
  },
  saveFieldsQuery,
  deleteFieldsQuery,
  updateTemplateQuery: (
    updateUserEmail: string,
    templateName: string,
    templateVersion: string,
    data: types.Fetch_Firm_TemplateQuery
  ) =>
    saveFieldsQuery(
      {
        firm: {
          [`firm${capitalize(templateName)}TemplateVersion`]: {
            id: uuid(),
            createDate: new Date(),
            createUser: updateUserEmail,
            ownerId: data.firm?.id,
            ownerType: "firm",
            state: null,
            modifyDate: new Date(),
            modifyUser: updateUserEmail,
            fieldKey: `firm${capitalize(templateName)}TemplateVersion`,
            value: templateVersion,
          },
        },
      },
      data
    ),
};
