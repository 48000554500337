export default function Spinner(): JSX.Element {
  return (
    <div
      data-cy="loading-more-spinner"
      data-testid="loading-more-spinner"
      className="main-spinner"
    >
      <i id="loading-more-spinner" className="main-spinner__icon" />
    </div>
  );
}
