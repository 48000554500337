import { Metadata } from "../types";

const TrueIcon = () => <i className="fas fa-check-circle" />;
const FalseIcon = () => <i className="fas fa-times-circle" />;

const displayQuestionText = (text: string | undefined) => {
  if (text) {
    return (
      <>
        {`${text}`}
        <br />
      </>
    );
  }
  return null;
};

export default function BooleanDropdownStatic({
  value,
  metadata,
  notApplicable,
}: {
  value?: boolean | string;
  metadata: Metadata;
  notApplicable: boolean;
}): JSX.Element | null {
  const { questionText, translations } = metadata;

  if (notApplicable) {
    return (
      <div className="avoid-page-break-inside">
        {displayQuestionText(questionText ?? undefined)} <b>N/A</b>
      </div>
    );
  }

  if (translations) {
    return value === true || value === "true" ? (
      <div className="avoid-page-break-inside">
        <TrueIcon /> {translations.trueText}
      </div>
    ) : value === false || value === "false" ? (
      <div className="avoid-page-break-inside">
        <FalseIcon /> {translations.falseText}
      </div>
    ) : typeof value === "string" ? (
      <div className="avoid-page-break-inside">{value}</div>
    ) : null;
  }
  return value === true || value === "true" ? (
    <div className="avoid-page-break-inside">
      {displayQuestionText(questionText ?? undefined)} <b>Yes</b> <TrueIcon />
    </div>
  ) : value === false || value === "false" ? (
    <div className="avoid-page-break-inside">
      {displayQuestionText(questionText ?? undefined)} <b>No</b> <FalseIcon />
    </div>
  ) : typeof value === "string" ? (
    <div className="avoid-page-break-inside">
      {displayQuestionText(questionText ?? undefined)} <b>{value}</b>
    </div>
  ) : null;
}
