import { ClockCircleFilled } from "@ant-design/icons";
import { useLazyQuery } from "@apollo/client";
import { Badge } from "antd";
import { get } from "lodash";
import { memo, useContext, useMemo, useState } from "react";
import { dynamicgql } from "../../../utils/graphql";
import Modal from "../../Modal";
import Spinner from "../../Spinner";
import { TemplateContext } from "../Context";
import { StaticComponents } from "../Static/StaticBase.component";
import { Field, Metadata } from "../types";
import { CopyField } from "./CopyField";
import { FieldStamp } from "./FieldAuditStamp";
import { TableContext } from "./FieldTableContext";
import { truncateFieldHistoryItem } from "./utils";

const fieldSupportsCopy = ({ displayType }: Metadata) => {
  const supportedDisplaytypes = ["textarea", "text", "percent"];
  return supportedDisplaytypes.includes(displayType ?? "");
};

const displayStatic = ({ displayType }: Metadata) => {
  const supportedDisplaytypes = [
    "textarea",
    "text",
    "percent",
    "boolean",
    "boolean_dropdown",
  ];
  return supportedDisplaytypes.includes(displayType ?? "");
};

const GET_FIELD_HISTORY = (ownerType: string, fieldKey: string) =>
  dynamicgql(`
query FieldHistory${ownerType}${fieldKey}(
  $id: Int!, $filter: FieldHistoryFilter, $sort: [FieldHistorySort!]
) {
  ${ownerType}(id: $id) {
    fieldHistory(filter: $filter, sort: $sort) {
      id
      ownerId
      ownerType
      fieldKey
      asOfDate
      state
      value
      modifyDate
      modifyUser
    }
  }
}
`);

function HistoryItemExpander({
  setExpanded,
  expanded,
  isTruncated,
}: {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  isTruncated: boolean;
}): JSX.Element | null {
  if (!isTruncated) return null;
  return (
    <button
      className="switch-btn__changelog"
      type="button"
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? "Show Less" : "Show More"}
    </button>
  );
}

function HistoryItem({
  field,
  allowCopy,
  metadata,
}: {
  field: Field;
  allowCopy: boolean;
  metadata: Metadata;
}) {
  if (!field) return null;
  const [expanded, setExpanded] = useState(false);

  const { value, isTruncated } = truncateFieldHistoryItem(field.value, 500);
  return (
    <li
      key={field.id}
      data-cy="field-changelog__item"
      className="field-popover__list-item"
    >
      <div
        className="d-flex justify-between"
        key={`${field.state}(field_info)`}
      >
        <>
          {displayStatic(metadata) ? (
            <div
              data-cy="field-changelog__item__static-wrapper"
              className="changelog__list__static-wrapper"
            >
              <StaticComponents
                displayType={metadata.displayType ?? ""}
                metadata={metadata}
                value={expanded ? field.value : value}
              />

              <HistoryItemExpander
                setExpanded={setExpanded}
                expanded={expanded}
                isTruncated={isTruncated}
              />
            </div>
          ) : (
            <p data-cy="field-changelog__item__text" className="rating-txt">
              {field.value}
            </p>
          )}
        </>
        {allowCopy && <CopyField field={field} />}
      </div>
      <FieldStamp
        field={field}
        className="field-popover__list-item-secondary-text"
      />
    </li>
  );
}

function FieldHistory({
  fieldHistory,
  loadingHistory,
  metadata,
}: {
  fieldHistory: Field[];
  loadingHistory: boolean;
  metadata: Metadata;
}) {
  if (loadingHistory) {
    return (
      <div data-cy="changelog-modal" className="changelog__container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="changelog__container">
      <h4>Change Log</h4>
      <ul data-cy="changelog-modal" className="changelog__list">
        {fieldHistory.length === 0 && (
          <li className="field-popover__list-item">
            <p className="rating-txt">No changes found.</p>
          </li>
        )}
        {fieldHistory.map(
          field =>
            field && (
              <HistoryItem
                key={field.id}
                field={field}
                allowCopy={fieldSupportsCopy(metadata)}
                metadata={metadata}
              />
            )
        )}
      </ul>
    </div>
  );
}

const FieldChangeLog = memo(function FieldChangeLog({
  templateKey,
  shouldDisplayBadge,
}: {
  templateKey: string;
  shouldDisplayBadge: boolean;
}): JSX.Element | null {
  const { isActive: isActiveFieldContext } = useContext(TableContext);
  if (isActiveFieldContext) return null;

  const [open, setOpen] = useState(false);
  const { readFieldFromCache, entityOwnerIds } = useContext(TemplateContext);
  const [ownerType, fieldKey] = templateKey.split(".");
  const { metadata } = readFieldFromCache(templateKey);

  const entityId = useMemo(() => {
    if (entityOwnerIds) return Number(entityOwnerIds[ownerType].id);
    return null;
  }, [entityOwnerIds]);

  if (metadata.value.hideChangelog) return null;
  const [fetchFieldHistory, { data, error, called, loading }] = useLazyQuery(
    GET_FIELD_HISTORY(ownerType, fieldKey),
    {
      variables: {
        id: entityId,
        filter: {
          fieldKey,
          showOnlyPublished: true,
        },
        sort: [
          {
            field: "modifyDate",
            order: "DESC",
          },
        ],
      },
      // do not cache field history results
      fetchPolicy: "no-cache",
    }
  );

  const fieldHistory: Field[] = useMemo(
    () => get(data, [ownerType, "fieldHistory"], []),
    [data, templateKey, called]
  );

  if (error) {
    console.error(error);
  }

  function handleClick() {
    if (entityId) {
      fetchFieldHistory();
    }
    setOpen(true);
  }

  return (
    <>
      <span
        data-cy="changelog-badge"
        className="changelog-badge"
        onClick={handleClick}
      >
        <Badge>
          <ClockCircleFilled
            style={{
              fontSize: "10px",
              zIndex: 50000,
              transform: "translate(-5px, -5px)",
              visibility: shouldDisplayBadge ? "visible" : "hidden",
            }}
          />
        </Badge>
      </span>
      <Modal visible={open} onCancel={() => setOpen(false)} title="">
        <FieldHistory
          fieldHistory={fieldHistory}
          loadingHistory={loading}
          metadata={metadata}
        />
      </Modal>
    </>
  );
});

export default FieldChangeLog;
