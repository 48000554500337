import { ExpandAltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { lookup } from "mime-types";
import { extname } from "path";
import { useState } from "react";
import { SUPPLEMENTAL_DILIGENCE_VISUALS } from "../../../utils/constants";
import DocumentsIcon from "../../Documents/DocumentsIcon";
import PreviewModal from "../../Documents/document_preview";
import { PartialDocument } from "../types";

const ImagePreview = ({
  doc: { name, signedThumbnailUrl, signedUrl },
}: {
  doc: NonNullable<PartialDocument["document"]>;
}) => {
  const [preview, changePreview] = useState<boolean>(false);
  const contentType = extname(name);

  const StaticImage = () => {
    if (!!signedThumbnailUrl) {
      return (
        <div className="field__doc-preview-thumbnail-wrapper">
          <Button
            className="field__doc-preview-expand-button"
            data-testid="field__doc-preview-expand-button"
            onClick={() => changePreview(true)}
            icon={<ExpandAltOutlined />}
            size="small"
          />

          <img
            className="field__doc-preview-thumbnail"
            src={signedThumbnailUrl}
            alt={name}
          />
        </div>
      );
    }
    return (
      <DocumentsIcon
        iconClassName="doc-tile__preview-icon"
        mimeType={lookup(name)}
      />
    );
  };

  return (
    <>
      {preview && (
        <PreviewModal
          title={name}
          visible={preview}
          signedUrl={signedUrl || ""}
          signedThumbnailUrl={signedThumbnailUrl || ""}
          contentType={contentType}
          onCancel={() => {
            changePreview(false);
          }}
          documentTypeEnum={SUPPLEMENTAL_DILIGENCE_VISUALS}
          showDetails={false}
          allowDownload={true}
          createDate=""
        />
      )}
      <StaticImage />
    </>
  );
};

export default function FileStatic({
  documents,
  notApplicable,
}: {
  documents: PartialDocument[];
  notApplicable?: boolean | null;
}) {
  if (notApplicable) {
    return null;
  }
  const docList = documents.flatMap(doc =>
    !doc.document ? [] : { id: doc.id, document: doc.document }
  );
  if (!docList.length) return null;
  if (docList.length > 1) {
    return (
      <div className="field__doc-preview-row">
        {docList.map(({ id, document: doc }) => (
          <ImagePreview doc={doc} key={id} />
        ))}
      </div>
    );
  }

  return <ImagePreview doc={docList[0].document} />;
}
