/* eslint-disable react/jsx-props-no-spreading */
import * as types from "_graphql-types-frontend/graphql";
import { Select } from "antd";
import { useCallback, useContext } from "react";
import { useCurrentUser } from "../../../../utils/hooks";
import { TemplateContext } from "../../Context";
import { FieldStamp } from "../FieldAuditStamp";
import { useTemplateController } from "../FieldContext";
import { InputFieldProps } from "./InputFieldBase";

const { Option } = Select;

export const OPTIONS = new Map([
  [null, ""],
  ["true", "Yes"],
  ["false", "No"],
  ["N/A", "N/A"],
]);

export default function BooleanDropdownNaFieldInput({
  templateKey,
  metadata,
  templateOverride,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const questionText = templateOverride?.questionText || metadata.questionText;

  const {
    flags: { isRockCreek },
  } = useCurrentUser();

  const { readFieldFromCache } = useContext(TemplateContext);
  const {
    field: { onChange, ...inputProps },
  } = useTemplateController({
    name: formLookupKey,
  });

  const { field: managerData } = readFieldFromCache(
    templateKey,
    types.FieldState.Mgr
  );

  const handleChange = useCallback(
    (value: string | null) => onChange(value),
    []
  );

  return (
    <NotApplicableWrapper {...{ templateKey, metadata }}>
      <>
        {!!questionText && (
          <div
            data-cy="template__textarea"
            data-test-id="template__textarea"
            className={"main-input__info"}
          >
            <div className="main-input__sublabel">{questionText}</div>
          </div>
        )}
        <Select
          {...inputProps}
          defaultValue={inputProps.value}
          style={{ marginRight: "20px", maxWidth: "100px" }}
          className="main-select"
          dropdownClassName="main-select__menu"
          onChange={handleChange}
          value={inputProps.value}
        >
          {[...OPTIONS].map(([value, label]) => (
            <Option key={value} value={value} title={label}>
              {label}
            </Option>
          ))}
        </Select>
        {typeof managerData?.value === "string" && isRockCreek && (
          <div className="rating-txt">
            Manager Entry: {OPTIONS.get(managerData.value)}.
            <div className="d-flex flex-wrap">
              <FieldStamp field={managerData} />
              <button
                onClick={() => handleChange(managerData.value)}
                type="button"
                className="in-blue-500"
              >
                <span> Move to draft </span>
              </button>
            </div>
          </div>
        )}
      </>
    </NotApplicableWrapper>
  );
}
