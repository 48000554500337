/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { InputNumber, Tooltip } from "antd";
import { useCallback, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import { useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

export default function CurrencyInput({
  metadata,
  templateOverride,
  formLookupKey,
  templateKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);

  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const {
    field: { onChange, value, onBlur, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NotApplicableWrapper {...{ metadata, templateKey }}>
        <>
          {!!questionText && (
            <div
              data-cy="template__textarea"
              data-test-id="template__textarea"
              className={"main-input__info"}
            >
              <div className="main-input__sublabel">{questionText}</div>
            </div>
          )}
          <div className="field-input-children-with-changelog">
            <FieldChangeLog
              templateKey={templateKey}
              shouldDisplayBadge={focus}
            />
            {/* Maximum update depth exceeded
            
              <Tooltip
              title={metadata.tooltip}
              placement="bottom"
              overlayStyle={{ fontSize: 10 }}              
            > */}
            <InputNumber
              className="main-input-number"
              placeholder={displayName ?? ""}
              value={value}
              onChange={onChange}
              formatter={(value: any) =>
                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "150px", borderRadius: "3px", padding: 0 }}
              {...inputProps}
              status={error && "error"}
              prefix={
                error ? (
                  <Tooltip title={error.message}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                ) : (
                  <span />
                )
              }
            />
            {/* </Tooltip> */}
          </div>
        </>
      </NotApplicableWrapper>
    </div>
  );
}
