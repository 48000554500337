import * as types from "_graphql-types-frontend/graphql";
import { gql } from "_graphql-types-frontend";
import { dynamicgql } from "../../../utils/graphql";
import { capitalize } from "lodash";
import { NO_QUERY } from ".";
import { TemplateConfig } from "./config";
import { generateTemplateFragmentKey } from "../Template.helpers";
import { FieldInput } from "../types";
import { getDynamicQueryFragment } from "./configHelpers";
import uuid from "../../../utils/uuid";

export const FETCH_INVESTMENT_TEMPLATE = gql(`
  query FETCH_INVESTMENT_TEMPLATE(
    $id: Int!
    $name: FieldSetType!
    $version: String
    $state: String
  ) {
    investment(id: $id) {
      id
      firmId
      templateOptions(fieldSetType: $name)
      fieldData(fieldSetType: $name, version: $version, state: $state) {
        assetClassName
        fieldMeta
        fieldSet
        fieldSetType
        strategyName
        queryFragments
        version
      }
    }
  }
`);

const INVESTMENT_PARAMS = `
  $investmentFields: InvestmentFieldsInput!,
  $investmentId: Int!,
`;

const FIRM_PARAMS = `
$firmFields: FirmFieldsInput!,
$firmId: Int!,
`;

function investmentFieldsQuery(
  investmentQueryFragments: string | null,
  operation: "deleteFields" | "updateFields"
) {
  if (!investmentQueryFragments) return "";

  let investmentFieldOperation = "";
  switch (operation) {
    case "updateFields":
      investmentFieldOperation = "updateInvestmentFields";
      break;
    case "deleteFields":
      investmentFieldOperation = "deleteInvestmentFields";
      break;
    default:
      throw new Error("unexpected investment field operation");
  }

  return `${investmentFieldOperation}(
    fields: $investmentFields, investmentId: $investmentId, state: $state
  ) {
    investmentId
    state
    ${investmentQueryFragments}
  }
  `;
}

function firmFieldsQuery(
  firmQueryFragments: string | null,
  operation: "deleteFields" | "updateFields"
) {
  if (!firmQueryFragments) return "";

  let firmFieldOperation = "";
  switch (operation) {
    case "updateFields":
      firmFieldOperation = "updateFirmFields";
      break;
    case "deleteFields":
      firmFieldOperation = "deleteFirmFields";
      break;
    default:
      throw new Error("unexpected investment field operation");
  }

  return `${firmFieldOperation}(
    fields: $firmFields, firmId: $firmId, state: $state
  ) {
    firmId
    state
    ${firmQueryFragments}
  }
  `;
}

function getInvestmentFieldsQuery(operation: "deleteFields" | "updateFields") {
  return (
    fields: Record<string, { [key: string]: FieldInput }>,
    data: types.Fetch_Investment_TemplateQuery
  ) => {
    if (!data || !data.investment || !data.investment.fieldData) return null;

    const queryId = generateTemplateFragmentKey(operation);

    const firmQueryFragments = getDynamicQueryFragment({
      fields: fields.firm,
      queryId,
      queryFragments: data.investment.fieldData.queryFragments.firm,
    });

    const investmentQueryFragments = getDynamicQueryFragment({
      fields: fields.investment,
      queryId,
      queryFragments: data.investment.fieldData.queryFragments.investment,
    });

    // queryId needs to be dynamic to regenerate results appropriately.
    return {
      query: dynamicgql`
      mutation ${queryId} (
        ${firmQueryFragments ? FIRM_PARAMS : ""}
        ${investmentQueryFragments ? INVESTMENT_PARAMS : ""}
        $state: String
      ) {
          ${investmentFieldsQuery(investmentQueryFragments, operation)}
          ${firmFieldsQuery(firmQueryFragments, operation)}
      }
    `,
      variables: ({ state }: { state: string | null }) => ({
        ...(firmQueryFragments
          ? {
              firmId: data.investment.firmId,
              firmFields: fields.firm,
            }
          : {}),
        ...(investmentQueryFragments
          ? {
              investmentId: data.investment.id,
              investmentFields: fields.investment,
            }
          : {}),
        state,
      }),
    };
  };
}

const saveFieldsQuery = getInvestmentFieldsQuery("updateFields");
const deleteFieldsQuery = getInvestmentFieldsQuery("deleteFields");

export const investmentTemplateConfig: TemplateConfig = {
  templateQuery: FETCH_INVESTMENT_TEMPLATE,
  getTemplateData(data: types.Fetch_Investment_TemplateQuery) {
    return data.investment.fieldData;
  },
  getFieldsData(data: {
    investment: {
      fields: types.InvestmentFields;
    };
    firm: {
      fields: types.FirmFields;
    };
  }) {
    return {
      investment: data.investment.fields,
      firm: data.firm.fields,
    };
  },
  getTemplateDataQuery(
    name: string,
    data: types.Fetch_Investment_TemplateQuery
  ) {
    if (!data || !data.investment || !data.investment.fieldData)
      return {
        query: NO_QUERY,
        variables: () => ({}),
      };

    return {
      query: dynamicgql`
        query TemplateFields${name}(
          $firmId: Int!, $investmentId: Int!, $state: String
        ) {
          investment(id: $investmentId) {
            id
            fields(state: $state) {
              investmentId
              state
              ${data.investment.fieldData.queryFragments.investment}
            }
          }
          firm(id: $firmId) {
            id
            fields(state: $state) {
              firmId
              state
              ${data.investment.fieldData.queryFragments.firm}
            }
          }
        }`,
      variables: ({ state }: { state: string | null }) => ({
        investmentId: data.investment.id,
        firmId: data.investment.firmId,
        state,
      }),
    };
  },
  getEntityOwnerIds(data: types.Fetch_Investment_TemplateQuery) {
    if (!data) return null;
    if (!data.investment.firmId)
      throw new Error("Expected firm id for template investment.");
    return {
      firm: { id: data.investment.firmId, fieldKey: "firmId" },
      investment: { id: data.investment.id, fieldKey: "investmentId" },
    };
  },
  saveFieldsQuery,
  deleteFieldsQuery,
  updateTemplateQuery: (
    updateUserEmail: string,
    templateName: string,
    templateVersion: string,
    data: types.Fetch_Investment_TemplateQuery
  ) =>
    saveFieldsQuery(
      {
        investment: {
          [`investment${capitalize(templateName)}TemplateVersion`]: {
            id: uuid(),
            createDate: new Date(),
            createUser: updateUserEmail,
            ownerId: data.investment.id,
            ownerType: "investment",
            state: null,
            modifyDate: new Date(),
            modifyUser: updateUserEmail,
            fieldKey: `investment${capitalize(templateName)}TemplateVersion`,
            value: templateVersion,
          },
        },
      },
      data
    ),
};
