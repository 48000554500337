/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-lines-per-function */
import { notification } from "antd";
import { cloneDeep, get, isEmpty, isEqual } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from "react-hook-form";
import { useResizeDetector } from "react-resize-detector";
import { useDebouncedCallback } from "use-debounce";
import { useCurrentUser } from "../../utils/hooks";
import { TemplateContext } from "./Context";
import { KEYS_TO_OMMIT_ON_INPUT } from "./ImportFields/helpers";
import PublishTemplateHeading from "./PublishTemplateHeading";
import { Field, FieldInput } from "./types";

const isChanged = ({
  formDataValues,
  fieldData,
  entityKey,
  fieldKey,
}: {
  formDataValues: { [key: string]: { [key: string]: Field } };
  fieldData: Field;
  entityKey: string;
  fieldKey: string;
}) => {
  const currentValue = get(formDataValues, [entityKey, fieldKey]);
  if (!currentValue) return true;
  if (!fieldData) return true;

  if (
    isEqual(currentValue.value, fieldData.value) &&
    isEqual(currentValue.notApplicable, fieldData.notApplicable) &&
    isEqual(currentValue.documentIds, fieldData.documentIds)
  )
    return false;
  return true;
};

function TemplateServerWatcher({
  formDataValues,
}: {
  formDataValues: { [key: string]: { [key: string]: Field } };
}) {
  const currentUser = useCurrentUser();
  const { saveFields, isPersistingField } = useContext(TemplateContext);

  const [formServerUpdates, setFormServerUpdates] = useState<
    { new: NonNullable<Field>; prev: NonNullable<Field> }[]
  >([]);

  const [upForRetry, changeUpForRetry] = useState<
    Record<string, Record<string, FieldInput>>
  >({});

  const {
    handleSubmit,
    getValues,
    watch: formData,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useFormContext();

  const { dirtyFields, defaultValues, errors } = useFormState();

  useEffect(() => {
    if (!defaultValues) return;
    const currentFormState = getValues();
    const serverUpdates = Object.values(formDataValues)
      .map(value => {
        return Object.values(value);
      })
      .flat()
      .flat()
      .filter((field: Field) => {
        if (!field) return false;
        const currentFormField = get(currentFormState, [
          field.ownerType,
          field.fieldKey,
        ]);
        const newFromServer =
          field &&
          currentFormField &&
          currentFormField.modifyDate.getTime() < field.modifyDate.getTime();
        if (!newFromServer) return false;
        return newFromServer;
      });
    if (serverUpdates.length) {
      serverUpdates.forEach(change => {
        if (!change) return;
        const currentField = get(currentFormState, [
          change.ownerType,
          change.fieldKey,
        ]);
        if (!currentField) return;
        if (isPersistingField(change)) {
          return setValue(
            `${change.ownerType}.${change.fieldKey}.modifyDate`,
            change.modifyDate
          );
        }
        if (!isPersistingField(change)) {
          if (
            isChanged({
              formDataValues: currentFormState,
              fieldData: change,
              entityKey: change.ownerType,
              fieldKey: change.fieldKey,
            })
          ) {
            setFormServerUpdates(prev =>
              [
                {
                  new: change,
                  prev: currentField,
                },
              ]
                .concat(prev)
                .slice(0, 100)
            );
          }
          return setValue(`${change.ownerType}.${change.fieldKey}`, change);
        }
      });
    }
  }, [formDataValues]);

  const autoSave = async (data: {
    [key: string]: { [key: string]: Field };
  }) => {
    if (isEmpty(dirtyFields) && isEmpty(upForRetry)) return null;
    reset(data, {
      keepValues: true,
      keepDirty: false,
      keepTouched: true,
      keepIsValid: true,
      keepErrors: true,
    });

    let inputData: Record<string, Record<string, FieldInput>> = {};
    if (!isEmpty(upForRetry)) {
      inputData = upForRetry;
    }
    for (const [entityKey, fields] of Object.entries(dirtyFields)) {
      for (const fieldKey of Object.keys(fields || {})) {
        const fieldData = data[entityKey][fieldKey];
        if (
          fieldData &&
          isChanged({
            formDataValues,
            fieldData,
            entityKey,
            fieldKey,
          })
        ) {
          // Dirty data gets auto saved based off of defaultData merged with current fieldData.
          inputData[entityKey] = inputData[entityKey] || {};
          inputData[entityKey][fieldKey] = omitDeep(
            {
              ...formDataValues[entityKey][fieldKey],
              ...fieldData,
            },
            KEYS_TO_OMMIT_ON_INPUT
          );
        }
      }
    }

    if (!isEmpty(inputData)) {
      try {
        const res = await saveFields(inputData, currentUser.writeDraftState);
        changeUpForRetry({});
      } catch (e: any) {
        //set inputData fields to dirty in

        notification.error({
          key: "FieldAutoSaveError",
          message: `Error Auto Saving Drafts: ${e.message}`,
        });

        setError("Field Auto Save Error", {
          type: "custom",
          message: `Failed to Auto Save: \n ${e.message}`,
        });

        changeUpForRetry(inputData);

        //Retry if there was a failure
        debouncedSubmit();
        throw e;
      }
    }
  };

  const debouncedSubmit = useDebouncedCallback(async () => {
    if (Object.keys(errors).length > 0) {
      clearErrors();
    }

    let newErrors;

    const res = await handleSubmit(autoSave, error => {
      newErrors = errors;
    })();

    if (newErrors) {
      notification.error({
        key: "invalid_fields",
        message: `Invalid fields must be fixed.`,
      });
      return { error: true };
    }
  }, 10000);

  useEffect(() => {
    const subscription = formData(() => {
      debouncedSubmit();
    });
    return () => subscription.unsubscribe();
  }, [formData]);

  return (
    <PublishTemplateHeading
      formServerUpdates={formServerUpdates}
      onPublishToolBarClick={debouncedSubmit.flush}
    />
  );
}

function TemplateFormProvider({
  formDataValues,
  children,
}: {
  formDataValues: { [key: string]: { [key: string]: Field } };
  children: React.ReactNode;
}) {
  const defaultValues = useMemo(() => {
    return cloneDeep(formDataValues);
  }, []);

  const methods = useForm({
    // https://github.com/react-hook-form/react-hook-form/discussions/3715
    defaultValues,
  });

  const { width, ref } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 100,
    handleHeight: false,
  });

  const FixedStyle: React.CSSProperties = useMemo(() => {
    return {
      position: "fixed",
      bottom: 0,
      left: ref.current && ref.current.getBoundingClientRect().left,
      width,
      zIndex: 2,
    };
  }, [ref.current && ref.current.getBoundingClientRect().left, width]);

  const memoized_watcher = useMemo(() => {
    return <TemplateServerWatcher formDataValues={formDataValues} />;
  }, [formDataValues]);

  return (
    <div className="main-content-template" ref={ref}>
      <FormProvider {...methods}>
        <div style={FixedStyle as any}>{memoized_watcher}</div>
        <form>{children}</form>
      </FormProvider>
    </div>
  );
}

export default function TemplateForm({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element | null {
  const { formDataValues } = useContext(TemplateContext);

  if (!formDataValues) {
    console.warn("No draft data or published data loaded or user email");
    return null;
  }

  return (
    <TemplateFormProvider formDataValues={formDataValues}>
      {children}
    </TemplateFormProvider>
  );
}
