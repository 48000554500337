import { useMemo } from "react";
import { Metadata } from "../../types";
import { useTemplateController } from "../FieldContext";

interface WrapperProps {
  children: JSX.Element;
  templateKey: string;
  metadata?: Metadata;
}

function NotApplicableWrapper({
  children,
  templateKey,
  metadata,
}: WrapperProps) {
  const {
    field: { onChange, value, onBlur, ...inputProps },
  } = useTemplateController({
    name: `${templateKey}.notApplicable`,
  });

  const CheckInput = () => (
    <div className={"main-checkbox"} style={{ marginRight: "20px" }}>
      <input
        id={`${templateKey}.notApplicable`}
        type="checkbox"
        data-testid={`${templateKey}.notApplicable`}
        {...inputProps}
        className="main-checkbox__input"
        onChange={e => {
          onChange(e.target.checked);
        }}
        defaultChecked={!!value as boolean}
      />
      <label
        className="main-checkbox__label"
        htmlFor={`${templateKey}.notApplicable`}
      >
        <b>N/A</b>
      </label>
    </div>
  );

  const WRAPPED_CONTENT = useMemo(() => {
    const rowDisplay: boolean = !!(
      metadata?.displayType &&
      [
        "boolean_dropdown",
        "boolean",
        "select",
        "integer",
        "currency",
        "date-yearly",
        "text",
        "percent",
      ].includes(metadata.displayType)
    );
    return {
      width: "100%",
      ...(rowDisplay
        ? {
            display: "flex",
            flexDirection: "row-reverse", // We Want the checkbox second in these row situations.
            justifyContent: "start",
          }
        : {}),
    };
  }, [metadata?.displayType]);

  if (value) {
    return (
      <div>
        <CheckInput />
      </div>
    );
  }
  return (
    <div style={WRAPPED_CONTENT as any}>
      <CheckInput />
      {children}
    </div>
  );
}

export default NotApplicableWrapper;
