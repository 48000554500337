/* eslint-disable react/jsx-props-no-spreading */
import { Select } from "antd";
import { useCallback, useState } from "react";
import { useController } from "react-hook-form";
import FieldChangeLog from "../FieldChangeLog";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

const availableRatings = [1, 2, 3, 4, 5];

function RatingInput({
  templateKey,
  metadata,
  formLookupKey,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  function handleChange(value: number) {
    if (!value) {
      return onChange(null);
    }
    return onChange(value);
  }

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div
      style={{ width: "75px" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="main-input">
        <label htmlFor={formLookupKey} className="main-input__label">
          Rating
        </label>

        <div className="field-input-children-with-changelog">
          <FieldChangeLog
            templateKey={templateKey}
            shouldDisplayBadge={focus}
          />
          <div className="width-60">
            <Select
              ref={ref}
              id={formLookupKey}
              optionLabelProp="title"
              className="main-select"
              data-testid="rating__input"
              dropdownClassName="main-select__menu"
              onChange={handleChange}
              {...inputProps}
            >
              <Select.Option
                className="main-select__menu-item-def"
                disabled={!metadata.value.nullable}
                value={0}
                title="Select"
              >
                Select
              </Select.Option>
              {availableRatings.map(rating => (
                <Select.Option
                  title={`${rating}`}
                  key={`${rating}`}
                  value={rating}
                >
                  {rating}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RatingInput;
