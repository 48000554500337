import * as types from "_graphql-types-frontend/graphql";
import { useContext, useState } from "react";
import { TemplateContext } from "../../Context";
import Static from "../../Static";
import { Metadata } from "../../types";
import FieldFileDisplay from "../FieldFileDisplay";

export default function FieldTextDisplay({
  templateKey,
  metadata,
  notApplicable,
}: {
  templateKey: string;
  metadata: Metadata;
  notApplicable?: boolean | null;
}): JSX.Element | null {
  const { readFieldFromCache } = useContext(TemplateContext);

  const { field: managerField } = readFieldFromCache(
    templateKey,
    types.FieldState.Mgr
  );
  const { field: publishedField } = readFieldFromCache(templateKey, null);

  const [selectedField, setSelectedField] = useState(
    publishedField || managerField
  );

  const hasMultipleFields = !!(publishedField && managerField);

  const ownerSource =
    selectedField && selectedField.state === null ? "RockCreek" : "Manager";

  return (
    <div
      data-testid="fieldTextDisplay"
      className="d-flex justify-between flex-wrap"
    >
      <div className="flex-column">
        <Static
          displayType={metadata.displayType as string}
          value={selectedField && selectedField.value}
          notApplicable={selectedField?.notApplicable}
          metadata={metadata}
        />
        <FieldFileDisplay
          documents={selectedField?.documents ?? []}
          notApplicable={selectedField?.notApplicable}
        />
      </div>
      {hasMultipleFields && (
        <button
          type="button"
          data-cy="field-owner-source-toggle"
          className="in-secondary-blue font-12 mr-10 ml-auto"
          onClick={() => {
            if (!hasMultipleFields) return;
            if (selectedField === publishedField) {
              setSelectedField(managerField);
            } else {
              setSelectedField(publishedField);
            }
          }}
        >
          {ownerSource}
          &nbsp; <i className="fa-solid fa-angle-right" />
        </button>
      )}

      {!hasMultipleFields && (
        <div
          data-cy="field-owner-source"
          className="in-secondary-blue font-12 mr-10 ml-auto"
          style={{ alignContent: "right" }}
        >
          {ownerSource}
        </div>
      )}
    </div>
  );
}
