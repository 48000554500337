/* eslint-disable jsx-a11y/control-has-associated-label */
import { GenericProp } from "_graphql-types-frontend/graphql";
import classnames from "classnames";
import { camelCase, get, kebabCase, lowerCase, startCase } from "lodash";
import InputFieldBase, { InputFieldProps } from "./InputFieldBase";

import { TableContext, useTableContext } from "../FieldTableContext";

export function sortColsByDisplayOrder(
  genericProps: GenericProp[]
): GenericProp[] {
  return genericProps
    .filter(
      (prop): prop is Required<GenericProp> => prop.displayOrder !== undefined
    )
    .sort((a, b) => Number(a.displayOrder) - Number(b.displayOrder));
}

function generateTableHeaders(rowProps: GenericProp) {
  return (
    <tr className="cms-striped-table__header field-default-edit__row">
      {sortColsByDisplayOrder(rowProps.props).map(prop => (
        <td key={`header__${prop.propertyKey}`} className={prop.propertyKey}>
          {get(prop, "displayName", startCase(prop.propertyKey))}
        </td>
      ))}
      <td />
    </tr>
  );
}

function TableRow({
  genericProps,
  rowLookupKey,
  iRow,
  deleteRow,
  templateKey,
}: {
  genericProps: GenericProp;
  rowLookupKey: string;
  iRow: number;
  deleteRow: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
  templateKey: string;
}) {
  const tableCols = sortColsByDisplayOrder(genericProps.props);
  return (
    <tr data-testid={`table__row-${iRow}`}>
      {tableCols.map(gProp => {
        return (
          <td
            key={`table-row__${gProp.propertyKey}::${iRow}`}
            data-testid={`table__cell-${iRow}-${gProp.propertyKey}`}
            className={classnames(
              "field-table__cell-content",
              "field-default-edit__cell-content",
              gProp.propertyKey,
              `cell-${lowerCase(gProp.displayType ?? "")}`
            )}
          >
            <InputFieldBase
              templateKey={`${templateKey}.${rowLookupKey}.${gProp.propertyKey}`}
              formLookupKey={`${rowLookupKey}.${gProp.propertyKey}`}
              metadata={{
                displayName: "",
                displayType: gProp.displayType,
                options: gProp.options,
                value: {
                  ...gProp,
                  hideChangelog: true,
                  hideTimeStamp: true,
                },
              }}
            />
          </td>
        );
      })}
      <td className="actions-cell">
        <div
          style={{ float: "right" }}
          data-testid={`remove-row-button-${iRow}`}
          onClick={event => deleteRow(event, iRow)}
        >
          <button
            type="button"
            className="cms-striped-table__icon icon-basket"
          />
        </div>
      </td>
    </tr>
  );
}

function TableInput({
  metadata,
  templateOverride,
  templateKey,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const tableContext = useTableContext({
    metadata,
    templateKey,
    formLookupKey,
  });

  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const { fieldState, fieldUuids, addRow, deleteRow, rowMetadata } =
    tableContext;

  return (
    <NotApplicableWrapper {...{ metadata, templateKey }}>
      <TableContext.Provider value={tableContext}>
        <div>{questionText}</div>
        <table
          id={templateKey}
          data-cy={`field-table__${camelCase(displayName ?? "")}`}
          className={classnames(
            "cms-striped-table",
            "field-default-edit",
            kebabCase(displayName ?? "")
          )}
        >
          <tbody>
            {generateTableHeaders(rowMetadata)}
            {fieldState &&
              fieldState.length > 0 &&
              fieldState.map((_row: any, irow: number) => (
                <TableRow
                  key={`tableRow__${rowMetadata.propertyKey}_${fieldUuids[irow]}`}
                  iRow={irow}
                  rowLookupKey={`${rowMetadata.propertyKey}[${irow}]`}
                  deleteRow={deleteRow}
                  genericProps={rowMetadata}
                  templateKey={templateKey}
                />
              ))}
          </tbody>
        </table>
        <button
          type="button"
          data-testid="add-row-button"
          onClick={addRow}
          className="cms-overview__add-btn"
        >
          <i className="cms-overview__add-btn-icon icon icon-plus" />
          <span className="cms-overview__add-btn-txt">Add New Row</span>
        </button>
      </TableContext.Provider>
    </NotApplicableWrapper>
  );
}

export default TableInput;
