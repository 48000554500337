import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
  format,
  isAfter,
  parseISO,
} from "date-fns";

export const asFullDateTime = (date: Date) => {
  if (!date) {
    return "";
  }
  return formatDate(date, "dd-MMM-yyyy, hh:mm");
};

export const formatDate = (
  value: string | number | Date,
  frmt = DEFAULT_DATE_FORMAT
) => {
  if (!value) return null;
  const date = intoDate(value);
  return format(date, frmt);
};

export const intoDate = (value: string | number | Date) => {
  const date =
    typeof value === "string"
      ? parseISO(value)
      : typeof value === "number"
      ? new Date(value)
      : value;
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date value: '${value}'`);
  }
  return date;
};

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
