import { findKey, partial, isEqual } from "lodash";
import { tagSample } from "../helper";
import { SDGIcon } from "../index";

interface SDGMapping {
  "01": string;
  "02": string;
  "03": string;
  "04": string;
  "05": string;
  "06": string;
  "07": string;
  "08": string;
  "09": string;
  "10": string;
  "11": string;
  "12": string;
  "13": string;
  "14": string;
  "15": string;
  "16": string;
  "17": string;
}

const sdgMapping: SDGMapping = {
  "01": "No Poverty",
  "02": "Zero Hunger",
  "03": "Good Health & Well-Being",
  "04": "Quality Education",
  "05": "Gender Equality",
  "06": "Clean Water & Sanitation",
  "07": "Affordable & Clean Energy",
  "08": "Decent Work & Economic Growth",
  "09": "Industry Innovation and Infrastructure",
  "10": "Reduced Inequalities",
  "11": "Sustainable Cities & Communities",
  "12": "Responsible Consumption and Production",
  "13": "Climate Action",
  "14": "Life Below Water",
  "15": "Life on Land",
  "16": "Peace Justice and Strong Institutions",
  "17": "Partnerships for the Goals",
};

export function getAffiliationLogoPath(fieldKey: string): string {
  const key = fieldKey.split("firmImpactAffiliation")[1];
  let ext = "png";
  if (key === "Asria" || key === "Africasif") {
    ext = "jpeg";
  }
  return `assets/FirmAffiliations/${key}.${ext}`;
}

export function getSDGNumberFromTag(tag: tagSample): string | undefined {
  // https://github.com/lodash/lodash/issues/528
  return findKey(sdgMapping, partial(isEqual, tag.name));
}

export function getSDGIconPath(
  tag: tagSample,
  bgFilled = false
): string | null {
  const key = getSDGNumberFromTag(tag);
  if (!key) return null;
  return `/assets/images/SDG/${
    bgFilled ? "E-WEB-Goal" : "SDG_Icons_WEB"
  }-${key}.png`;
}
