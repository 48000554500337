import { UseControllerProps, UseControllerReturn } from "react-hook-form";
import { round, isEmpty } from "lodash";
import { useDebounce } from "use-debounce";
import { useEffect, useState, useCallback } from "react";
import { GenericProp } from "_graphql-types-frontend/graphql";
import { Field } from "../types";

export function getMostRecentField(field: Field, draftField: Field): Field {
  if (
    (!field && draftField) ||
    (field && draftField && draftField.modifyDate > field?.modifyDate)
  ) {
    return draftField;
  }

  return field;
}

export const percentParser = (displayValue?: string): number | undefined => {
  if (displayValue === undefined || displayValue === "") return undefined;
  if (Number(displayValue) === 0) return 0;
  return round(Number(displayValue) / 100.0, 5);
};

export const percentFormatter = (val?: number | string): string | undefined => {
  if (val === undefined) return "";
  if (typeof val === "string") {
    return `${round(Number(val) * 100.0, 2)}`;
  }
  return `${round(val * 100.0, 2)}`;
};

type FormRules = UseControllerProps["rules"];

export function getFormRulesFromMetadata(
  genericProps: GenericProp
): FormRules | null {
  const { nullable, length, max, min, isEmail, isUrl, isUUID } = genericProps;

  const rules: FormRules = {
    ...(!nullable && { required: { value: true, message: "must be present" } }),
    ...((max || max === 0) && {
      max: {
        value: max,
        message: `must be less than ${max}`,
      },
    }),
    ...((min || min === 0) && {
      min: {
        value: min,
        message: `must be greater than ${min}`,
      },
    }),
    ...((length?.min || length?.min === 0) && {
      minLength: {
        value: length.min,
        message: `must contain atleast ${length.min} items`,
      },
    }),
    ...((length?.max || length?.max === 0) && {
      maxLength: {
        value: length.max,
        message: `must contain at most ${length.max} items`,
      },
    }),
    ...(isEmail && {
      pattern: { value: /^[^@]+@[^@]+$/, message: "not a valid email address" },
    }),
    ...(isUrl && {
      pattern: { value: /^(?!.*?:\/\/)(.){1,}$/, message: "not a valid url" },
    }),
    ...(isUUID && {
      pattern: {
        value: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
        message: "not a valid UUID",
      },
    }),
  };

  if (isEmpty(rules)) return null;

  return rules;
}

export function truncateFieldHistoryItem(
  value: string,
  maxCharLength = 500
): { value: string; isTruncated: boolean } {
  if (value.length > maxCharLength && value.length - maxCharLength > 100) {
    const truncatedValue = value.slice(0, maxCharLength);
    return { value: `${truncatedValue}...`, isTruncated: true };
  }
  return {
    value,
    isTruncated: false,
  };
}
