import RatingIconBig0 from "./rating_big_0.svg";
import RatingIconBig1 from "./rating_big_1.svg";
import RatingIconBig2 from "./rating_big_2.svg";
import RatingIconBig3 from "./rating_big_3.svg";
import RatingIconBig4 from "./rating_big_4.svg";
import RatingIconBig5 from "./rating_big_5.svg";
import RatingIconSmall0 from "./rating_small_0.svg";
import RatingIconSmall1 from "./rating_small_1.svg";
import RatingIconSmall2 from "./rating_small_2.svg";
import RatingIconSmall3 from "./rating_small_3.svg";
import RatingIconSmall4 from "./rating_small_4.svg";
import RatingIconSmall5 from "./rating_small_5.svg";

type RatingIconProps = {
  size?: "small" | "big";
  ratingValue?: 0 | 1 | 2 | 3 | 4 | 5;
};

const getIcon = ({ size = "small", ratingValue }: RatingIconProps) => {
  return {
    small: {
      0: RatingIconSmall0,
      1: RatingIconSmall1,
      2: RatingIconSmall2,
      3: RatingIconSmall3,
      4: RatingIconSmall4,
      5: RatingIconSmall5,
    },
    big: {
      0: RatingIconBig0,
      1: RatingIconBig1,
      2: RatingIconBig2,
      3: RatingIconBig3,
      4: RatingIconBig4,
      5: RatingIconBig5,
    },
  }[size][ratingValue || 0];
};

function RatingIcon({ size = "small", ratingValue }: RatingIconProps) {
  return (
    <img
      data-testid="rating-icon"
      className={`va-middle d-inline-block rating-${size}`}
      src={getIcon({ size, ratingValue }) as any}
      alt={`rating ${ratingValue}`}
    />
  );
}

export default RatingIcon;
