/* eslint-disable react/jsx-props-no-spreading */
import { Select } from "antd";
import { useCallback } from "react";
import { useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";

const { Option } = Select;

export default function SelectInput({
  metadata,
  templateOverride,
  formLookupKey,
  templateKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);
  const questionText = templateOverride?.questionText || metadata.questionText;
  const {
    field: { onChange, ...inputProps },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  const handleChange = useCallback((value: string) => {
    if (!value) {
      return onChange(undefined);
    }
    return onChange(value);
  }, []);

  return (
    <>
      <div
        data-cy="template__textarea"
        data-test-id="template__textarea"
        className={"main-input__info"}
      >
        <div className="main-input__sublabel">{questionText}</div>
      </div>
      <NotApplicableWrapper {...{ templateKey, metadata }}>
        <Select
          defaultValue={inputProps.value}
          style={{ marginRight: "20px", maxWidth: "60%" }}
          className="main-select"
          dropdownMatchSelectWidth={false}
          dropdownClassName="main-select__menu"
          onChange={handleChange}
          {...inputProps}
        >
          {metadata.options?.map(option => (
            <Option key={option} value={option} title={option}>
              {option}
            </Option>
          ))}
        </Select>
      </NotApplicableWrapper>
    </>
  );
}
