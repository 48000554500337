/* eslint-disable max-lines-per-function */
import HighCharts from "highcharts";
import { getUniversalChartOptions } from "./universalOptions";
import { HChartType } from "./helpers";
import { BubbleChartSeries } from "./dataSeriesFormatters";

export interface IChartGenerator {
  highChartType: HChartType;
  dataSeries: BubbleChartSeries[];
  height?: number;
  width?: number;
  fontSize: number;
  allowExport: boolean;
  title?: string;
  subTitle?: string;
  pointWidth?: number;
  allowDecimals?: boolean;
  chartColors?: string[];
  formatSuffix?: string;
  formatPrefix?: string;
  dataLabelFormat?: string;
  tooltipFormat?: string;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
}

/**
 * generates chart options object for a packed bubble chart
 * @param dataSeries chart data formatted according to highChartType
 * @param height optional chart height
 * @param width optional chart width
 * @param fontSize determines size of chart labels/tickets/legend
 * @param allowExport true for usage in reports, false for rendering in browser
 * @param title optional chart title
 * @param subTitle optional chart subTitle
 * @param formatSuffix (optional) appended to chart values (eg. %)
 * @param formatSuffix (optional) prepended to chart values (eg. $)
 * @param dataLabelFormat (optional) highcharts format string
 * @param tooltipFormat (optional) highcharts format string
 * @param legendSymbolHeight (optional) height of the circle symbol in legend (defaults to fontsize)
 * @param legendItemMarginTop (optional) margin between items in legend
 */
export function getBubbleChartOptions({
  dataSeries,
  height,
  width,
  fontSize,
  allowExport,
  title,
  subTitle,
  chartColors,
  formatSuffix,
  formatPrefix,
  dataLabelFormat,
  tooltipFormat,
  legendSymbolHeight,
  legendItemMarginTop,
}: IChartGenerator) {
  const universalOptions = getUniversalChartOptions({
    title,
    subTitle,
    dataSeries: dataSeries as HighCharts.SeriesOptionsType[],
    allowExport,
    chartColors,
    fontSize,
  });

  const options: HighCharts.Options = {
    ...universalOptions,
    chart: {
      type: "packedbubble",
      ignoreHiddenSeries: false,
      plotShadow: false,
      style: {
        fontFamily: "Helvetica",
        fontSize: `${fontSize}px`,
      },
      width,
      height,
      alignTicks: false,
    },
    plotOptions: {
      packedbubble: {
        minSize: "55%",
        maxSize: "100%",
        dataLabels: {
          enabled: true,
          crop: false,
          format: dataLabelFormat ?? "{point.name}: {point.value}",
          style: {
            fontSize: `${fontSize}px`,
          },
        },
      },
    },
    tooltip: {
      ...(formatSuffix && { valueSuffix: formatSuffix }),
      ...(formatPrefix && { valuePrefix: formatPrefix }),
      useHTML: true,
      pointFormat: tooltipFormat ?? "<b>{point.name}:</b> {point.value}",
    },
    legend: {
      align: "center",
      itemMarginTop: legendItemMarginTop ?? 0,
      symbolHeight: legendSymbolHeight ?? fontSize,
      itemStyle: {
        fontSize: `${fontSize}px`,
      },
    },
  };
  return options;
}
