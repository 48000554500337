import { format } from "date-fns";
import { lookup } from "mime-types";
import Modal from "../Modal";
import VideoViewer from "./documents_video_viewer";
import DocumentsIcon from "./DocumentsIcon";

const Body = ({
  contentType,
  signedUrl,
  signedThumbnailUrl,
  name,
}: {
  contentType: string;
  signedUrl?: string;
  signedThumbnailUrl?: string;
  name: string;
}) => {
  if (contentType === ".mp4" || contentType === ".mov") {
    return <VideoViewer url={signedUrl} />;
  }
  if (signedThumbnailUrl) {
    return <img style={{ width: "100%" }} src={signedThumbnailUrl} />;
  }
  return (
    <div style={{ width: "100%", height: "500px", display: "flex" }}>
      <DocumentsIcon
        iconClassName="doc-tile__preview-icon"
        mimeType={lookup(name)}
      />
    </div>
  );
};

const DocumentDetails = ({
  documentTypeEnum,
  createUser,
  createDate,
}: {
  createUser?: string;
  createDate: string;
  documentTypeEnum: string;
}) => (
  <>
    <div>Type: {documentTypeEnum}</div>
    <div>
      Created By: {createUser} On:{" "}
      {format(new Date(createDate), "MMM do yyyy h:mm")}
    </div>
  </>
);

const DocumentDownload = ({ signedUrl }: { signedUrl?: string }) => (
  <a download href={signedUrl} data-testid="document-download">
    <i style={{ fontSize: 20 }} className="in-blue-500 icon icon-download" />
  </a>
);

const PreviewModal = ({
  visible,
  signedUrl,
  signedThumbnailUrl,
  title,
  onCancel,
  contentType,
  createUser,
  createDate,
  documentTypeEnum,
  showDetails = true,
  allowDownload = true,
}: {
  visible: boolean;
  signedUrl?: string;
  signedThumbnailUrl?: string;
  title: string;
  onCancel: () => void;
  contentType: string;
  createUser?: string;
  createDate: string;
  documentTypeEnum: string;
  showDetails?: boolean;
  allowDownload?: boolean;
}) => {
  return (
    <Modal visible={visible} title={title} onCancel={onCancel}>
      <div>
        <Body
          contentType={contentType}
          signedUrl={signedUrl}
          signedThumbnailUrl={signedThumbnailUrl}
          name={title}
        />
      </div>
      {showDetails || allowDownload ? (
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {showDetails && (
            <DocumentDetails
              {...{ createDate, createUser, documentTypeEnum }}
            />
          )}
          {allowDownload && <DocumentDownload signedUrl={signedUrl} />}
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default PreviewModal;
