import { FieldState } from "_graphql-types-frontend/graphql";
import { Tag } from "antd";
import { startCase } from "lodash";

export function FieldStateBadge({
  field,
}: {
  field: { state?: FieldState | null };
}): JSX.Element {
  const state = field.state || "published";

  return <Tag>{startCase(state)}</Tag>;
}
