import * as types from "_graphql-types-frontend/graphql";
import { addMonths } from "date-fns";
import { useMemo } from "react";
import AUMChart from "../../AUMChart";
import { Metadata } from "../types";

export default function AumTableStatic({
  value,
  currency = "",
  metadata,
}: {
  value: types.AumRecord[];
  currency?: string;
  metadata: Metadata;
}) {
  const chartData = useMemo<Array<Highcharts.SeriesOptionsType>>(
    () => [
      {
        name: metadata.displayName ?? "",
        data: (value || []).map(
          asset =>
            [addMonths(new Date(asset.asOfDate), 3).getTime(), asset.aum] as [
              number,
              number
            ]
        ),
        type: "column",
      },
    ],
    [value]
  );

  return (
    <AUMChart
      chartData={chartData}
      yAxisTitle={{ text: `AUM` + (currency && ` (${currency})`) }}
    />
  );
}
