import { saveAs } from "file-saver";

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}

export function isNotNull<T>(argument: T | null): argument is T {
  return argument !== null;
}

export function isDefinedAndNotNull<T>(
  argument: T | null | undefined
): argument is T {
  return isDefined(argument) && isNotNull(argument);
}

export function ensureDefined<T>(
  argument: T | undefined | null,
  message = "This value should never be undefined"
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function downloadFileLink(link: string, fileName: string) {
  return fetch(link)
    .then(response => response.blob())
    .then(blob => {
      saveAs(blob, fileName);
    });
}

export const humanizeMimeType = (mimeType: string | false) => {
  const mimeTypes = {
    pdf: ["application/pdf", "application/x-pdf"],
    word: [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    excel: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    image: ["image/gif", "image/png", "image/jpg", "image/jpeg"],
    video: [
      "video/ogg",
      "video/mp4",
      "video/avi",
      "video/mpeg",
      "video/webm",
      "video/msvideo",
      "video/x-msvideo",
      "video/quicktime",
      "application/x-troff-msvideo",
    ],
  };

  for (const humanized in mimeTypes) {
    if (!mimeTypes.hasOwnProperty(humanized)) {
      continue;
    }

    if (
      mimeType &&
      mimeTypes[humanized as keyof typeof mimeTypes].indexOf(mimeType) > -1
    ) {
      return humanized;
    }
  }

  return "other";
};
