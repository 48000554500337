import ContentItems from "../ContentItems.component";
import { ContentItem } from "../types";

export default function CardSection({
  content,
  Footer,
  displayName,
}: {
  content: ContentItem[];
  Footer?: React.FunctionComponent<{}>;
  displayName?: string;
}) {
  return (
    <div className="overview-page__card mt-20">
      <a id={`${(displayName || "").replace(/\s/g, "")}`} />
      <div className="section-card__content">
        <ContentItems content={content} />
        {Footer && <Footer />}
      </div>
    </div>
  );
}
