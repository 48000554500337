import {
  OperationVariables,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import gqltag from "graphql-tag";
import { gql } from "_graphql-types-frontend";
/*
  This tag is used to avoid detection by graphql type processing 
  and acknowledge dynamic gql usage.
  Usage of dynamic gql goes against graphql convention.
  Usage should be avoided, graphql has some support
  for work arounds for dynamic gql using directives, which should be 
  preferred when possible. Be careful.
*/
export const dynamicgql = gqltag;

export function useQueryWithSkip<T, V extends OperationVariables>(
  query: TypedDocumentNode<T, V>,
  variables: V | undefined
) {
  return useQuery<T, V>(query, { variables, skip: variables === undefined });
}

export const USER_SETTINGS = gql(`
  fragment UserSettingsAttributes on UserSettings {
    id
    data {
      defaultInvestmentView {
        type
        id
      }
    }
  }
`);

export const CURRENT_USER_QUERY = gql(/* GraphQL */ `
  query currentUser {
    currentUser {
      id
      organizationId
      sub
      username
      userName
      commonName
      email
      imageType
      imageTypeSm
      entryTypeId
      flags {
        ...UserFlagList
      }
      userSettings {
        ...UserSettingsAttributes
      }
      organization {
        name
        id
        isRockCreek
      }
      currentUserId
    }
  }
`);

export const USER_FLAGS = gql(/* GraphQL */ `
  fragment UserFlagList on UserFlags {
    DCAdmin
    isRockCreek
    rcgDataAccess
    isDataManager
    rcgDataEntry
    anyUser
    businessApplicant
    isRockCreekAdmin
    RECFReviewer
    canAssumeUser
    canSiriusChat
    isClientUser
    isPortalUser
    investmentPageAccess
  }
`);

export const INVESTMENT_PROFILE_FRAGMENT = gql(`
fragment InvestmentProfileFragment on GeneratedInvestmentProfile {
  name
  firmId
  firmName
  inceptionDate
  isImpact
  summary
  marketEnumId
  vehicleTypeEnumId
  assetClassEnumId
  strategyEnumId
  primaryGeographicFocusId
  primaryBenchmarkId
  secondaryBenchmarkId
  currencyDenominationEnumId
  vintageYear
  peLegalLifeYrs
  gpCommitted
  committedCapital
  targetRaise
  minTargetNetMOIC
  minTargetNetIRR
  maxTargetNetMOIC
  maxTargetNetIRR
}
`);

export const ADD_TEMP_FILES = gql(`
  mutation AddTempFiles($input: [TempFileUpload!]!) {
    addTempFiles(input: $input) {
      key
      bucket
      filename
      suggestions {
        documentTypeEnumId
        date
      }
    }
  }
`);

export const BUILD_INVESTMENT_PROFILE = gql(`
  query ProfileBuilder($keys: [String!]!, $bucket: String!) {
    investmentProfileBuilder(
      Keys: $keys
      bucket: $bucket
    ) {
      answer
      conversationId
      investmentProfile {
        ...InvestmentProfileFragment
      }
    }
  }
`);

export const ADD_USER_ACTIITY = gql(`
mutation AddUserActivity($input: UserActivityInput!) {
  addUserActivity(input: $input) {
    id
  }
}`);
