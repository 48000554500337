/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Input, Tooltip } from "antd";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import { get, isNaN } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import uuid from "../../../utils/uuid";
import { getPieChartOptions } from "../../HighCharts";
import { PieChartSeries } from "../../HighCharts/dataSeriesFormatters";
import { TemplateContext } from "../Context";
import FieldChangeLog from "../Field/FieldChangeLog";
import { useTemplateController } from "../Field/FieldContext";
import {
  getFormRulesFromMetadata,
  getMostRecentField,
  percentFormatter,
  percentParser,
} from "../Field/utils";
import { getSectionFieldKeys } from "../Template.helpers";
import { ContentItem, FieldContentItem, Metadata } from "../types";

interface ChartData {
  name: string;
  value: string | number;
  fieldKey: string;
}

function PieListTableRow({
  templateKey,
  metadata,
  rerenderChart,
}: {
  templateKey: string;
  metadata: Metadata;
  rerenderChart: () => void;
}) {
  const {
    value: { min, max },
  } = metadata;

  const formRules = getFormRulesFromMetadata(metadata.value);

  const {
    field: { ref, value, onChange, onBlur, ...inputProps },
    formState: { errors },
  } = useController({
    name: `${templateKey}.value`,
    ...(formRules && { rules: formRules }),
  });

  const [inputValue, setInputValue] = useState(percentFormatter(value));

  useEffect(() => {
    if (percentParser(value) !== inputValue)
      setInputValue(percentFormatter(value));
  }, [value]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setInputValue(value);
    rerenderChart();
    if (!isNaN(Number(value))) {
      onChange(percentParser(value));
    }
  }

  const error = get(errors, `${templateKey}.value`, "");

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <tr onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <td>{metadata.displayName}</td>

      <td>
        <div className="field-input-children-with-changelog">
          <FieldChangeLog
            templateKey={templateKey}
            shouldDisplayBadge={focus}
          />
          <div className="integer-field">
            <Input
              ref={ref}
              value={inputValue}
              className="main-input-number"
              onChange={handleChange}
              style={{ width: "80px", borderRadius: "3px" }}
              suffix="%"
              status={error && "error"}
              prefix={
                error ? (
                  <Tooltip title={<>{error.message}</>}>
                    <ExclamationCircleOutlined />
                  </Tooltip>
                ) : (
                  <span />
                )
              }
              {...inputProps}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}

function calculateChartOptions(editMode: boolean, chartData: ChartData[]) {
  const dataSeries: PieChartSeries[] = [
    {
      type: "pie",
      colorByPoint: true,
      data: chartData
        .filter(item => item && !!item.value)
        .map(({ name, value }) => ({
          name,
          y: Number(percentFormatter(value)),
        })),
    },
  ];

  let sizeFormatting = {
    height: 600,
    width: 800,
    diameter: 400,
  };

  if (editMode) {
    sizeFormatting = {
      height: 510,
      width: 500,
      diameter: 300,
    };
  }

  return getPieChartOptions({
    dataSeries,
    fontSize: 10,
    allowExport: false,
    allowDecimals: false,
    showAnimation: false,
    formatSuffix: "%",
    ...sizeFormatting,
  });
}

export default function PieSection({
  content,
  allowSectionNotApplicable,
}: {
  content: ContentItem[];
  allowSectionNotApplicable?: boolean;
}): JSX.Element {
  const {
    readFieldFromCache,
    readDraftFieldFromCache,
    editMode,
    publishedData,
  } = useContext(TemplateContext);
  const id = uuid();
  const fields = getSectionFieldKeys(content);
  const fieldControllers = editMode
    ? fields.map(
        field => useTemplateController({ name: `${field}.notApplicable` }).field
      )
    : [];
  const allPublishedFieldsAreNa = fields
    .map(
      field => publishedData?.[field.split(".")[0]]?.[`${field.split(".")[1]}`]
    )
    .every(field => field?.notApplicable);

  const allNa = editMode
    ? fieldControllers.every(({ value }) => value)
    : allPublishedFieldsAreNa;

  const [renderChart, updaterenderChart] = useState(0);

  const rerenderChart = () => {
    updaterenderChart(renderChart + 1);
  };

  const fieldContent = content.filter(
    ({ type }) => type === "field"
  ) as FieldContentItem[];

  const chartOptions = useMemo(() => {
    return calculateChartOptions(
      editMode,
      fieldContent.map(({ key }) => {
        const { field, metadata } = readFieldFromCache(key);
        if (editMode) {
          const { field: draftField } = readDraftFieldFromCache(key);
          const mostRecentField = getMostRecentField(field, draftField);
          return {
            name: metadata.displayName ?? "",
            value: mostRecentField && mostRecentField.value,
            fieldKey: key,
          };
        }
        return {
          name: metadata.displayName ?? "",
          value: field && field.value,
          fieldKey: key,
        };
      })
    );
  }, [editMode, fieldContent, rerenderChart]);

  if (!editMode) {
    return (
      <div className="d-flex justify-center">
        {allowSectionNotApplicable && allNa && (
          <div className={"main-checkbox"} style={{ marginRight: "20px" }}>
            <input
              id={`${id}.notApplicable`}
              type="checkbox"
              className="main-checkbox__input"
              checked={!!allNa as boolean}
              disabled
            />
            <label
              className="main-checkbox__label"
              htmlFor={`${id}.notApplicable`}
            >
              <b>N/A</b>
            </label>
          </div>
        )}
        <HighChartsReact
          options={chartOptions}
          highcharts={HighCharts}
          immutable
        />
      </div>
    );
  }

  //editMode
  return (
    <div data-cy="pie-chart__section" className="d-flex align-items-center">
      <div className="cms-pie-chart__col">
        <table className="crutch-table-double-col cms-striped-table">
          <colgroup>
            <col style={{ width: "246px" }} />
            <col />
          </colgroup>
          <tbody>
            {allowSectionNotApplicable && (
              <div className={"main-checkbox"} style={{ marginRight: "20px" }}>
                <input
                  id={`${id}.notApplicable`}
                  type="checkbox"
                  className="main-checkbox__input"
                  onClick={() => {
                    fieldControllers
                      .map(({ onChange }) => onChange)
                      .forEach(set => set(!allNa));
                  }}
                  defaultChecked={!!allNa as boolean}
                />
                <label
                  className="main-checkbox__label"
                  htmlFor={`${id}.notApplicable`}
                >
                  <b>N/A</b>
                </label>
              </div>
            )}
            {((allowSectionNotApplicable && !allNa) ||
              !allowSectionNotApplicable) &&
              fieldContent.map(({ key }) => {
                const { metadata } = readFieldFromCache(key);
                return (
                  <PieListTableRow
                    key={key}
                    templateKey={key}
                    metadata={metadata}
                    rerenderChart={rerenderChart}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="cms-pie-chart__col d-flex">
        <div className="m-auto">
          <HighChartsReact
            options={chartOptions}
            highcharts={HighCharts}
            immutable
          />
        </div>
      </div>
    </div>
  );
}
