import { startCase, get } from "lodash";
import { DataNode } from "antd/lib/tree";
import { notification } from "antd";
import {
  TemplateContextInterface,
  Field,
  ContentItem,
  Metadata,
  FieldInput,
} from "../types";
import { FieldState } from "_graphql-types-frontend/graphql";
import { isFieldPresent } from "../Template.helpers";

export const KEYS_TO_OMMIT_ON_INPUT = ["__typename", "documents"];

export const removeIdentificationKeys = (key: string) =>
  !["__typename", "state", "investmentId", "firmId"].includes(key);

export const fieldsDataIsEmpty = (
  fieldsData: TemplateContextInterface["publishedData"]
) =>
  fieldsData == null ||
  !Object.keys(fieldsData)
    .filter(removeIdentificationKeys)
    .some(ownerTypeKey => {
      const fields = fieldsData[ownerTypeKey];
      return Object.keys(fields)
        .filter(removeIdentificationKeys)
        .some(fieldKey => fields[fieldKey] && fields[fieldKey]?.value !== null);
    });

export function renderImportTree({
  content,
  path,
  importingFieldsData,
  getMetadata,
}: {
  content: ContentItem[];
  path: string;
  importingFieldsData: Record<string, Record<string, Field>>;
  getMetadata: (key: string) => Metadata;
}): DataNode[] {
  const getFieldDatum = (
    key: string
  ): { field: Field; metadata: Metadata } => ({
    field: get(importingFieldsData, key.split(".", 2)),
    metadata: getMetadata(key),
  });

  return content.flatMap((contentItem, index) => {
    switch (contentItem.type) {
      case "field":
        const { field, metadata } = getFieldDatum(contentItem.key);
        if (!metadata.readOnly && isFieldPresent(field)) {
          return [
            {
              title: startCase(contentItem.key.split(".", 2)[1]),
              key:
                (path && `${path}.field::${contentItem.key}`) ??
                contentItem.key,
            },
          ];
        }
        break;
      case "section":
        const children = renderImportTree({
          content: contentItem.content,
          path: `${path}.${index}`,
          importingFieldsData,
          getMetadata,
        });
        if (children.length) {
          if (contentItem.display_name) {
            return [
              {
                title: contentItem.display_name,
                key: `${path}.${index}`,
                children,
              },
            ];
          } else {
            return children;
          }
        }
        break;
    }
    return [];
  });
}

export function draftImportedFieldsOnToInvestment({
  keysToImport,
  targetEntityId,
  publishedSourceData,
  saveTargetFields,
  closeModal,
}: {
  keysToImport: string[];
  targetEntityId: number;
  publishedSourceData: TemplateContextInterface["publishedData"];
  saveTargetFields: TemplateContextInterface["saveFields"];
  closeModal: () => void;
}) {
  const fieldKeys = keysToImport
    .filter(key => key.includes("field::"))
    .map(key => {
      return key.split("field::")[1];
    });

  if (fieldKeys.length > 0 && targetEntityId) {
    const draftFields = fieldKeys.reduce(
      (
        accu: {
          [key: string]: {
            [key: string]: FieldInput;
          };
        },
        key: string
      ) => {
        const [owner, fieldKey] = key.split(".", 2);
        const field = get(publishedSourceData, [owner, fieldKey], null);
        if (field !== null) {
          const { ownerId, state, __typename, ...fieldValues } = field;
          const draftField = {
            ...fieldValues,
            ownerId: targetEntityId,
            state: FieldState.Draft,
          };
          return {
            ...accu,
            [owner]: {
              ...accu[owner],
              [fieldKey]: draftField as FieldInput,
            },
          };
        }
        return {
          ...accu,
          [owner]: {
            ...accu[owner],
          },
        };
      },
      {}
    );
    closeModal();
    saveTargetFields(draftFields, FieldState.Draft)
      .then(res => {
        if (res)
          notification.info({
            key: "import_fields_saved",
            message: "Fields imported as draft.",
          });
      })
      .catch(e => {
        notification.error({
          key: "import_fields_error",
          message: `Error Importing Fields: ${JSON.stringify(e)}`,
        });
        throw e;
      });
  }
}
