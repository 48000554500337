import { humanizeMimeType } from "../../utils/helpers";

const DocumentsIcon = ({
  mimeType,
  iconClassName,
}: {
  mimeType: string | false;
  iconClassName: string;
}) => {
  const icon = humanizeMimeType(mimeType);
  return <i className={`${iconClassName} main-doc-icon--${icon} icon`} />;
};

export default DocumentsIcon;
