export default function PageHeadingStatic({ value }: { value: string }) {
  return (
    <div
      data-cy="template-page-heading_static"
      className="sub-header mb-0 avoid-page-break-inside"
    >
      <p>{value}</p>
    </div>
  );
}
