import ContentItems from "../ContentItems.component";
import { ContentItem } from "../types";

export default function SubSection({
  content,
  display_name,
}: {
  content: ContentItem[];
  display_name?: string;
}) {
  return (
    <div>
      <h3>{display_name}</h3>
      <div>
        <ContentItems content={content} />
      </div>
    </div>
  );
}
