import { dynamicgql } from "../../../utils/graphql";
import { print } from "graphql/language/printer";
import { FieldInput } from "../types";

export function getDynamicQueryFragment({
  fields,
  queryId,
  queryFragments,
}: {
  queryFragments: any;
  fields: { [key: string]: FieldInput };
  queryId: string;
}): string | null {
  if (!fields) return null;

  const query: any = dynamicgql`
    query ${queryId}tempfields {
      ${queryFragments}
    }
  `;

  const { selectionSet } = query.definitions[0];
  selectionSet.selections = selectionSet.selections.filter(
    ({ name: { value } }: { name: { value: string } }) =>
      Object.keys(fields).includes(value)
  );
  return print(selectionSet.selections);
}
