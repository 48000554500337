import { Spin, notification } from "antd";
import * as types from "_graphql-types-frontend/graphql";
import { useEffect } from "react";
import ContentItems from "./ContentItems.component";
import { TemplateContext, useTemplate } from "./Context";
import TemplateForm from "./TemplateForm";
import TemplateNav from "./TemplateNav";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

interface TemplateComponentProps {
  name: types.FieldSetType;
  ownerId: number | string;
  ownerType: types.FieldOwnerType;
  editMode: boolean;
  children?: React.ReactNode;
  simplified?: boolean;
  onIsCompleted?: () => void;
  onPublish?: () => void;
  // TODO: provide better handling of modify date
  getModifyDate?: () => Date;
}

function TemplateComponent({
  name,
  ownerId,
  ownerType,
  children = null,
  getModifyDate,
  editMode,
  simplified,
  onIsCompleted,
  onPublish,
}: TemplateComponentProps): JSX.Element | null {
  const template = useTemplate({
    name,
    ownerId,
    ownerType,
    editMode,
    getModifyDate,
    simplified: simplified || false,
    onIsCompleted,
    onPublish,
  });

  let location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    const el =
      hash && document.getElementById(hash.substr(1).replace("_RETURN", ""));

    if (el) {
      console.log("ELEMENT TO SCROLL TO:", el);
      window.setTimeout(
        () => el.scrollIntoView({ behavior: "smooth", block: "start" }),
        0
      );
      window.location.href = window.location.href.replace("_RETURN", "");
    }
  }, [location.hash]);

  useEffect(() => {
    if (template.error) {
      notification.error({
        message: "Error",
        description: template.error.message,
        duration: 0,
      });
    }
  }, [template.error?.message]);

  if (template.loading) {
    return (
      <div
        data-cy="loading-spinner"
        data-testid="loading-spinner"
        className="text-center"
      >
        <Spin />
      </div>
    );
  }

  if (editMode) {
    return (
      <>
        {template.error && (
          <div
            data-cy="template-error"
            data-testid="template-error"
            className="text-center"
          >
            {template.error.message}
          </div>
        )}

        <ErrorBoundary>
          <TemplateContext.Provider value={template}>
            <div style={{ display: "flex", marginBottom: "100px" }}>
              <TemplateNav template={template} />
              <div style={{ width: "100%" }}>
                <TemplateForm>
                  {children}
                  <ContentItems content={template.content} />
                </TemplateForm>
              </div>
            </div>
          </TemplateContext.Provider>
        </ErrorBoundary>
      </>
    );
  }

  return (
    <>
      {template.error && (
        <div
          data-cy="template-error"
          data-testid="template-error"
          className="text-center"
        >
          {template.error.message}
        </div>
      )}
      <ErrorBoundary>
        <TemplateContext.Provider value={template}>
          {children}
          <div style={{ display: "flex" }}>
            <TemplateNav template={template} />
            <div style={{ width: "calc(100% - 55px)" }}>
              <ContentItems content={template.content} />
            </div>
          </div>
        </TemplateContext.Provider>
      </ErrorBoundary>
    </>
  );
}

export default TemplateComponent;
