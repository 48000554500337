/* eslint-disable no-shadow */
import { GenericProp } from "_graphql-types-frontend/graphql";
import { Card } from "antd";
import classnames from "classnames";
import { camelCase, get, lowerCase, startCase } from "lodash";
import { useMemo } from "react";
import { Metadata } from "../types";
import StaticFieldBase from "./StaticBase.component";

function ListTableRow({
  prop,
  key,
  keyValue,
}: {
  key: string;
  prop?: GenericProp;
  keyValue: string;
}) {
  if (!prop || !prop.displayType) return null; // skip missing prop keys __typename etc

  const metadata = useMemo(() => {
    return {
      displayName: "", // displayName nothing just incase child component has display considerations
      displayType: prop.displayType,
      value: { ...prop },
    };
  }, [prop]);

  return (
    <tr key={key} className={prop.propertyKey}>
      <td className="cms-striped-table__header">
        {get(prop, "displayName", startCase(prop.propertyKey))}
      </td>
      <td
        className={classnames(
          "field-table__cell-content",
          "field-table-default__cell-content",
          `field-cell-${lowerCase(prop.displayType)}`,
          `cell-${lowerCase(prop.displayType)}`
        )}
      >
        <StaticFieldBase
          displayType={prop.displayType}
          value={keyValue}
          metadata={metadata}
        />
      </td>
    </tr>
  );
}

function ListTableStatic({
  value: formValue,
  metadata,
  notApplicable,
}: {
  value: unknown;
  metadata: Metadata;
  notApplicable?: boolean | null;
}): JSX.Element {
  const rowMetadata = metadata.value.props[0];
  const rowPropertyKey = rowMetadata.propertyKey;
  const value = get(
    formValue as Record<typeof rowPropertyKey, Record<"id", string>[]>,
    rowMetadata.propertyKey,
    []
  );

  return (
    <div
      className="section-card__content-table-wrap"
      data-cy={`ListTableStatic__${camelCase(metadata.displayName ?? "")}`}
    >
      {notApplicable && <b>N/A</b>}
      {!notApplicable &&
        value.map((row, index) => {
          return (
            <Card key={row.id ?? index}>
              <table
                className={classnames(
                  "crutch-table-double-col",
                  "cms-striped-table"
                )}
              >
                <colgroup>
                  <col style={{ width: "246px" }} />
                  <col />
                </colgroup>
                <tbody>
                  {Object.entries(row).map(([key, keyValue]) => {
                    const prop = rowMetadata.props.find(({ propertyKey }) => {
                      return propertyKey === key;
                    });

                    return (
                      <ListTableRow key={key} prop={prop} keyValue={keyValue} />
                    );
                  })}
                </tbody>
              </table>
            </Card>
          );
        })}
    </div>
  );
}

export default ListTableStatic;
