import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { DatePicker, Tooltip } from "antd";
import { Metadata } from "../../types";
import { useTemplateController } from "../FieldContext";
import { getFormRulesFromMetadata } from "../utils";
import { InputFieldProps } from "./InputFieldBase";
import dayjs, { type Dayjs } from "dayjs";

type PickerType = "week" | "month" | "quarter" | "year";

function getPickerTypeFromMetadata({
  displayType,
}: Metadata): PickerType | undefined {
  if (displayType === "date-monthly") {
    return "month";
  }
  if (displayType === "date-yearly") {
    return "year";
  }
  return undefined;
}

function DateInput({
  metadata,
  formLookupKey,
  templateKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);

  const pickerType = getPickerTypeFromMetadata(metadata);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && {
      rules: { ...formRules },
    }),
  });

  function handleChange(dayjs: Dayjs | null) {
    if (!dayjs) return;
    const date = dayjs.toDate();
    onChange(date);
  }

  return (
    <NotApplicableWrapper {...{ metadata, templateKey }}>
      <div style={{ display: "flex" }}>
        <DatePicker
          status={error && "error"}
          onChange={handleChange}
          allowClear={false}
          defaultValue={(value as Date) && dayjs(value as Date)}
          picker={pickerType}
        />
        {error && (
          <Tooltip title={error.message}>
            <ExclamationCircleOutlined style={{ color: "red" }} />
          </Tooltip>
        )}
      </div>
    </NotApplicableWrapper>
  );
}

export default DateInput;
