import { formatDate } from "../../../utils/date";

function DateStatic({
  value,
  dateFormat,
  notApplicable,
}: {
  value?: string;
  dateFormat: string;
  notApplicable?: boolean | null;
}): JSX.Element | null {
  if (notApplicable) return <b>N/A</b>;
  if (!value) return null;
  return (
    <p data-cy="field__date-static avoid-page-break-inside">
      {formatDate(value, dateFormat)}
    </p>
  );
}

DateStatic.defaultProps = {
  value: undefined,
};

export default DateStatic;
