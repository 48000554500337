import FieldsBase from "./Field";
import SectionBase from "./Section";
import StaticBase from "./Static";

import { ContentItemDecorator, ContentItem as ContentItemType } from "./types";

function ContentItem({
  contentItem,
  Component,
}: {
  contentItem: ContentItemType;
  Component?: ContentItemDecorator;
}) {
  switch (contentItem.type) {
    case "field":
      return <FieldsBase contentItem={contentItem} Component={Component} />;
    case "section":
      return <SectionBase section={contentItem} Component={Component} />;
    case "static":
      return (
        <StaticBase
          value={contentItem.value}
          displayType={contentItem.display_type}
          metadata={contentItem.metadata}
          Component={Component}
        />
      );
    default:
      // eslint-disable-next-line no-console
      console.warn("INVALID CONTENT ITEM TYPE ", contentItem);
      return null;
  }
}

export default function ContentItems({
  content,
  Component,
}: {
  content: ContentItemType[];
  Component?: ContentItemDecorator;
}) {
  if (!content) return null;

  //order should not change so index is used as key.
  return (
    <>
      {content.map((contentItem, index) => {
        return (
          <ContentItem
            contentItem={contentItem}
            Component={Component}
            key={index}
          />
        );
      })}
    </>
  );
}
