import { useContext, useMemo } from "react";
import { useCurrentUser } from "../../../utils/hooks";
import { TemplateContext } from "../Context";
import Static from "../Static";
import { staticIsNotRenderable } from "../Static/StaticBase.component";
import {
  ContentItemDecorator,
  FieldContentItem as FieldContentItemType,
} from "../types";
import FieldTextDisplay from "./Display/FieldTextDisplay";
import InputFieldBase, { InputComponentFor } from "./Inputs";

export default function FieldBaseComponent({
  contentItem,
  Component,
}: {
  contentItem: FieldContentItemType;
  Component?: ContentItemDecorator;
}): JSX.Element | null {
  const {
    key: templateKey,
    display_name: displayName,
    question_text: questionText,
  } = contentItem;

  const templateOverride = { displayName, questionText };

  const { readFieldFromCache, editMode } = useContext(TemplateContext);

  const cachedFieldData = readFieldFromCache(templateKey);

  const { field, metadata } = cachedFieldData;

  const currentUser = useCurrentUser();

  if (!metadata.displayType) {
    console.warn("Missing metadata for field", field, metadata, templateKey);
    return null;
  }

  if (metadata.managerRestricted && !currentUser.flags.rcgDataAccess) {
    return null;
  }

  if (
    editMode &&
    InputComponentFor(metadata.displayType) &&
    !metadata.readOnly
  ) {
    return (
      <InputFieldBase
        templateKey={templateKey}
        templateOverride={templateOverride}
        metadata={metadata}
        Component={Component}
      />
    );
  }

  if (
    staticIsNotRenderable({
      value: field?.value,
      displayType: metadata.displayType,
      notApplicable: field?.notApplicable,
      documents: field?.documents,
    })
  )
    return null;

  const FieldContent = useMemo(() => {
    switch (metadata.displayType) {
      case "textarea":
        return (
          <FieldTextDisplay
            templateKey={templateKey}
            metadata={metadata}
            notApplicable={field?.notApplicable}
          />
        );
      default:
        return (
          <Static
            displayType={metadata.displayType as string}
            value={field?.value}
            notApplicable={field?.notApplicable}
            metadata={metadata}
          />
        );
    }
  }, [field, metadata, Component]);

  if (Component) {
    return (
      <Component
        displayName={templateOverride.displayName || metadata.displayName || ""}
        contentItemProps={{
          type: "static",
          displayType: metadata.displayType,
          value: field && field.value,
          metadata,
        }}
      >
        {FieldContent}
      </Component>
    );
  }
  return <>{FieldContent}</>;
}
