import classNames from "classnames";
import { camelCase } from "lodash";
import { useCallback, useMemo, useState } from "react";
import ContentItems from "../ContentItems.component";
import { ContentItemDecorator, SectionContentItem } from "../types";

export const SubSection: ContentItemDecorator = ({ children, displayName }) => {
  if (displayName) {
    return (
      <div
        data-cy={`subSection__${camelCase(displayName)}`}
        className="main-rating__section"
      >
        <p className="cms-subtitle">{displayName}</p>
        {children}
      </div>
    );
  }
  return <>{children}</>;
};

export default function ExpandableSection({
  content,
  expanded,
  expanded_text,
  collapsed_text,
}: {
  content: SectionContentItem[];
  expanded: boolean;
  expanded_text: string;
  collapsed_text: string;
}) {
  const [isExpanded, setExpanded] = useState(!!expanded);
  const toggledExpanded = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);

  const expandedSection = useMemo(() => {
    return content.find(item => item.display_type === "expanded");
  }, [content]);

  const collapsedSection = useMemo(() => {
    return content.find(item => item.display_type === "collapsed");
  }, [content]);

  return (
    <div className="section-columnar__column-content">
      <div
        className={classNames({
          "collapsed-section--collapsed": !isExpanded,
        })}
      >
        {collapsedSection && (
          <ContentItems
            content={collapsedSection.content}
            Component={SubSection}
          />
        )}
      </div>
      {isExpanded && expandedSection && (
        <div className="expandable__content">
          <ContentItems
            content={expandedSection.content}
            Component={SubSection}
          />
        </div>
      )}

      <div className="expandable__expander" onClick={toggledExpanded}>
        <button
          data-cy="template-expander"
          type="button"
          onClick={toggledExpanded}
          className="switch-btn"
        >
          {isExpanded && expanded_text}
          {!isExpanded && collapsed_text}
          <i
            className={classNames("switch-btn__icon icon icon--20", {
              "icon-arrow-up": isExpanded,
              "icon-arrow": !isExpanded,
            })}
          />
        </button>
      </div>
    </div>
  );
}
