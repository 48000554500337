import * as types from "_graphql-types-frontend/graphql";
import { gql } from "_graphql-types-frontend";
import { capitalize } from "lodash";
import { dynamicgql } from "../../../utils/graphql";
import { NO_QUERY } from ".";
import { TemplateConfig } from "./config";
import { generateTemplateFragmentKey } from "../Template.helpers";
import { getDynamicQueryFragment } from "./configHelpers";
import { FieldInput } from "../types";
import { v4 as uuid } from "uuid";
//import uuid from "../../../utils/uuid";

export const FETCH_COMPANY_TEMPLATE = gql(`
  query FETCH_COMPANY_TEMPLATE(
    $id: Int!
    $name: FieldSetType!
    $version: String
    $state: String
  ) {
    company(id: $id) {
      id
      templateOptions(fieldSetType: $name)
      fieldData(fieldSetType: $name, version: $version, state: $state) {
        assetClassName
        fieldMeta
        fieldSet
        fieldSetType
        strategyName
        queryFragments
        version
      }
    }
  }
`);

function getCompanyFieldsQuery(
  operation: "deleteCompanyFields" | "updateCompanyFields"
) {
  return (
    fields: Record<"company", { [key: string]: FieldInput }>,
    data: types.Fetch_Company_TemplateQuery
  ) => {
    if (!data || !data.company || !data.company.fieldData) return null;

    const queryId = generateTemplateFragmentKey(operation);

    const companyQueryFragments = getDynamicQueryFragment({
      fields: fields.company,
      queryId,
      queryFragments: data.company.fieldData.queryFragments.company,
    });

    // queryId needs to be dynamic to regenerate results appropriately.
    const companyId = data.company.id;
    return {
      query: dynamicgql`
    mutation ${queryId} (
      $companyFields: CompanyFieldsInput!
      $companyId: Int!
      $state: String
    ) {
        ${operation}(
          fields: $companyFields, companyId: $companyId, state: $state
        ) {
          companyId
          state
          ${companyQueryFragments}
        }
    }`,
      variables: ({ state }: { state: string | null }) => ({
        companyId,
        companyFields: fields.company,
        state,
      }),
    };
  };
}

const saveFieldsQuery = getCompanyFieldsQuery("updateCompanyFields");
const deleteFieldsQuery = getCompanyFieldsQuery("deleteCompanyFields");

export const comapanyTemplateConfig: TemplateConfig = {
  templateQuery: FETCH_COMPANY_TEMPLATE,
  getTemplateData(data: types.Fetch_Company_TemplateQuery) {
    return data?.company?.fieldData;
  },
  getFieldsData(data: {
    company: {
      fields: types.CompanyFields;
    };
  }) {
    return {
      company: data.company.fields,
    };
  },
  getTemplateDataQuery(name: string, data: types.Fetch_Company_TemplateQuery) {
    if (!data || !data.company || !data.company.fieldData)
      return {
        query: NO_QUERY,
        variables: () => ({}),
      };

    const companyId = data.company.id;

    return {
      query: dynamicgql`
        query TemplateFields${name}(
          $companyId: Int!, $state: String
        ) {
          company(id: $companyId) {
            id
            fields(state: $state) {
              companyId
              state
              ${data.company.fieldData.queryFragments.company}
            }
          }
        }`,
      variables: ({ state }: { state: string | null }) => ({
        companyId,
        state,
      }),
    };
  },
  getEntityOwnerIds(data: types.Fetch_Company_TemplateQuery) {
    if (!data || !data?.company?.id) return null;
    return {
      company: { id: data.company.id, fieldKey: "companyId" },
    };
  },
  saveFieldsQuery,
  deleteFieldsQuery,
  updateTemplateQuery: (
    updateUserEmail: string,
    templateName: string,
    templateVersion: string,
    data: types.Fetch_Company_TemplateQuery
  ) =>
    saveFieldsQuery(
      {
        company: {
          [`company${capitalize(templateName)}TemplateVersion`]: {
            id: uuid(),
            createDate: new Date(),
            createUser: updateUserEmail,
            ownerId: data.company?.id,
            ownerType: "company",
            state: null,
            modifyDate: new Date(),
            modifyUser: updateUserEmail,
            fieldKey: `company${capitalize(templateName)}TemplateVersion`,
            value: templateVersion,
          },
        },
      },
      data
    ),
};
