import * as types from "_graphql-types-frontend/graphql";
import classNames from "classnames";
import { useContext, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { TemplateContext } from "../Context";
import { isHTML } from "../Static/TextStatic";
import { Field } from "../types";
import { CopyField } from "./CopyField";
import { FieldStamp } from "./FieldAuditStamp";

type StateKey = "draft" | "mgr" | "published";

interface TabContent {
  stateKey: StateKey;
  field: Field;
}

const fieldState = {
  mgr: "Manager",
  draft: "Draft",
  published: "Published",
};

function renderTab(stateKey: StateKey) {
  return (
    <Tab key={stateKey} className="cms-tabs__tab main-tabs__tab">
      {fieldState[stateKey]}
    </Tab>
  );
}

function renderExpander(
  expanded: boolean,
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
  contentHeight: number
) {
  if (contentHeight < 70) return null;
  const iconClasses = classNames({
    "switch-btn__icon": true,
    icon: true,
    "icon--20": true,
    "icon-arrow-up": expanded,
    "icon-arrow": !expanded,
  });

  const text = expanded ? "Show Less" : "Show More";

  return (
    <button
      type="button"
      className="switch-btn"
      onClick={() => setExpanded(!expanded)}
    >
      {text}
      <i className={iconClasses} />
    </button>
  );
}

const sandwichNotPublished = {
  mgr: "No Manager's updated yet",
  published: "Nothing Published",
  draft: "Nothing edited",
};

function renderPanel(
  stateKey: StateKey = "published",
  field: Field,
  expanded: boolean,
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>,
  contentRef: React.RefObject<HTMLDivElement>
) {
  const tabName = fieldState[stateKey];
  const classes = classNames({
    "cms-tabs__txt": true,
    "cms-tabs__txt--expanded": expanded,
  });

  let tabContent: JSX.Element[];
  if (!field || !field.value) {
    tabContent = [
      <div className="text-center in-disabled-blue pt-8 pb-45" key={tabName}>
        <i className="icon icon-notes d-inline-block font-32 mb-8" />
        <p>{sandwichNotPublished[stateKey]}</p>
      </div>,
    ];
  } else {
    tabContent = [
      <div key={field?.fieldKey} className={classes}>
        <div ref={contentRef} className="rating-txt">
          {(field.notApplicable && <i>N/A</i>) || isHTML(field.value) ? (
            <ReactQuill theme="bubble" value={field.value} readOnly={true} />
          ) : (
            field.value
          )}
        </div>
      </div>,
      <div
        className="d-flex justify-between flex-wrap"
        key={`${stateKey}(field_info)`}
      >
        <div>
          {renderExpander(
            expanded,
            setExpanded,
            contentRef.current?.clientHeight ?? 0
          )}
          <div className="d-flex flex-wrap">
            <FieldStamp field={field} />
            <CopyField field={field} />
          </div>
        </div>
      </div>,
    ];
  }

  return <TabPanel key={stateKey}>{tabContent}</TabPanel>;
}

function TextAreaFieldStateSandwich({
  children,
  templateKey,
  childClassName,
}: {
  children: React.ReactNode;
  templateKey: string;
  childClassName?: string;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  const { readFieldFromCache } = useContext(TemplateContext);

  const { field } = readFieldFromCache(templateKey, null);
  const { field: managerData } = readFieldFromCache(
    templateKey,
    types.FieldState.Mgr
  );

  const [expanded, setExpanded] = useState(false);

  const tabs: TabContent[] = useMemo(
    () => [
      {
        stateKey: "published",
        field,
      },
      {
        stateKey: "mgr",
        field: managerData,
      },
    ],
    [managerData, field]
  );

  const tabContent = useMemo(
    () => (
      <div
        data-cy="field-state-sandwich"
        className="cms-info-status__bg  mb-16"
      >
        <Tabs
          selectedTabClassName="main-tabs__tab--active"
          defaultIndex={!field?.value && managerData?.value ? 1 : 0}
        >
          <TabList className="main-tabs">
            {tabs.map(({ stateKey }) => renderTab(stateKey))}
          </TabList>
          <div className="cms-tabs__content">
            {tabs.map(({ stateKey, field: fieldDatum }) =>
              renderPanel(
                stateKey,
                fieldDatum,
                expanded,
                setExpanded,
                contentRef
              )
            )}
          </div>
        </Tabs>
      </div>
    ),
    [tabs, expanded, setExpanded, contentRef]
  );

  return (
    <div>
      {tabContent}
      <div className={childClassName}>{children}</div>
    </div>
  );
}

export default TextAreaFieldStateSandwich;
