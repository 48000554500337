import classNames from "classnames";
import numeral from "numeral";
import { isNegativeValue } from "../../../utils/number";

export default function PercentStatic({
  value,
  notApplicable,
}: {
  value: number;
  notApplicable?: boolean | null;
}): JSX.Element {
  if (notApplicable) {
    return <b>N/A</b>;
  }
  if (value === null || value === undefined) {
    return (
      <span
        data-cy="percentStatic__null"
        className="cms-updates__value avoid-page-break-inside"
      >
        {" "}
        -{" "}
      </span>
    );
  }
  return (
    <span
      data-cy="percentStatic"
      className={classNames(
        "cms-updates__value invst-table-tag avoid-page-break-inside",
        {
          negative: isNegativeValue(value),
        }
      )}
    >
      {numeral(value).format("0.00%")}
    </span>
  );
}
