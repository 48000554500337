import { Affix } from "antd";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { TemplateContext } from "./Context";
import { isSectionEmpty } from "./Template.helpers";
import { SectionContentItem, TemplateContextInterface } from "./types";

const TemplateNav = ({ template }: { template: TemplateContextInterface }) => {
  const { readFieldFromCache, editMode } = useContext(TemplateContext);
  let { hash } = useLocation();

  const getSections = (template: TemplateContextInterface, editMode: boolean) =>
    template.content.filter(t => {
      if (t.type !== "section") return false;
      return !isSectionEmpty(t.content, readFieldFromCache) || editMode;
    });

  return (
    <Affix offsetTop={200}>
      <div className="anchor-bar">
        <ul className="anchor-bar__menu">
          {(getSections(template, editMode) as SectionContentItem[]).map(
            section => (
              <li className="anchor-bar__menu-item" key={section.display_name}>
                <Link
                  to={`#${section.display_name?.replace(/\s/g, "")}${
                    hash.slice(1) === section.display_name?.replace(/\s/g, "")
                      ? "_RETURN"
                      : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  {section.display_name}
                </Link>
              </li>
            )
          )}
        </ul>
      </div>
    </Affix>
  );
};

export default TemplateNav;
