/* eslint-disable jsx-a11y/control-has-associated-label */
import { GenericProp } from "_graphql-types-frontend/graphql";
import classnames from "classnames";
import { camelCase, get, kebabCase, lowerCase, startCase } from "lodash";
import { useCallback, useEffect, useState } from "react";
import InputFieldBase, { InputFieldProps } from "./InputFieldBase";

import { subYears } from "date-fns";
import uuid from "../../../../utils/uuid";
import { TableContext, useTableContext } from "../FieldTableContext";

function sortColsByDisplayOrder(genericProps: GenericProp[]): GenericProp[] {
  return genericProps
    .filter(
      (prop): prop is Required<GenericProp> => prop.displayOrder !== undefined
    )
    .sort((a, b) => Number(a.displayOrder) - Number(b.displayOrder));
}

function generateTableHeaders(rowProps: GenericProp) {
  return (
    <tr className="cms-striped-table__header field-default-edit__row">
      {sortColsByDisplayOrder(rowProps.props).map(prop => (
        <td key={`header__${prop.propertyKey}`} className={prop.propertyKey}>
          {get(prop, "displayName", startCase(prop.propertyKey))}
        </td>
      ))}
      <td />
    </tr>
  );
}

function getEmptyValue(prop: GenericProp) {
  if (prop.displayType === "Boolean") {
    return false;
  }
  if (prop.nullable) {
    return null;
  }
  return "";
}

function generateEmptyRow(genericProps: GenericProp[]) {
  return genericProps.reduce(
    (acc, curr) => ({ ...acc, [curr.propertyKey]: getEmptyValue(curr) }),
    {}
  );
}

function TableRow({
  genericProps,
  rowLookupKey,
  iRow,
  deleteRow,
  periodKey,
  periodValue,
  templateKey,
}: {
  genericProps: GenericProp;
  rowLookupKey: string;
  iRow: number;
  deleteRow: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
  periodKey?: string | null;
  periodValue?: string | null;
  templateKey: string;
}) {
  const periodCol = genericProps.props.filter(
    col => col.propertyKey === periodKey
  );
  const tableCols = sortColsByDisplayOrder(genericProps.props).filter(
    col => col.propertyKey !== periodKey
  );

  return (
    <tr data-testid={`table__row-${iRow}`}>
      {
        <td
          key={`table-row__${periodKey}::${iRow}`}
          data-testid={`table__cell-${iRow}-${periodKey}`}
          className={classnames(
            "field-table__cell-content",
            "field-default-edit__cell-content",
            periodKey,
            `cell-${lowerCase(periodCol[0].displayType ?? "")}`
          )}
        >
          {periodValue}
        </td>
      }
      {[...tableCols].map(gProp => (
        <td
          key={`table-row__${gProp.propertyKey}::${iRow}`}
          data-testid={`table__cell-${iRow}-${gProp.propertyKey}`}
          className={classnames(
            "field-table__cell-content",
            "field-default-edit__cell-content",
            gProp.propertyKey,
            `cell-${lowerCase(gProp.displayType ?? "")}`
          )}
        >
          <InputFieldBase
            templateKey={`${templateKey}.${rowLookupKey}.${gProp.propertyKey}`}
            formLookupKey={`${rowLookupKey}.${gProp.propertyKey}`}
            metadata={{
              displayName: "",
              displayType: gProp.displayType ?? gProp.propertyKey,
              options: gProp.options,
              value: { ...gProp, hideChangelog: true },
            }}
          />
        </td>
      ))}
      <td className="actions-cell">
        <div
          style={{ float: "right" }}
          data-testid={`remove-row-button-${iRow}`}
          onClick={event => deleteRow(event, iRow)}
        >
          <button
            type="button"
            className="cms-striped-table__icon icon-basket"
          />
        </div>
      </td>
    </tr>
  );
}

const getPeriods = (periodMeta?: string | null) => {
  if (periodMeta === "year") {
    return [
      subYears(new Date(), 1).getFullYear(),
      subYears(new Date(), 2).getFullYear(),
      subYears(new Date(), 3).getFullYear(),
    ];
  } else {
    throw Error(`Key ${periodMeta} not implemented in TimeSeriesTable`);
  }
};

function TimeSeriesTableInput({
  metadata,
  templateKey,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const tableContext = useTableContext({
    metadata,
    templateKey,
    formLookupKey,
  });

  const { fieldState, fieldUuids, deleteRow, rowMetadata, onChange } =
    tableContext;

  const [periods, changePeriods] = useState(getPeriods(metadata?.period));

  const periodKey = metadata.period;
  if (!periodKey) throw Error("No period metatdata");

  const addRow = useCallback(() => {
    const newPeriod =
      Number(periods.sort((a, b) => Number(a) - Number(b))[0]) - 1;

    const newRows = [
      ...fieldState,
      { ...generateEmptyRow(rowMetadata.props), [periodKey]: `${newPeriod}` },
    ];
    changePeriods([...periods, newPeriod]);
    const newUuids = [...fieldUuids, uuid()];

    /// set state fields
    onChange(newRows, newUuids);
  }, [fieldState, rowMetadata]);

  useEffect(() => {
    if (fieldState.length) return;
    onChange(
      periods.map(period => ({
        ...generateEmptyRow(rowMetadata.props),
        [periodKey]: `${period}`,
      })),
      periods.map(() => uuid())
    );
  }, [fieldState]);

  return (
    <NotApplicableWrapper templateKey={templateKey}>
      <TableContext.Provider value={tableContext}>
        <table
          id={templateKey}
          data-cy={`field-table__${camelCase(metadata.displayName ?? "")}`}
          className={classnames(
            "cms-striped-table",
            "field-default-edit",
            kebabCase(metadata.displayName ?? "")
          )}
        >
          <tbody>
            {generateTableHeaders(rowMetadata)}
            {fieldState &&
              fieldState.length > 0 &&
              fieldState.map((_row: any, iRow: number) => {
                return (
                  <TableRow
                    key={`tableRow__${rowMetadata.propertyKey}_${fieldUuids[iRow]}`}
                    iRow={iRow}
                    rowLookupKey={`${rowMetadata.propertyKey}[${iRow}]`}
                    periodKey={metadata.period}
                    periodValue={metadata.period && _row[metadata.period]}
                    deleteRow={deleteRow}
                    genericProps={rowMetadata}
                    templateKey={templateKey}
                  />
                );
              })}
          </tbody>
        </table>
        <button
          type="button"
          data-testid="add-row-button"
          onClick={addRow}
          className="cms-overview__add-btn"
        >
          <i className="cms-overview__add-btn-icon icon icon-plus" />
          <span className="cms-overview__add-btn-txt">Add New Row</span>
        </button>
      </TableContext.Provider>
    </NotApplicableWrapper>
  );
}

export default TimeSeriesTableInput;
