import { gql } from "_graphql-types-frontend";

export const ASYNC_DOCUMENT_PROCESS = gql(/* GraphQL */ `
  query asyncDocumentProcess(
    $documentId: Int!
    $processName: String!
    $sessionId: String!
    $requestId: String!
  ) {
    asyncDocumentProcess(
      documentId: $documentId
      processName: $processName
      sessionId: $sessionId
      requestId: $requestId
    )
  }
`);

export const DOCUMENT_PROCESS_MESSAGE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription DocumentProcessChatMessageSubscription($sessionId: String!) {
    chatMessages(sessionId: $sessionId) {
      sessionId
      requestId
      error
      messageStream {
        message
        messageIndex
        contentType
        blockIndex
        event
        id
      }
    }
  }
`);
