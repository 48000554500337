import { flowRight } from "lodash";

export const numberFromString = (string: string): number =>
  Number(string.replace(/[^\d.-]/g, ""));

export const isNegativeValue = (value: number | string) =>
  flowRight([
    (number: number | string) => Number(number) < 0,
    numberFromString,
    String,
  ])(value);
