/* eslint-disable react/jsx-props-no-spreading */
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { Input, Tooltip } from "antd";
import { isNaN } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import {
  getFormRulesFromMetadata,
  percentFormatter,
  percentParser,
} from "../utils";
import { InputFieldProps } from "./InputFieldBase";

import { useTemplateController } from "../FieldContext";

const doNothing = () => {};
const inputStyle = { width: "70px", borderRadius: "3px" };

function PercentFieldInput({
  templateKey,
  metadata,
  templateOverride,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const formRules = getFormRulesFromMetadata(metadata.value);
  const questionText = templateOverride?.questionText || metadata.questionText;

  const {
    field: { value, onChange, ...inputProps },
    fieldState: { error },
  } = useTemplateController({
    name: formLookupKey,
    ...(formRules && { rules: formRules }),
  });

  const [inputValue, setInputValue] = useState<string | undefined>(
    percentFormatter(value)
  );

  useEffect(() => {
    if (value !== percentParser(inputValue)) {
      setInputValue(percentFormatter(value));
    }
  }, [value]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    if (!isNaN(Number(event.target.value))) {
      onChange(percentParser(event.target.value));
    }
  }

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {!!questionText && (
        <div
          data-cy="template__textarea"
          data-test-id="template__textarea"
          className={"main-input__info"}
        >
          <div className="main-input__sublabel">{questionText}</div>
        </div>
      )}
      <NotApplicableWrapper {...{ templateKey, metadata }}>
        <div className="field-input-children-with-changelog">
          <FieldChangeLog
            templateKey={templateKey}
            shouldDisplayBadge={focus}
          />
          <Input
            placeholder="0.00"
            value={inputValue}
            className="percent-field__input"
            {...inputProps}
            size={"small"}
            onBlur={doNothing}
            onChange={handleChange}
            style={inputStyle}
            suffix="%"
            status={error && "error"}
            prefix={
              error ? (
                <Tooltip title={error.message}>
                  <ExclamationCircleOutlined />
                </Tooltip>
              ) : (
                <span />
              )
            }
          />
        </div>
      </NotApplicableWrapper>
    </div>
  );
}

export default PercentFieldInput;
