import classNames from "classnames";
import ContentItems from "../ContentItems.component";
import { ContentItem } from "../types";

export default function ColumnSection({ content }: { content: ContentItem[] }) {
  return (
    <div className={classNames("section-columnar__grid_column")}>
      <ContentItems content={content} />
    </div>
  );
}
