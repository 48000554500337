import { Select, Spin } from "antd";
import classNames from "classnames";
import { useContext, useState } from "react";
import { TemplateContext } from "./Context";

const { Option } = Select;

const formatVersionNumber = (v: string) =>
  `v${v.slice(1, 5)}-${v.slice(5, 7)}-${v.slice(7, 9)}`;

export default function VersionSelector() {
  const {
    changeTemplateVersion,
    updateTemplateVersion,
    availableVersions,
    templateVersion,
    defaultVersion,
    loading,
  } = useContext(TemplateContext);

  const [savingVersion, setSavingVersion] = useState<boolean>(false);

  return (
    <div className="cms-updates__counter" style={{ width: "300px" }}>
      <div className="main-input">
        <label htmlFor={"TemplateSelect"} className="main-input__label">
          Template Version
        </label>
        <Select
          value={templateVersion || defaultVersion}
          onChange={v => changeTemplateVersion(v)}
          style={{ width: "150px" }}
          optionLabelProp="Select Version"
          data-testid="TemplateSelect"
          placement="topRight"
          id="TemplateSelect"
        >
          {availableVersions.map(version => (
            <Option
              key={version}
              data-testid={`TemplateOption${version}`}
              value={version}
            >
              {formatVersionNumber(version)}
            </Option>
          ))}
        </Select>
      </div>
      <button
        onClick={() => {
          setSavingVersion(true);
          updateTemplateVersion(null).finally(() => setSavingVersion(false));
        }}
        className={classNames({
          "rounded-btn": true,
          "rounded-btn--blue": true,
        })}
        style={{
          marginLeft: "20px",
          fontSize: "12px",
          width: "150px",
          height: "10%",
          marginTop: "7%",
          ...(savingVersion && { backgroundColor: "#ffffff" }),
        }}
      >
        {!savingVersion ? "Publish Version" : <Spin />}
      </button>
    </div>
  );
}
