import { useMemo, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Select } from "antd";
import classNames from "classnames";
import { DefaultOptionType as OptionData } from "rc-select/lib/Select";
import { upperFirst, get } from "lodash";

import { useDebounce } from "use-debounce";
import { Firm, Investment } from "_graphql-types-frontend/graphql";
import { dynamicgql } from "../../../utils/graphql";
import { FieldOwnerType } from "../types";

const TYPEAHEAD_QUERY = (ownerType: FieldOwnerType) =>
  dynamicgql`query ${upperFirst(ownerType)}TypeAhead($filter: ${upperFirst(
    ownerType
  )}FilterInput) {
      ${ownerType}List(page: { limit: 15, offset: 0 }, filter: $filter, sort: [{ field: name, order: ASC }]) {
        items {
          id
          name
        }
        total
      }
    }`;

function FieldTypeAhead({
  className,
  dropdownClassName,
  onChange = () => {},
  onSelect = () => {},
  placeholder,
  dropdownStyle,
  dropdownMatchSelectWidth,
  defaultValue,
  getPopupContainer = (triggerNode: any) => triggerNode.parentNode,
  ownerType,
}: {
  className?: string;
  dropdownClassName?: string;
  onChange?: (...args: any) => void;
  onSelect?: (...args: any) => void;
  placeholder?: string;
  dropdownStyle?: React.CSSProperties;
  dropdownMatchSelectWidth: boolean;
  defaultValue?: string;
  getPopupContainer?: React.ComponentProps<typeof Select>["getPopupContainer"];
  ownerType: FieldOwnerType;
}): JSX.Element {
  const SEARCH_QUERY = useMemo(() => TYPEAHEAD_QUERY(ownerType), [ownerType]);

  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 250);
  const [search, { data, called, loading, refetch }] =
    useLazyQuery(SEARCH_QUERY);

  useEffect(() => {
    const variables = {
      filter: {
        q: debouncedQuery,
      },
    };
    if (called && refetch) {
      refetch(variables);
    } else {
      search({ variables });
    }
  }, [called, refetch, debouncedQuery]);

  function handleSearch(queryString: string) {
    onChange({ name: queryString });
    if (queryString.trim()) {
      setQuery(queryString);
    }
  }

  useEffect(() => {
    if (data && called) {
      const filteredOptions = get(data, [`${ownerType}List`, "items"], []).map(
        (item: Investment | Firm) =>
          item && { value: item.id, label: item.name }
      );
      setOptions(filteredOptions);
    }
  }, [data]);

  function handleSelect(_value: string, option: OptionData) {
    const { label: name, value: id } = option;
    setQuery("");
    onChange({ name, id });
    onSelect({ name, id });
  }

  function noResultsContent() {
    if (called && loading) {
      return <i className="fa fa-spinner fa-spin" />;
    }
    if (query.trim()) {
      return "No results found";
    }
    return null;
  }

  return (
    <>
      <Select
        showSearch
        filterOption={false}
        className={classNames("main-autocomplete", className)}
        dropdownClassName={classNames(
          "main-autocomplete__menu",
          dropdownClassName
        )}
        notFoundContent={noResultsContent()}
        options={options}
        onFocus={() => {
          handleSearch("");
        }}
        onSearch={handleSearch}
        onSelect={handleSelect}
        defaultValue={defaultValue}
        getPopupContainer={getPopupContainer}
        placeholder={placeholder}
        dropdownStyle={dropdownStyle as any}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      />
    </>
  );
}

export default FieldTypeAhead;
