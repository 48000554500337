import HighCharts from "highcharts";

export type HChartType =
  | "area"
  | "bar"
  | "bubble"
  | "column"
  | "pie"
  | "item"
  | "packedbubble"
  | "tilemap"
  | "heatmap";

export function rotatePieChartLabels(
  options: HighCharts.Options
): HighCharts.Options {
  let allY: number;
  let angle1: number;
  let angle2: number;
  let angle3: number;

  const series = (options.series as HighCharts.SeriesPieOptions[])?.map(
    point => {
      angle1 = 0;
      angle2 = 0;
      angle3 = 0;
      allY = 0;

      const data: HighCharts.PointOptionsObject[] = (
        point.data as HighCharts.PointOptionsObject[]
      )?.map(dataPoint => {
        const dataLabels =
          dataPoint.dataLabels as HighCharts.SeriesPieDataLabelsOptionsObject;
        if (!dataLabels || !dataPoint.y) return dataPoint;

        angle2 = angle1 + (dataPoint.y * 360) / allY;
        angle3 = angle2 - (dataPoint.y * 360) / (2 * allY);
        dataLabels.rotation = -90 + angle3;
        angle1 = angle2;

        return {
          ...dataPoint,
          dataLabels,
        };
      });

      return {
        ...point,
        data,
      };
    }
  );

  return {
    ...options,
    series,
  };
}
