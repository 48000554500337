/* eslint-disable @typescript-eslint/no-empty-function */

import { useContext, createContext } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";
import { TableContext, useTableController } from "./FieldTableContext";

export const ParentContext = createContext<string>("");

type UseTemplateControllerReturn =
  | UseControllerReturn<
      FieldValues,
      string | `${string}.${string}` | `${string}.${number}`
    >
  | {
      field: {
        onChange: (value: any) => void;
        value: any;
        modifyDate: any;
        notApplicable: boolean;
        name: string;
        onBlur: () => void;
      };
      formState: UseControllerReturn["formState"];
      fieldState: UseControllerReturn["fieldState"];
    };
/**
 * Detects Context (table or form)
 * Provides controller API
 *
 */
export function useTemplateController(
  controllerProps: UseControllerProps
): UseTemplateControllerReturn {
  const { isActive: isInTable } = useContext(TableContext);

  const controller = !isInTable;

  if (controller) {
    return useController(controllerProps);
  }

  if (isInTable) return useTableController(controllerProps);
  throw new Error("No field template controller, that's unexpected?!");
}
