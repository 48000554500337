/* eslint-disable react/jsx-props-no-spreading */
import { Select } from "antd";
import { useCallback, useState } from "react";
import { useController } from "react-hook-form";
import Conviction from "../../Static/ConvictionScoreStatic";
import FieldChangeLog from "../FieldChangeLog";
import { InputFieldProps } from "./InputFieldBase";

export const availableScores = [
  { score: 1, label: "Low" },
  { score: 2, label: "Medium" },
  { score: 3, label: "High" },
];

function ConvictionScoreInput({
  templateKey,
  metadata,
  formLookupKey,
}: InputFieldProps): JSX.Element {
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({ name: formLookupKey });

  function handleChange(val: number) {
    if (!val) {
      return onChange(undefined);
    }
    return onChange(val);
  }

  const [focus, setFocus] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div
      style={{ marginTop: 5 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="d-flex">
        <div className="field-input-children-with-changelog">
          <FieldChangeLog
            templateKey={templateKey}
            shouldDisplayBadge={focus}
          />
          <Conviction value={value} />
        </div>
        <Select
          ref={ref}
          optionLabelProp="title"
          className="cms-conviction__select main-select"
          dropdownClassName="cms-conviction__select-menu main-select__menu"
          data-testid="conviction-score__input"
          onChange={handleChange}
          {...inputProps}
        >
          <Select.Option
            className="main-select__menu-item-def"
            disabled={!metadata.value.nullable}
            value=""
            title="Select"
          >
            Select
          </Select.Option>
          {availableScores.map(({ label, score }) => (
            <Select.Option key={score} title={label} value={score}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default ConvictionScoreInput;
