import { useApolloClient } from "@apollo/client";
import { CurrentUserQuery, FieldState } from "_graphql-types-frontend/graphql";
import { CURRENT_USER_QUERY } from "./graphql";

export function useCurrentUser():
  | CurrentUserQuery["currentUser"] & {
      writeDraftState: FieldState.Draft | FieldState.MgrDraft;
      writePublishState: null | FieldState.Mgr;
      clientView: boolean;
    } {
  const client = useApolloClient();

  const { currentUser } = client.readQuery({ query: CURRENT_USER_QUERY }) || {};

  if (!currentUser) throw new Error("no current user");
  const clientView =
    sessionStorage.getItem("clientView") === "true" &&
    currentUser.flags.isRockCreek;

  return {
    writeDraftState: currentUser.flags.rcgDataEntry
      ? FieldState.Draft
      : FieldState.MgrDraft,
    writePublishState: currentUser.flags.rcgDataEntry ? null : FieldState.Mgr,
    clientView: currentUser.flags.isClientUser || clientView,
    ...currentUser,
    ...(clientView && {
      flags: { ...currentUser.flags, isRockCreek: false, isClientUser: true },
    }),
  };
}
