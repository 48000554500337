/* eslint-disable max-lines-per-function */
import HighCharts from "highcharts";
import { rotatePieChartLabels } from "./helpers";
import { getUniversalChartOptions } from "./universalOptions";
import { PieChartSeries } from "./dataSeriesFormatters";

export interface IChartGeneratorPie {
  dataSeries: PieChartSeries[];
  height?: number;
  width?: number;
  fontSize: number;
  allowExport: boolean;
  title?: string;
  subTitle?: string;
  formatSuffix?: string;
  showAnimation?: boolean;
  allowDecimals?: boolean;
  chartColors?: string[];
  innerSize?: number | string;
  diameter?: number;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  legendAlign?: HighCharts.AlignValue;
  legendVerticalAlign?: HighCharts.VerticalAlignValue;
  legendMaxHeight?: number;
}

/**
 * generates chart options object for pie charts
 * @param dataSeries chart data formatted according to highChartType
 * @param height optional chart height
 * @param width optional chart width
 * @param fontSize determines size of chart labels/tickets/legend
 * @param allowExport true for usage in reports, false for rendering in browser
 * @param title optional chart title
 * @param subTitle optional chart subTitle
 * @param formatSuffix appended to chart values (eg. %)
 * @param showAnimation defaults to false (can be true if only rendering in browser - not in reports)
 * @param chartColors (optional) array of hex codes
 * @param innerSize (optional) number higher than zero will create donut chart (number or percent string)
 * @param diameter (optional) set size of pie diameter
 * @param legendSymbolHeight (optional) height of the circle symbol in legend (defaults to fontsize)
 * @param legendItemMarginTop (optional) margin between items in legend
 * * @param legendAlign (optional) legend alignment on horizontal axis
 * @param legendVerticalAlign (optional) legend alignment on vertical axis
 * @param legendMaxHeight (optional) max height of legend
 * @returns HighCharts.Options object
 */
export function getPieChartOptions({
  dataSeries,
  height,
  width,
  fontSize,
  allowExport,
  title,
  subTitle,
  formatSuffix,
  showAnimation = false,
  chartColors,
  allowDecimals,
  innerSize,
  diameter,
  legendSymbolHeight,
  legendItemMarginTop,
  legendAlign = "center",
  legendVerticalAlign = "bottom",
  legendMaxHeight,
}: IChartGeneratorPie): HighCharts.Options {
  const universalOptions = getUniversalChartOptions({
    title,
    subTitle,
    dataSeries: dataSeries as HighCharts.SeriesPieOptions[],
    allowExport,
    chartColors,
    fontSize,
  });

  const options: HighCharts.Options = {
    ...universalOptions,
    chart: {
      type: "pie",
      plotShadow: false,
      style: {
        fontFamily: "Helvetica",
        fontSize: `${fontSize}px`,
      },
      width,
      height,
    },
    xAxis: {
      allowDecimals,
    },
    yAxis: {
      allowDecimals,
    },
    tooltip: {
      pointFormat: formatSuffix
        ? `<b>{point.y}${formatSuffix}</b>`
        : `<b>{point.y}</b>`,
    },
    plotOptions: {
      series: {
        animation: showAnimation,
      },
      pie: {
        dataLabels: {
          enabled: true,
          crop: false,
          distance: -50,
          style: {
            fontSize: `${fontSize}px`,
          },
          formatter() {
            if (this.y && this.y > 0) {
              return `${this.y}${formatSuffix}`;
            }
            return "";
          },
        },
        showInLegend: true,
        innerSize,
        ...(diameter && { size: diameter }),
      },
    },
    legend: {
      align: legendAlign,
      verticalAlign: legendVerticalAlign,
      itemMarginTop: legendItemMarginTop ?? 0,
      symbolHeight: legendSymbolHeight ?? fontSize,
      itemStyle: {
        fontSize: `${fontSize}px`,
      },
      maxHeight: legendMaxHeight,
    },
  };

  return rotatePieChartLabels(options);
}
