/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import { useCallback, useContext, useState } from "react";
import FieldChangeLog from "../FieldChangeLog";
import { ParentContext, useTemplateController } from "../FieldContext";
import { InputFieldProps } from "./InputFieldBase";

function displayLabel(
  parent: string,
  displayName: string | null | undefined,
  questionText: string | null | undefined
) {
  if (parent !== "checklist") {
    return questionText;
  }
  return displayName;
}

export default function BooleanFieldInput({
  templateKey,
  metadata,
  templateOverride,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const displayName = templateOverride?.displayName || metadata.displayName;
  const questionText = templateOverride?.questionText || metadata.questionText;

  const {
    field: { value, onChange, ...inputProps },
  } = useTemplateController({ name: formLookupKey, defaultValue: false });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      onChange(checked);
    },
    [onChange]
  );

  const parent = useContext(ParentContext);

  const [focus, setFocus] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setFocus(true);
  }, []);
  const handleMouseLeave = useCallback(() => {
    setFocus(false);
  }, []);

  return (
    <div
      className="fields-input__boolean"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="field-input-children-with-changelog">
        <FieldChangeLog templateKey={templateKey} shouldDisplayBadge={focus} />
        <NotApplicableWrapper {...{ templateKey, metadata }}>
          <div className={classNames("main-checkbox")}>
            {/* {displayHeader(parent, metadata)} */}
            <input
              type="checkbox"
              className={classNames("main-checkbox__input", {
                "main-checkbox__input--only-checkbox": displayName === "",
              })}
              id={templateKey}
              {...inputProps}
              onChange={handleChange}
              defaultChecked={!!value as boolean}
            />

            <label className="main-checkbox__label" htmlFor={templateKey}>
              {displayLabel(parent, displayName, questionText)}
            </label>
          </div>
        </NotApplicableWrapper>
      </div>
    </div>
  );
}
