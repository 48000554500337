import { Button } from "antd";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { TemplateContext } from "../Context";
import { isSectionEmpty } from "../Template.helpers";
import { ContentItemDecorator, SectionContentItem } from "../types";
import CardSection from "./CardSection";
import CheckListSection from "./CheckListSection";
import ColumnarSection from "./ColumnarSection";
import ColumnSection from "./ColumnSection";
import ConditionalDescriptionSection from "./ConditionalDescriptionSection";
import ExpandableSection from "./ExpandableSection";
import ListTableSection from "./ListTableSection";
import PieSection from "./PieSection";
import SubSection from "./SubSection";

function SectionsComponent({
  section,
  editMode,
  footer,
}: {
  section: SectionContentItem;
  editMode: boolean;
  footer?: React.FunctionComponent<{}>;
}) {
  switch (section.display_type) {
    case "card":
      return (
        <CardSection
          content={section.content}
          Footer={footer}
          displayName={section.display_name}
        />
      );
    case "columnar":
      return <ColumnarSection content={section.content} />;
    case "column":
      return <ColumnSection content={section.content} />;
    case "expandable_section":
      return (
        <ExpandableSection
          content={section.content}
          expanded={section.expanded || editMode}
          collapsed_text={section.collapsed_text}
          expanded_text={section.expanded_text}
        />
      );
    case "conditional_description":
      return <ConditionalDescriptionSection content={section.content} />;
    case "list-table":
      return (
        <ListTableSection
          displayName={section.display_name}
          content={section.content}
          allowSectionNotApplicable={section.allowSectionNotApplicable}
        />
      );
    case "checklist":
      return (
        <CheckListSection
          content={section.content}
          allowSectionNotApplicable={section.allowSectionNotApplicable}
        />
      );
    case "pie":
      return (
        <PieSection
          content={section.content}
          allowSectionNotApplicable={section.allowSectionNotApplicable}
        />
      );
    case "sub_section":
      return (
        <SubSection
          content={section.content}
          display_name={section.display_name}
        />
      );
    default:
      console.warn("UNSUPPORTED Section ", section);
      return null;
  }
}

export default function SectionBaseComponent({
  section,
  Component,
}: {
  section: SectionContentItem;
  Component?: ContentItemDecorator;
}) {
  const { investmentId } = useParams() as any;
  const { readFieldFromCache, editMode } = useContext(TemplateContext);

  const isEmpty = isSectionEmpty(section.content, readFieldFromCache);

  if (isEmpty && !editMode) return null;

  const EditLink =
    section.editPath && editMode
      ? () => (
          <Button
            onClick={() =>
              window.open(
                `https://data-collection.rcgproduct.com/investment/${investmentId}${section.editPath}`
              )
            }
          >
            Edit in Data Collection
          </Button>
        )
      : undefined;

  if (Component) {
    return (
      <Component displayName={section.display_name} contentItemProps={section}>
        <SectionsComponent
          section={section}
          editMode={editMode}
          footer={EditLink}
        />
      </Component>
    );
  }

  return (
    <SectionsComponent
      section={section}
      editMode={editMode}
      footer={EditLink}
    />
  );
}
