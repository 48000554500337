export const BUSINESS_OBJECT_ENUM = {
  investment: 1,
  firm: 2,
  company: 3,
  deal: 4,
};

export const CALENDAR_UNIT_ENUM = {
  month: 3,
};

export const DOCUMENT_TYPE_ENUM = {
  supplementalDiligenceVisuals: 96,
  logo: 71,
};

export const SUPPLEMENTAL_DILIGENCE_VISUALS = "Supplemental Diligence Visuals";

export const DOCUMENT_ACCESS_LEVEL_ENUM = {
  PUBLIC: 1,
  ORGANIZATION: 2,
  PRIVATE: 3,
  CLIENTS: 4,
};

export const NO_WHITESPACE_REGEX = /^[\s]{0}([^\s]+.*[^\s]+|[^\s]{1})[\s]{0}$/;

// case insensitive UUID (non-null)
export const UUID_REGEX =
  /^[a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$/i;

// https://uibakery.io/regex-library/url
export const VALID_WEBSITE_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/i;
