import HighCharts from "highcharts";

export const DEFAULT_CHART_COLORS = [
  "#92d4e3",
  "#5597ff",
  "#d59ed4",
  "#774be5",
  "#0777ad",
  "#f76e4c",
  "#ffb14e",
  "#23cda0",
  "#00bb60",
  "#a5e3b0",
];

export const CHART_COLORS_SERIES_2 = [
  "#16adb0",
  "#fa8775",
  "#a0f2cf",
  "#f1f1f1",
  "#ffbd86",
];

interface IUniversalChartOptions {
  dataSeries: HighCharts.SeriesOptionsType[];
  allowExport: boolean;
  chartColors?: string[];
  title?: string;
  subTitle?: string;
  fontSize: number;
}

export function getUniversalChartOptions({
  title,
  subTitle,
  dataSeries,
  allowExport,
  chartColors = DEFAULT_CHART_COLORS,
  fontSize,
}: IUniversalChartOptions): HighCharts.Options {
  return {
    colors: chartColors,
    title: {
      text: title || undefined,
    },
    subtitle: {
      text: subTitle,
      style: {
        fontSize: `${fontSize + 2}px`,
      },
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: `${fontSize}px`,
        color: "#303030",
      },
    },
    lang: {
      noData: "Data not provided",
    },
    credits: {
      enabled: false,
    },
    series: dataSeries,
    exporting: {
      enabled: allowExport,
      fallbackToExportServer: false,
    },
  };
}
