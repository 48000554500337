import { ContentItemDecorator, Metadata, PartialDocument } from "../types";
import AumTableStatic from "./AumTableStatic";
import BooleanStatic from "./BooleanStatic";
import ConvictionScoreStatic from "./ConvictionScoreStatic";
import DateStatic from "./DateStatic";
import ListTableStatic from "./ListTableStatic";
import PageHeadingStatic from "./PageHeadingStatic";
import PercentStatic from "./PercentStatic";
import RatingStatic from "./RatingStatic";
import SectionHeadingStatic from "./SectionHeadingStatic";
import TableStatic from "./TableStatic";
import TextStatic, {
  currencyFormat,
  decimalFormat,
  numberFormat,
  textOrRichText,
} from "./TextStatic";

export function StaticComponents({
  displayType,
  value,
  metadata,
  notApplicable,
}: {
  value: any;
  metadata: Metadata;
  displayType: string;
  notApplicable?: boolean | null;
}): JSX.Element | null {
  switch (displayType) {
    case "conviction_score":
      return <ConvictionScoreStatic value={value} />;
    case "boolean_dropdown_na":
    case "boolean_dropdown":
    case "boolean":
      return (
        <BooleanStatic
          value={value}
          metadata={metadata}
          notApplicable={!!notApplicable}
        />
      );

    case "text":
    case "select":
    case "year":
      return <TextStatic value={value} notApplicable={notApplicable} />;
    case "textarea":
      return (
        <TextStatic
          value={value}
          formatter={textOrRichText}
          notApplicable={notApplicable}
        />
      );
    case "integer":
      return (
        <TextStatic
          value={value}
          formatter={numberFormat}
          notApplicable={notApplicable}
        />
      );
    case "decimal":
      return (
        <TextStatic
          value={value}
          formatter={decimalFormat}
          notApplicable={notApplicable}
        />
      );
    case "currency":
      return (
        <TextStatic
          value={value}
          formatter={currencyFormat}
          notApplicable={notApplicable}
        />
      );
    case "date-monthly":
      return (
        <DateStatic
          value={value}
          dateFormat="MMM-yyyy"
          notApplicable={notApplicable}
        />
      );
    case "date-daily":
      return (
        <DateStatic
          value={value}
          dateFormat="dd-MMM-yyyy"
          notApplicable={notApplicable}
        />
      );
    case "date-yearly":
      return (
        <DateStatic
          value={value}
          dateFormat="yyyy"
          notApplicable={notApplicable}
        />
      );
    case "rating":
      return <RatingStatic value={value} />;
    case "percent":
      return <PercentStatic value={value} notApplicable={notApplicable} />;
    case "list-table":
      return (
        <ListTableStatic
          value={value}
          metadata={metadata}
          notApplicable={notApplicable}
        />
      );
    case "table":
    case "time-series-table":
      return (
        <TableStatic
          value={value}
          metadata={metadata}
          notApplicable={notApplicable}
        />
      );
    case "page_heading":
      return <PageHeadingStatic value={value} />;
    case "section_heading":
      return <SectionHeadingStatic value={value} />;
    case "aum_table":
      return <AumTableStatic value={value} metadata={metadata} />;
    default:
      console.warn("UNSUPPORTED Static Type ", displayType, value, metadata);
      return null;
  }
}

export function staticIsNotRenderable({
  value,
  displayType,
  notApplicable,
  documents,
}: {
  value: any;
  displayType: string;
  notApplicable?: boolean | null;
  documents?: PartialDocument[];
}): boolean {
  return (
    ((value === null && !notApplicable && !documents?.length) ||
      value === undefined ||
      value === "" ||
      (Array.isArray(value) && !value.length)) &&
    displayType !== "aum_table"
  ); // exception as it has no value currently
}

function StaticBaseComponent({
  displayType,
  value,
  notApplicable,
  metadata,
  Component,
  documents,
}: {
  value: any;
  metadata: Metadata;
  notApplicable?: boolean | null;
  displayType: string;
  Component?: ContentItemDecorator;
  documents?: PartialDocument[];
}): JSX.Element | null {
  if (staticIsNotRenderable({ value, displayType, notApplicable, documents }))
    return null;

  if (Component) {
    return (
      <Component
        displayName={metadata?.displayName ?? ""}
        contentItemProps={{
          type: "static",
          displayType,
          value,
          metadata,
        }}
      >
        <StaticComponents
          displayType={displayType}
          value={value}
          notApplicable={notApplicable}
          metadata={metadata}
        />
      </Component>
    );
  }

  return (
    <StaticComponents
      displayType={displayType}
      value={value}
      notApplicable={notApplicable}
      metadata={metadata}
    />
  );
}

StaticBaseComponent.defaultProps = {
  Component: undefined,
};

export default StaticBaseComponent;
