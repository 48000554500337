import ReactDOM from "react-dom";
import createClass from "create-react-class";
import { isUndefined, includes } from "lodash";

const Portal = createClass({
  getInitialState() {
    const div = document.createElement("div");
    return {
      node: div,
    };
  },

  target() {
    if (isUndefined(this.props.target)) {
      return document.body;
    }
    return document.getElementById(this.props.target);
  },

  componentDidMount() {
    if (this.props.target && this.target() && this.target().hasChildNodes()) {
      const currentNode = this.target().lastChild;
      if (currentNode !== null) {
        this.target().removeChild(currentNode);
        this.setState({ fallbackNode: document.createElement("div") });
      }
    }

    if (this.target() === null)
      throw new Error(`Undefined target for Portal: ${this.props.target}`);
    this.target().appendChild(this.state.node);
  },

  componentWillUnmount() {
    if (includes(this.target().childNodes, this.state.node))
      this.target().removeChild(this.state.node);

    if (this.state.fallbackNode) {
      this.target().appendChild(this.state.fallbackNode);
    }
  },

  render() {
    return ReactDOM.createPortal(this.props.children, this.state.node);
  },
});

export default Portal;
