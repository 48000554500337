const VideoViewer = ({ url }: { url?: string }): JSX.Element => (
  <div>
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <video autoPlay controls style={{ width: "100%" }}>
      <source src={url} type="video/mp4" />
    </video>
  </div>
);

export default VideoViewer;
