import { GenericProp } from "_graphql-types-frontend/graphql";
import { cloneDeep, get, set } from "lodash";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";
import uuid from "../../../utils/uuid";
import { InputFieldProps } from "./Inputs/InputFieldBase";
import { getFormRulesFromMetadata } from "./utils";

type FieldTableContextProps = {
  tableController: UseControllerReturn<FieldValues, string>;
  fieldState: any[];
  fieldUuids: string[];
  addRow: () => void;
  changeTableField: (name: string) => (value: any) => void;
  onChange: (fieldState: any[], fieldUuids: string[]) => void;
  deleteRow: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    irow: number
  ) => void;
  rowMetadata: GenericProp;
  isActive: boolean;
};

const defaultReturn: FieldTableContextProps = {
  tableController: {
    field: {
      onChange: () => {},
      name: "nothing.value",
      value: null,
      onBlur: () => {},
      ref: () => {},
    },
    formState: {
      isDirty: false,
      dirtyFields: [],
      isSubmitted: false,
      submitCount: 0,
      touchedFields: [],
      isValidating: false,
      isValid: true,
      errors: {},
      isSubmitSuccessful: false,
      isSubmitting: false,
      isLoading: false,
      disabled: false,
      validatingFields: {},
    },
    fieldState: {
      invalid: false,
      isTouched: false,
      isDirty: false,
      isValidating: false,
    },
  },
  fieldState: [],
  fieldUuids: [],
  onChange: () => {},
  changeTableField: () => () => {},
  addRow: () => {},
  deleteRow: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    irow: number
  ) => {},
  rowMetadata: {
    arrayDepth: 0,
    isScalar: false,
    nullable: false,
    props: [],
    propertyKey: "",
    returnTypeName: "",
  },
  isActive: false,
};

export const TableContext =
  createContext<FieldTableContextProps>(defaultReturn);

function getEmptyValue(prop: GenericProp) {
  if (prop.displayType === "Boolean") {
    return false;
  }
  if (prop.nullable) {
    return null;
  }
  return "";
}

function generateEmptyRow(genericProps: GenericProp[]) {
  return genericProps.reduce(
    (acc, curr) => ({ ...acc, [curr.propertyKey]: getEmptyValue(curr) }),
    {}
  );
}
const onBlur = () => {};
const ref = () => {};

export const useTableController = (
  controllerProps: UseControllerProps
): UseControllerReturn => {
  const { rowMetadata, fieldState, tableController, changeTableField } =
    useContext(TableContext);
  const fieldName = `${tableController.field.name}.${controllerProps.name}`;
  //register validations
  const controller = useController({
    name: fieldName,
    ...(controllerProps.rules ? { rules: controllerProps.rules } : {}),
  });

  const currentValue = useMemo(
    () => ({ [rowMetadata.propertyKey]: fieldState }),
    [fieldState, rowMetadata.propertyKey]
  );

  const value = get(currentValue, controllerProps.name, undefined);

  const onChange = useMemo(() => {
    return changeTableField(controllerProps.name);
  }, [changeTableField]);

  return {
    ...tableController,
    fieldState: {
      ...tableController.fieldState,
      error: controller.fieldState.error,
    },
    field: {
      onBlur,
      ref,
      onChange,
      value: value,
      name: fieldName,
    },
  };
};

/**
 * Builds context for TableContext
 */
export function useTableContext({
  templateKey,
  metadata,
}: Omit<
  InputFieldProps,
  "NotApplicableWrapper" | "templateOverride"
>): FieldTableContextProps {
  const rowMetadata = metadata.value.props[0];
  const formRules = getFormRulesFromMetadata(metadata.value);

  const tableController = useController({
    name: `${templateKey}.value`,
    ...(formRules && { rules: formRules }),
  });

  const {
    field: { value, onChange: onTemplateChange },
  } = tableController;

  /// state
  const fields: any[] = useMemo(() => {
    return get(value, rowMetadata.propertyKey, []);
  }, [value]);

  const [fieldState, setFieldState] = useState(fields);
  const [fieldUuids, setFieldUuids] = useState<string[]>(
    fields.map(() => uuid())
  );

  //https://github.com/facebook/react/issues/16956
  const stateRef = useRef({
    fieldState,
    fieldUuids,
  });

  const onChange = (fieldState: any[], fieldUuids: string[]) => {
    setFieldState(fieldState);
    setFieldUuids(fieldUuids);
    onTemplateChange({ [rowMetadata.propertyKey]: fieldState });
  };

  const addRow = () => {
    const newState = [
      ...stateRef.current.fieldState,
      generateEmptyRow(rowMetadata.props),
    ];
    const newUuids = [...stateRef.current.fieldUuids, uuid()];

    /// set state fields
    onChange(newState, newUuids);
  };

  const deleteRow = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    irow: number
  ) => {
    event.preventDefault();

    const newState = [
      ...stateRef.current.fieldState.slice(0, irow),
      ...stateRef.current.fieldState.slice(irow + 1),
    ];
    const newUuids = [
      ...stateRef.current.fieldUuids.slice(0, irow),
      ...stateRef.current.fieldUuids.slice(irow + 1),
    ];

    onChange(newState, newUuids);
  };

  const changeTableField = (fieldName: string) => (value: any) => {
    const newValue = {
      [rowMetadata.propertyKey]: cloneDeep(stateRef.current.fieldState),
    };
    set(newValue, fieldName, value);
    onChange(newValue[rowMetadata.propertyKey], stateRef.current.fieldUuids);
  };

  //https://github.com/facebook/react/issues/16956
  useEffect(() => {
    stateRef.current = {
      fieldState,
      fieldUuids,
    };
  }, [fieldState, fieldUuids]);

  return {
    tableController,
    changeTableField,
    onChange,
    fieldState,
    fieldUuids,
    addRow,
    deleteRow,
    rowMetadata,
    isActive: true,
  };
}
