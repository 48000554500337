import { FieldOwnerType, FieldSetType } from "_graphql-types-frontend/graphql";
import _ from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import RatingIcon from "../RatingIcon";
import { useTemplate } from "../Template/Context";
import {
  ContentItem,
  Field,
  FieldContentItem,
  Metadata,
  TemplateContextInterface,
} from "../Template/types";

type Section = {
  rating?: number;
  name: string;
};

function findRatingKey(content: ContentItem[]): string | undefined {
  let key: string | undefined;
  content.find(contentItem => {
    if (contentItem.type === "field") {
      if (contentItem.display_name === "Rating") {
        key = contentItem.key;
      }
    } else if (contentItem.type === "section") {
      key = findRatingKey(contentItem.content);
    }
    return key;
  }) as FieldContentItem | undefined;

  return key;
}

function getSectionRating(
  content: ContentItem[],
  readFieldFromCache: (key: string) => {
    field: Field;
    metadata: Metadata;
  }
) {
  const ratingKey = findRatingKey(content);

  if (ratingKey) {
    const { field } = readFieldFromCache(ratingKey);
    return field && field.value;
  }

  return undefined;
}

function findSectionsAndRatings(
  content: ContentItem[],
  readFieldFromCache: (key: string) => {
    field: Field;
    metadata: Metadata;
  }
): Section[] {
  return content.reduce((accu: Section[], contentItem) => {
    if (contentItem.type === "section" && contentItem.display_name) {
      accu.push({
        rating: getSectionRating(contentItem.content, readFieldFromCache),
        name: contentItem.display_name,
      });
    }

    return accu;
  }, []);
}

function TemplateRatings({
  investmentId,
  template,
  showRatings,
}: {
  investmentId: number;
  template: TemplateContextInterface;
  showRatings: boolean;
}) {
  const sections = useMemo(
    () => findSectionsAndRatings(template.content, template.readFieldFromCache),
    [template]
  );

  return (
    <div
      className="ratings-list list-container"
      data-cy="ratings-list-container"
    >
      <h3 className="invt-tab__title" style={{ textTransform: "capitalize" }}>
        {template.name}
      </h3>

      <div className="list-body">
        {sections.map(({ rating, name }) => (
          <Link
            key={name}
            to={`/investments/${investmentId}/${template.name}#${_.snakeCase(
              name
            )}`}
            className="list-item"
            data-tab-name={name}
          >
            {showRatings && (
              <RatingIcon
                ratingValue={
                  rating as React.ComponentProps<
                    typeof RatingIcon
                  >["ratingValue"]
                }
              />
            )}

            <div className="list-item-text">{name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default function InvestmentRatingsNav({
  investmentId,
  showRatings = true,
}: {
  investmentId: number;
  showRatings?: boolean;
}) {
  const iddTemplate = useTemplate({
    ownerId: investmentId,
    ownerType: FieldOwnerType.Investment,
    editMode: false,
    name: FieldSetType.Investment,
  });

  const oddTemplate = useTemplate({
    ownerId: investmentId,
    ownerType: FieldOwnerType.Investment,
    editMode: false,
    name: FieldSetType.Operations,
  });

  const impactTemplate = useTemplate({
    ownerId: investmentId,
    ownerType: FieldOwnerType.Investment,
    editMode: false,
    name: FieldSetType.Impact,
  });

  return (
    <>
      <TemplateRatings
        template={iddTemplate}
        showRatings={showRatings}
        investmentId={investmentId}
      />

      {iddTemplate.error && JSON.stringify(iddTemplate.error)}

      <TemplateRatings
        template={oddTemplate}
        showRatings={showRatings}
        investmentId={investmentId}
      />

      {oddTemplate.error && JSON.stringify(oddTemplate.error)}

      <TemplateRatings
        template={impactTemplate}
        showRatings={showRatings}
        investmentId={investmentId}
      />

      {impactTemplate.error && JSON.stringify(impactTemplate.error)}
    </>
  );
}
