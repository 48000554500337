import classname from "classnames";
import { useContext, useMemo } from "react";
import uuid from "../../../utils/uuid";
import ContentItems from "../ContentItems.component";
import { TemplateContext } from "../Context";
import { ParentContext, useTemplateController } from "../Field/FieldContext";
import { getSectionFieldKeys } from "../Template.helpers";
import { ContentItem, ContentItemDecorator } from "../types";

export const CheckMark = ({ label }: { label?: string }): JSX.Element => (
  <div>
    <i className="crutch-checkbox-list__item-icon icon icon--24 icon-success" />
    {label}
  </div>
);

const ConditionalDescriptionWrapper: ContentItemDecorator = ({
  displayName,
  contentItemProps,
  children,
}) => {
  if (contentItemProps.type === "section") {
    return (
      <div
        className={classname(
          "crutch-checkbox-list__item--other-wrap",
          "flex-column"
        )}
      >
        <ContentItems
          content={contentItemProps.content}
          Component={ConditionalDescriptionWrapper}
        />
      </div>
    );
  }

  if (contentItemProps.type === "static") {
    if (contentItemProps.metadata.displayType === "boolean") {
      // null valued checkmark fields will be hidden because of StaticBaseComponent hides null value fields.
      return (
        <div
          className={`crutch-checkbox-list__item--other ${
            !contentItemProps.value ? "crutch-checkbox-list__item--dis" : ""
          }`}
        >
          <CheckMark label={displayName} />
        </div>
      );
    }
    return (
      <div className="crutch-checkbox-list__item crutch-checkbox-list__item-other-desc">
        {children}
      </div>
    );
  }
  return (
    <div
      className={`crutch-checkbox-list__item ${
        contentItemProps.metadata.displayType === "boolean"
          ? "crutch-checkbox-list__item--other"
          : "crutch-checkbox-list__item-other-desc"
      }`}
    >
      {children}
    </div>
  );
};

const CheckListContentItem: ContentItemDecorator = ({
  displayName,
  contentItemProps,
  children,
}) => {
  if (contentItemProps.type === "section") {
    return (
      <div
        className={classname(
          "crutch-checkbox-list__item--other-wrap",
          "flex-column"
        )}
      >
        <ContentItems
          content={contentItemProps.content}
          Component={ConditionalDescriptionWrapper}
        />
      </div>
    );
  }

  if (
    contentItemProps.metadata.displayType === "boolean" &&
    contentItemProps.type === "static"
  ) {
    // null valued checkmark fields will be hidden because of StaticBaseComponent hides null value fields.
    return (
      <div
        className={`crutch-checkbox-list__item--other ${
          !contentItemProps.value ? "crutch-checkbox-list__item--dis" : ""
        }`}
      >
        {!children.props.notApplicable ? (
          <CheckMark label={displayName} />
        ) : (
          <b>N/A {displayName}</b>
        )}
      </div>
    );
  }

  return (
    <div
      className={classname(
        "crutch-checkbox-list__item--other-wrap",
        "flex-column"
      )}
    >
      {children}
    </div>
  );
};

export default function CheckListSection({
  content,
  allowSectionNotApplicable,
}: {
  content: ContentItem[];
  allowSectionNotApplicable?: boolean;
}) {
  const { editMode, publishedData, ownerType } = useContext(TemplateContext);
  const id = uuid();
  const fields = getSectionFieldKeys(content);
  const fieldControllers = editMode
    ? fields.map(
        field => useTemplateController({ name: `${field}.notApplicable` }).field
      )
    : [];

  const allPublishedFieldsAreNa = fields
    .map(
      field => publishedData?.[field.split(".")[0]]?.[`${field.split(".")[1]}`]
    )
    .every(field => field?.notApplicable);

  const allNa = editMode
    ? fieldControllers.every(({ value }) => value)
    : allPublishedFieldsAreNa;

  const memoized_content_items = useMemo(() => {
    return (
      <>
        {((allowSectionNotApplicable && !allNa) ||
          !allowSectionNotApplicable) && (
          <div className="check-list crutch-checkbox-list ">
            <ContentItems content={content} Component={CheckListContentItem} />
          </div>
        )}
      </>
    );
  }, [content, allowSectionNotApplicable, allNa]);

  return (
    <ParentContext.Provider value="checklist">
      {allowSectionNotApplicable && (editMode || (!editMode && allNa)) && (
        <div className={"main-checkbox"} style={{ marginRight: "20px" }}>
          <input
            id={`${id}.notApplicable`}
            type="checkbox"
            className="main-checkbox__input"
            onClick={() => {
              fieldControllers &&
                fieldControllers
                  .map(({ onChange }) => onChange)
                  .forEach(set => set(!allNa));
            }}
            defaultChecked={!!allNa as boolean}
            disabled={!editMode}
          />
          <label
            className="main-checkbox__label"
            htmlFor={`${id}.notApplicable`}
          >
            <b>N/A</b>
          </label>
        </div>
      )}
      {memoized_content_items}
    </ParentContext.Provider>
  );
}
