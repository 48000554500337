import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./fix.css";

interface RCGReactQuillInputProps {
  toolbarId: string | number;
  toolbarStyle?: React.CSSProperties;
  placeholder?: string;
  textValue: string | undefined;
  onChange: (content: any, delta: any, source: any) => void;
  style?: React.CSSProperties;
}

const ALLOWED_FORMATS = [
  "header",
  "bold",
  "italic",
  "underline",
  "color",
  "background",
  "list",
  "indent",
  "clean",
];

export const CustomToolbar = ({
  id,
  style = {} as React.CSSProperties,
}: {
  id: string | number;
  style?: React.CSSProperties;
}) => (
  <div id={`toolbar-${id}`} style={style as any}>
    <span className="ql-formats">
      <select className="ql-header">
        <option value="1"></option>
        <option value="2"></option>
        <option selected></option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
    </span>
    <span className="ql-formats">
      <select className="ql-color"></select>
      <select className="ql-background"></select>
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-indent" value="-1"></button>
      <button className="ql-indent" value="+1"></button>
    </span>
    <span className="ql-formats">
      <button className="ql-clean"></button>
    </span>
  </div>
);

export function RCGReactQuillInput(props: RCGReactQuillInputProps) {
  const { toolbarId, toolbarStyle, placeholder, textValue, onChange, style } =
    props;
  const { maxHeight, ...otherStyles } = style || {};

  return (
    <>
      <CustomToolbar id={toolbarId} style={toolbarStyle || {}} />
      <div
        className={"scroll-block" + toolbarId}
        style={{ maxHeight: maxHeight || "600px", overflow: "auto" }}
      >
        <div id={`scrolling-container-${toolbarId}`}>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: `#toolbar-${toolbarId}`,
              clipboard: {
                style: {
                  position: "fixed !important",
                  left: "50% !important",
                  top: "50% !important",
                  display: "none",
                },
              },
            }}
            bounds={`#scrolling-container-${toolbarId}`}
            scrollingContainer={".scroll-block" + toolbarId}
            style={(otherStyles || {}) as any}
            placeholder={placeholder ?? ""}
            value={textValue}
            formats={ALLOWED_FORMATS}
            onChange={onChange}
          ></ReactQuill>
        </div>
      </div>
    </>
  );
}
