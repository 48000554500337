import uuid from "../../utils/uuid";
import { ContentItem, Field, Metadata } from "./types";

export function generateTemplateFragmentKey(fragmentName: string): string {
  const id = `${fragmentName}${uuid().replace(/-/g, "")}`;
  return id;
}

export const isFieldPresent = (field?: Field | null): boolean => {
  if (field?.value === 0) return true;
  if (Array.isArray(field?.value)) return !!field?.value.length;
  return !!(field?.value || field?.notApplicable || field?.documents?.length);
};

export const isSectionEmpty = (
  content: ContentItem[],
  readFieldFromCache: (key: string) => { field: Field; metadata: Metadata }
): boolean =>
  !content.some(contentItem => {
    if (contentItem.type === "static") {
      return false;
    }
    if (contentItem.type === "field") {
      const { field, metadata } = readFieldFromCache(contentItem.key!);
      if (metadata.displayType === "aum_table") return false;
      return isFieldPresent(field);
    }
    if (contentItem.type === "section") {
      return !isSectionEmpty(contentItem.content!, readFieldFromCache);
    }
    return false;
  });

export const getSectionFieldKeys = (content: ContentItem[]): String[] => {
  return content
    .map(c =>
      c.type === "field"
        ? c.key
        : c.type === "section"
        ? getSectionFieldKeys(c.content)
        : false
    )
    .flat()
    .filter(String) as String[];
};
