import { useContext } from "react";
import ContentItems from "../ContentItems.component";
import { TemplateContext } from "../Context";
import { ParentContext } from "../Field/FieldContext";
import { ContentItem, ContentItemDecorator } from "../types";

const ConditionalDescriptionWrapper: ContentItemDecorator = ({
  displayName,
  contentItemProps,
  children,
}) => {
  const {
    ownerType,
    draftData,
    publishedData,
    editMode,
    readDraftFieldFromCache,
    readFieldFromCache,
  } = useContext(TemplateContext);

  if (
    contentItemProps.type !== "section" &&
    contentItemProps.metadata.conditionalDescriptionKey
  ) {
    // TODO: This is wrong doesn't consider manager state. not sure what this is doing
    const conditionalDescriptionKeyField = editMode
      ? draftData?.[ownerType]?.[
          contentItemProps.metadata.conditionalDescriptionKey
        ] ||
        readDraftFieldFromCache(
          contentItemProps.metadata.conditionalDescriptionKey
        )?.field ||
        publishedData?.[ownerType]?.[
          contentItemProps.metadata.conditionalDescriptionKey
        ] ||
        readFieldFromCache(contentItemProps.metadata.conditionalDescriptionKey)
          ?.field
      : publishedData?.[ownerType]?.[
          contentItemProps.metadata.conditionalDescriptionKey
        ] ||
        readFieldFromCache(contentItemProps.metadata.conditionalDescriptionKey)
          ?.field;

    // Removing logic for hiding conditional description text until we implement logic to disable it (PB-1589)

    // if (
    //   (typeof conditionalDescriptionKeyField?.value === "string" &&
    //     !["Yes", "Other"].includes(conditionalDescriptionKeyField?.value)) ||
    //   (typeof conditionalDescriptionKeyField?.value === "boolean" &&
    //     !conditionalDescriptionKeyField?.value) ||
    //   conditionalDescriptionKeyField?.value === undefined ||
    //   conditionalDescriptionKeyField?.notApplicable === true
    // ) {
    //   return null;
    // }
  }

  return <div style={{ marginBottom: "20px" }}>{children}</div>;
};

export default function ConditionalDescriptionSection({
  content,
}: {
  content: ContentItem[];
}) {
  return (
    <ParentContext.Provider value="conditional_description">
      <div>
        <ContentItems
          content={content}
          Component={ConditionalDescriptionWrapper}
        />
      </div>
    </ParentContext.Provider>
  );
}
