/* eslint-disable max-lines-per-function */
import HighCharts from "highcharts";
import { getUniversalChartOptions } from "./universalOptions";
import { HChartType } from "./helpers";
import { AxisChartSeries } from "./dataSeriesFormatters";

export interface IChartGenerator {
  highChartType: HChartType;
  dataSeries: AxisChartSeries[];
  height?: number;
  width?: number;
  fontSize: number;
  xAxisCategories?: string[];
  yAxisCategories?: string[];
  allowExport: boolean;
  yAxisTitle?: string;
  xAxisTitle?: string;
  title?: string;
  subTitle?: string;
  pointWidth?: number;
  maxPointWidth?: number;
  xAxisType?: HighCharts.XAxisOptions["type"];
  yAxisType?: HighCharts.XAxisOptions["type"];
  polar?: boolean;
  inverted?: boolean;
  allowDecimals?: boolean;
  chartColors?: string[];
  tickInterval?: number;
  maxValue?: number;
  dataLabelFormat?: string;
  tooltipValueSuffix?: string;
  tooltipValuePrefix?: string;
  legendSymbolHeight?: number;
  legendItemMarginTop?: number;
  stackLabelOptions?: HighCharts.YAxisStackLabelsOptions;
}

/**
 * generates chart options object for column, bar and radial charts
 * @param highChartType (eg. pie, bar, column)
 * @param dataSeries chart data formatted according to highChartType
 * @param height optional chart height
 * @param width optional chart width
 * @param fontSize determines size of chart labels/tickets/legend
 * @param xAxisCategories optional (required if xAxisType is category)
 * @param yAxisCategories optional (required if yAxisType is category)
 * @param allowExport true for usage in reports, false for rendering in browser
 * @param yAxisTitle optional y axis label
 * @param xAxisTitle optional x axis label
 * @param title optional chart title
 * @param subTitle optional chart subTitle
 * @param pointWidth optional fixed pointWidth (eg. for bars and columns) - not recommended
 * @param maxPointWidth optional - sets max width of columns/bars (defaults to 80)
 * @param xAxisType optional (defaults to category) (eg. linear, logarithmic, datetime or category)
 * @param yAxisType optional (defaults to linear) (eg. linear, logarithmic, datetime or category)
 * @param polar optional (defaults to false) creates a radial graph
 * @param inverted optional inverts x/y axis (defaults to value of polar - polar charts should usually be inverted)
 * @param allowDecimals optional (allow tick with decimals on x/y axis)
 * @param tickInterval optional (applied to linear axis)
 * @param maxValue optional - max value for linear axis (will auto calculate if not provided)
 * @param dataLabelFormat (optional) highcharts format string
 * @param tooltipValueSuffix (optional) appends to tooltip value
 * @param tooltipValuePrefix (optional) prepends to tooltip value
 * @param legendSymbolHeight (optional) height of the circle symbol in legend (defaults to fontsize)
 * @param legendItemMarginTop (optional) margin between items in legend
 * @param stackLabelOptions (optional) additional stack label options
 * @returns HighCharts.Options object
 */
export function getChartOptions({
  highChartType,
  dataSeries,
  height,
  width,
  fontSize,
  xAxisCategories,
  yAxisCategories,
  allowExport,
  yAxisTitle,
  xAxisTitle,
  title,
  subTitle,
  pointWidth,
  maxPointWidth,
  xAxisType = "category",
  yAxisType = "linear",
  polar = false,
  inverted = polar,
  allowDecimals,
  chartColors,
  tickInterval,
  maxValue,
  dataLabelFormat,
  tooltipValuePrefix,
  tooltipValueSuffix,
  legendSymbolHeight,
  legendItemMarginTop,
  stackLabelOptions,
}: IChartGenerator): HighCharts.Options {
  const universalOptions = getUniversalChartOptions({
    title,
    subTitle,
    dataSeries: dataSeries as HighCharts.SeriesOptionsType[],
    allowExport,
    chartColors,
    fontSize,
  });

  const options: HighCharts.Options = {
    ...universalOptions,
    chart: {
      type: `${highChartType}`,
      ignoreHiddenSeries: false,
      plotShadow: false,
      polar,
      inverted,
      style: {
        fontFamily: "Helvetica",
        fontSize: `${fontSize}px`,
      },
      width,
      height,
      alignTicks: false,
    },
    ...(polar && {
      pane: {
        size: "90%",
        innerSize: "30%",
        endAngle: 300,
      },
    }),
    plotOptions: {
      [highChartType]: {
        ...(highChartType === "column" && { stacking: "normal" }),
        dataLabels: {
          format: dataLabelFormat ?? "{y}",
          enabled: true,
          crop: false,
          style: {
            fontSize: `${fontSize}px`,
          },
        },
        maxPointWidth: maxPointWidth ?? 80,
        pointWidth,
      },
    },
    xAxis: {
      categories: xAxisCategories,
      ...(xAxisCategories &&
        !polar && { min: 0, max: xAxisCategories.length - 1 }),
      labels: {
        style: {
          fontSize: `${fontSize}px`,
        },
      },
      title: {
        text: xAxisTitle,
      },
      showEmpty: true,
      type: xAxisType,
      showFirstLabel: true,
      showLastLabel: true,
      allowDecimals,
      ...(xAxisType === "linear" && { tickInterval }),
      ...(xAxisType === "linear" && { min: 0 }),
      ...(xAxisType === "linear" && maxValue && { max: maxValue }),
    },
    yAxis: {
      ...(yAxisType === "linear" && { min: 0 }),
      ...(yAxisType === "linear" && maxValue && { max: maxValue }),
      title: {
        text: yAxisTitle,
      },
      labels: {
        style: {
          fontSize: `${fontSize}px`,
        },
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
        },
        ...stackLabelOptions,
      },
      allowDecimals,
      showLastLabel: true,
      showFirstLabel: true,
      showEmpty: true,
      type: yAxisType,
      categories: yAxisCategories,
      ...(yAxisType === "linear" && { tickInterval }),
    },
    legend: {
      align: "center",
      itemMarginTop: legendItemMarginTop ?? 0,
      symbolHeight: legendSymbolHeight ?? fontSize,
      itemStyle: {
        fontSize: `${fontSize}px`,
      },
    },
    tooltip: {
      ...(tooltipValuePrefix && { valuePrefix: tooltipValuePrefix }),
      ...(tooltipValueSuffix && { valueSuffix: tooltipValueSuffix }),
    },
  };
  return options;
}
