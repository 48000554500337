import { useContext } from "react";
import ContentItems from "../ContentItems.component";
import { TemplateContext } from "../Context";
import { useTemplateController } from "../Field/FieldContext";
import { getSectionFieldKeys } from "../Template.helpers";
import { ContentItem, ContentItemDecorator } from "../types";

const ListTableRow: ContentItemDecorator = ({ displayName, children }) => {
  return (
    <tr>
      {displayName && <td>{displayName}</td>}
      <td>{children}</td>
    </tr>
  );
};

export default function ListTableSection({
  content,
  displayName,

  allowSectionNotApplicable,
}: {
  content: ContentItem[];
  displayName?: string;

  allowSectionNotApplicable?: boolean;
}) {
  const { editMode, publishedData } = useContext(TemplateContext);
  const fields = getSectionFieldKeys(content);
  const fieldControllers = editMode
    ? fields.map(
        field => useTemplateController({ name: `${field}.notApplicable` }).field
      )
    : [];
  const allPublishedFieldsAreNa = fields
    .map(
      field => publishedData?.[field.split(".")[0]]?.[`${field.split(".")[1]}`]
    )
    .every(field => field?.notApplicable);

  const allNa = editMode
    ? fieldControllers.every(({ value }) => value)
    : allPublishedFieldsAreNa;

  return (
    <>
      {allowSectionNotApplicable && (editMode || (!editMode && allNa)) && (
        <div className={"main-checkbox"} style={{ marginRight: "20px" }}>
          <input
            id={`${displayName}.notApplicable`}
            type="checkbox"
            className="main-checkbox__input main-checkbox__input--only-checkbox"
            onClick={() => {
              fieldControllers
                .map(({ onChange }) => onChange)
                .forEach(set => set(!allNa));
            }}
            defaultChecked={!!allNa as boolean}
            disabled={!editMode}
          />
          <label
            className="main-checkbox__label"
            htmlFor={`${displayName}.notApplicable`}
          >
            <b>N/A</b>
          </label>
        </div>
      )}

      <table className="crutch-table-double-col cms-striped-table">
        <colgroup>
          <col style={{ width: "246px" }} />
          <col />
        </colgroup>
        <tbody>
          {((allowSectionNotApplicable && !allNa) ||
            !allowSectionNotApplicable) && (
            <ContentItems content={content} Component={ListTableRow} />
          )}
        </tbody>
      </table>
    </>
  );
}
