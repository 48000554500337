import classnames from "classnames";
import { memo } from "react";
import { asFullDateTime } from "../../../utils/date";

export const FieldStamp = memo(function FieldStamp({
  field,
  className,
}: {
  field: {
    modifyDate: Date;
    modifyUser: string;
    ownerId: string | number;
    ownerType: string;
    fieldKey: string;
  } | null;
  className?: string;
}): JSX.Element {
  const hasData =
    !!field && field?.fieldKey && field.ownerId && field.ownerType;

  const classes = className
    ? classnames(className)
    : classnames("in-secondary-blue font-12 mr-10");

  if (field?.modifyDate && field.modifyUser) {
    return (
      <div data-cy="field-timestamp" className={classes}>
        {`Updated By `}
        {`${field.modifyUser}, ${asFullDateTime(field.modifyDate)}`}
      </div>
    );
  }

  return (
    <div data-cy="field-timestamp-not_edited" className={classes}>
      {hasData ? "Not edited yet" : null}
    </div>
  );
});
