import ContentItems from "../ContentItems.component";
import { ContentItem } from "../types";

export default function ColumnarSection({
  content,
}: {
  content: ContentItem[];
}) {
  return (
    <div className="section-columnar__grid">
      <ContentItems content={content} />
    </div>
  );
}
