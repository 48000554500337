/* eslint-disable no-shadow */
import { GenericProp } from "_graphql-types-frontend/graphql";
import { Card } from "antd";
import classnames from "classnames";
import { camelCase, get, lowerCase, startCase } from "lodash";
import { memo, useMemo } from "react";
import { TableContext, useTableContext } from "../FieldTableContext";
import InputFieldBase, { InputFieldProps } from "./InputFieldBase";

const ListTableInputRow = memo(function ListTableInputRow({
  prop,
  key,
  index,
  fieldUuids,
  templateKey,
  rowMetadata,
}: {
  prop?: GenericProp;
  key: string;
  index: number;
  fieldUuids: string[];
  templateKey: string;
  rowMetadata: any;
}) {
  if (!prop || !prop.displayType) return null; // skip missing prop keys __typename etc

  const metadata = useMemo(() => {
    return {
      displayName: "",
      displayType: prop.displayType,
      options: prop.options,
      value: {
        ...prop,
        hideChangelog: true,
        hideTimeStamp: true,
      },
    };
  }, [prop]);

  return (
    <tr className={prop.propertyKey}>
      <td className="cms-striped-table__header">
        {get(prop, "displayName", startCase(prop.propertyKey))}
      </td>
      <td
        className={classnames(
          "field-table__cell-content",
          "field-table-default__cell-content",
          `field-cell-${lowerCase(prop.displayType)}`,
          `cell-${lowerCase(prop.displayType)}`
        )}
      >
        <InputFieldBase
          templateKey={`${templateKey}.${rowMetadata.propertyKey}[${index}].${prop.propertyKey}`}
          formLookupKey={`${rowMetadata.propertyKey}[${index}].${prop.propertyKey}`}
          metadata={metadata}
        />
      </td>
    </tr>
  );
});

function ListTableInput({
  metadata,
  templateKey,
  formLookupKey,
  NotApplicableWrapper,
}: InputFieldProps): JSX.Element {
  const tableContext = useTableContext({
    metadata,
    templateKey,
    formLookupKey,
  });

  const { fieldState, fieldUuids, addRow, deleteRow, rowMetadata } =
    tableContext;

  return (
    <NotApplicableWrapper {...{ templateKey, metadata }}>
      <TableContext.Provider value={tableContext}>
        <div
          className="section-card__content-table-wrap"
          data-cy={`ListTableInput__${camelCase(metadata.displayName ?? "")}`}
        >
          <>
            {fieldState.map((row, index) => {
              return (
                <Card
                  key={fieldUuids[index]}
                  data-testid={`table__row-${index}`}
                >
                  <div
                    style={{ float: "right" }}
                    data-testid={`remove-row-button-${index}`}
                    onClick={event => deleteRow(event, index)}
                  >
                    <button
                      type="button"
                      className="cms-striped-table__icon icon-basket"
                    />
                  </div>
                  <table
                    className={classnames(
                      "crutch-table-double-col",
                      "cms-striped-table"
                    )}
                  >
                    <colgroup>
                      <col style={{ width: "246px" }} />
                      <col />
                    </colgroup>
                    <tbody>
                      {Object.entries(row).map(([key]) => {
                        const prop = rowMetadata.props.find(
                          ({ propertyKey }: { propertyKey: string }) => {
                            return propertyKey === key;
                          }
                        );
                        return (
                          <ListTableInputRow
                            key={fieldUuids[index] + "__" + key}
                            prop={prop}
                            index={index}
                            fieldUuids={fieldUuids}
                            templateKey={templateKey}
                            rowMetadata={rowMetadata}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              );
            })}
          </>

          <button
            type="button"
            data-testid="add-row-button"
            onClick={addRow}
            className="cms-overview__add-btn"
          >
            <i className="cms-overview__add-btn-icon icon icon-plus" />
            <span className="cms-overview__add-btn-txt">Add New Row</span>
          </button>
        </div>
      </TableContext.Provider>
    </NotApplicableWrapper>
  );
}

export default ListTableInput;
