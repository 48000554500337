/* eslint-disable react/jsx-no-undef */

import { Modal as AntdModal } from "antd";

type ModalProps = {
  title: string;
  onCancel: () => void;
  children: JSX.Element | JSX.Element[] | null;
  footer?: JSX.Element | null;
  visible: boolean;
  width?: string | number;
  zIndex?: number;
};

function Modal({
  width = "850px",
  footer = null,
  visible,
  onCancel,
  title,
  children,
  zIndex = 9999,
}: ModalProps) {
  return (
    <AntdModal
      data-cy="antd-modal"
      data-testid="antd-modal"
      width={width}
      open={visible}
      onCancel={onCancel}
      title={title}
      zIndex={zIndex}
      centered
      footer={footer ?? null}
    >
      {children}
    </AntdModal>
  );
}

export default Modal;
